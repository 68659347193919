import React from "react";
import { Form, Input, Select, Row, Col, Space, Typography } from "antd";
import { CustomButton } from "../styles";

interface RegisterFormProps {
  form: any;
  onFinish: (values: any) => void;
  onLoginRedirect: () => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ form, onFinish, onLoginRedirect }) => (
  <Form layout="vertical" form={form} onFinish={onFinish} style={{ marginTop: "30px" }}>
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Email is required" }]}
        >
          <Input placeholder="Enter email" style={{ height: "38px" }} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Country"
          name="country"
          rules={[{ required: true, message: "Country is required" }]}
        >
          <Select style={{ height: "38px" }} placeholder="Select country" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Country Code"
          name="countryCode"
          rules={[{ required: true, message: "Country code is required" }]}
        >
          <Input placeholder="Enter country code" style={{ height: "38px" }} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="State"
          name="state"
          rules={[{ required: true, message: "State is required" }]}
        >
          <Select style={{ height: "38px" }} placeholder="Select state" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: "City is required" }]}
        >
          <Input placeholder="Enter city" style={{ height: "38px" }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="WhatsApp/Mobile Number"
          name="phoneNumber"
          rules={[{ required: true, message: "Phone number is required" }]}
        >
          <Input placeholder="Enter phone number" style={{ height: "38px" }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item>
          <CustomButton type="primary" size="middle">
            Register
          </CustomButton>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Space>
            <Typography.Text type="secondary">Already have an account?</Typography.Text>
            <Typography.Link onClick={onLoginRedirect}>Login</Typography.Link>
          </Space>
        </Row>
      </Col>
    </Row>
  </Form>
);

export default RegisterForm;
