import styled from "@emotion/styled";
import { Button, Col, Input, Row, Select, Typography } from "antd";

export const RegisterPageWrapper = styled(Row)`
  height: 100vh;
`;

export const RegisterCard = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f7f7f8;
`;

export const RegisterFormContainer = styled.div`
  max-width: 450px;
  width: 100%;
`;

export const Title = styled(Typography.Title)`
  color: #263446;
  font-weight: 700;
  margin: 5px;
`;

export const DescriptionText = styled(Typography.Text)`
  color: rgba(0, 0, 0, 0.45);
`;

export const CustomButton = styled(Button)`
  width: 100%;
  height: 38px;
`;

export const StyledInput = styled(Input)`
  height: 38px;
`;

export const StyledSelect = styled(Select)`
  height: 38px;
`;
