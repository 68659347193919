import { notification } from "antd";
import { AxiosError } from "axios";

const showNotification = (type: "success" | "error", message: string, description: string) => {
  notification[type]({
    message,
    description,
    style: {
      backgroundColor: type === "error" ? "#f8d7da" : "#d4edda",
      color: type === "error" ? "#721c24" : "#155724",
    },
  });
};

const getAxiosErrorMessage = (error: any): string => {
  return error.response?.data?.message || error.message;
};

const formatError = (error: unknown): void => {
  if (!(error instanceof AxiosError)) {
    showNotification("error", "Error", "An unexpected error occurred.");
    return;
  }

  const { response } = error;
  const errors = response?.data?.errors;

  if (errors && typeof errors === "object") {
    const errorMessages = Object.entries(errors)
      .map(([key, value]) => `${key}: ${value}`)
      .join("\n");

    showNotification("error", "Failed", errorMessages);
  } else {
    showNotification("error", "Failed", getAxiosErrorMessage(error));
  }
};

export { showNotification, formatError };
