/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import CardWithForm from "./CardsWithForm";
import { Table, Typography, Row, Col, Input, Form } from "antd";
import { matrixTableData, matrixTableDataExtended } from "../data";
import {
  matrixTableColumns,
  matrixTableColumns2,
  matrixTableColumnsExtended,
} from "../handlers/tableColumns";
import { binocularCardStyle, formItemStyle, tableLabelStyle, titleStyle } from "../styles";

const BinocularCard: React.FC = () => (
  <CardWithForm
    title="Binocular"
    formItems={
      <>
        <Form.Item label="Motility" css={formItemStyle}>
          <Input.TextArea rows={4} placeholder="Describe Motility" />
        </Form.Item>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="coverD" label="Cover D" css={formItemStyle}>
              <Input placeholder="Cover D value" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="coverN" label="Cover N" css={formItemStyle}>
              <Input placeholder="Cover N value" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="npcCm" label="NPC (cm)" css={formItemStyle}>
              <Input placeholder="NPC in cm" />
            </Form.Item>
          </Col>
        </Row>
      </>
    }
    additionalContent={
      <div css={binocularCardStyle}>
        <Typography.Title level={5} css={titleStyle}>
          Un-aided
        </Typography.Title>
        <Table
          columns={matrixTableColumns.map((col) => ({
            ...col,
            onCell: () => ({
              style: { whiteSpace: "nowrap" },
            }),
          }))}
          dataSource={matrixTableData}
          pagination={false}
          rowKey="key"
          css={tableLabelStyle}
        />

        <Typography.Title level={5} css={titleStyle}>
          Aided
        </Typography.Title>
        <Table
          columns={matrixTableColumnsExtended.map((col) => ({
            ...col,
            onCell: () => ({
              style: { whiteSpace: "nowrap" },
            }),
          }))}
          dataSource={matrixTableData}
          pagination={false}
          rowKey="key"
          css={tableLabelStyle}
        />

        <Form layout="vertical" style={{ marginTop: "12px" }}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="controlLens" label="Control Lens" css={formItemStyle}>
                <Input placeholder="Enter Control Lens value" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="fusedXCyl" label="Fused X-Cyl" css={formItemStyle}>
                <Input placeholder="Fused X-Cyl value" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="unfusedXCyl" label="Unfused X-Cyl" css={formItemStyle}>
                <Input placeholder="Unfused X-Cyl value" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="bPlus" label="B+" css={formItemStyle}>
                <Input placeholder="B+ value" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="bMinus" label="B-" css={formItemStyle}>
                <Input placeholder="B- value" />
              </Form.Item>
            </Col>
          </Row>

          <Table
            columns={matrixTableColumns2.map((col) => ({
              ...col,
              onCell: () => ({
                style: { whiteSpace: "nowrap" },
              }),
            }))}
            dataSource={matrixTableDataExtended}
            pagination={false}
            rowKey="key"
            css={tableLabelStyle}
          />

          <Row gutter={16} style={{ marginTop: "12px" }}>
            <Col span={12}>
              <Form.Item name="stereopsis" label="Stereopsis" css={formItemStyle}>
                <Input placeholder="Enter Stereopsis value" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="fixationDisparity" label="Fixation Disparity" css={formItemStyle}>
                <Input placeholder="Enter Fixation Disparity value" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Notes" css={formItemStyle}>
                <Input.TextArea rows={4} placeholder="Enter your notes here" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    }
  />
);

export default BinocularCard;
