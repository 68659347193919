import { Typography } from "antd";
import styled from "@emotion/styled";

const { Text, Title } = Typography;

export const StyledTitle = styled(Title)`
  margin-bottom: 5px;
`;

export const StyledSecondaryText = styled(Text)`
  color: #b5b6b7;
`;

export const StatisticTitle = styled(Text)`
  color: #000;
`;
