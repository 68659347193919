import React, { useEffect, useState, useCallback } from "react";
import { TablePaginationConfig } from "antd/es/table";
import QueryParamsManager from "core/QueryParamsManager";

const withPagination = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return (props: Omit<P, "pagination" | "total"> & { total: number; fetchData: () => void }) => {
    const [current, setCurrent] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);

    useEffect(() => {
      const queryParams = QueryParamsManager.getQueryParams();
      setCurrent(queryParams.pagination.page);
      setPageSize(queryParams.pagination.pageSize);
    }, []);

    useEffect(() => {
      QueryParamsManager.updateQueryParams({
        pagination: { page: current, pageSize },
      });
      props.fetchData();
    }, [current, pageSize]);

    const handleChange = useCallback((page: number, size: number) => {
      setCurrent(page);
      setPageSize(size);
    }, []);

    const pagination: TablePaginationConfig = {
      current,
      pageSize,
      total: props.total,
      onChange: handleChange,
      showSizeChanger: true,
    };

    return <WrappedComponent {...(props as P)} pagination={pagination} />;
  };
};

export default withPagination;
