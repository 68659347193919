import axios, { AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import config from "config/api.config";
import { storage } from "./storage";
import { ServiceName } from "config/api.config";

interface ApiResponse<T = any> {
  success: boolean;
  errorCode: number;
  message?: string;
  data?: T;
  [key: string]: any;
}

interface ServiceConfig {
  basePath?: string;
  [key: string]: any;
}

class HttpClient {
  private httpClient: AxiosInstance;

  constructor(serviceName?: ServiceName) {
    let baseURL: string;

    if (serviceName) {
      const serviceConfig = config.services[serviceName];

      if (!serviceConfig || !serviceConfig.basePath) {
        throw new Error(`Invalid configuration for service: ${serviceName}`);
      }

      baseURL = this.constructBaseUrl(
        config.globalBaseUrl,
        config.apiVersion,
        serviceConfig.basePath
      );
    } else {
      baseURL = this.constructBaseUrl(config.globalBaseUrl, config.apiVersion, "");
    }

    this.httpClient = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpClient.interceptors.request.use(
      this.handleRequest.bind(this),
      this.handleRequestError.bind(this)
    );

    this.httpClient.interceptors.response.use(
      this.handleResponse.bind(this),
      this.handleResponseError.bind(this)
    );
  }

  private constructBaseUrl(
    globalBaseUrl: string,
    apiVersion: string,
    serviceBasePath: string
  ): string {
    const formattedGlobalBaseUrl = globalBaseUrl.endsWith("/")
      ? globalBaseUrl.slice(0, -1)
      : globalBaseUrl;
    const formattedApiVersion = apiVersion.startsWith("/") ? apiVersion.slice(1) : apiVersion;
    const formattedServiceBasePath = serviceBasePath.startsWith("/")
      ? serviceBasePath.slice(1)
      : serviceBasePath;

    return `${formattedGlobalBaseUrl}/${formattedApiVersion}/${formattedServiceBasePath}`;
  }

  private handleRequest(config: any): any {
    const token = storage.getToken();
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: token,
      };
    }
    return config;
  }

  private handleRequestError(error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error);
  }

  private handleResponse(
    response: AxiosResponse<ApiResponse<any>>
  ): AxiosResponse<ApiResponse<any>> {
    return response;
  }

  private handleResponseError(error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error);
  }

  getClient(): AxiosInstance {
    return this.httpClient;
  }
}

class ServiceManager {
  private static clients: Map<ServiceName, HttpClient> = new Map();

  static getClient(serviceName: ServiceName): HttpClient {
    if (!ServiceManager.clients.has(serviceName)) {
      ServiceManager.clients.set(serviceName, new HttpClient(serviceName));
    }
    return ServiceManager.clients.get(serviceName)!;
  }

  static getCoreClient(): HttpClient {
    return new HttpClient();
  }
}

const authClient = ServiceManager.getClient(ServiceName.AuthService).getClient();
const supportClient = ServiceManager.getClient(ServiceName.SupportService).getClient();
const socketClient = ServiceManager.getClient(ServiceName.Socket).getClient();
const coreClient = ServiceManager.getCoreClient().getClient();

export { ServiceManager, authClient, supportClient, socketClient, coreClient };
