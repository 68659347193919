import SubscriptionForm from "Scenes/Subscription/forms/SubscriptionForm";
import NotFound from "components/PageNotFound";
import { SubscriptionPage } from "Scenes/Subscription";
import { PatientRecord, PatientRecordForm } from "Scenes/PatientRecords";
import { AppointmentPage } from "Scenes/Appointment";
import ListTicketTypes from "Scenes/Support/components/SupportTicketType/ListTicketTypes";
import { HelpAndSupport } from "Scenes/Support";
import { ConsultationPage } from "Scenes/Consultation";
import { ResetPasswordPage } from "Scenes/PasswordRecovery";
import { ChangePasswordPage } from "Scenes/PasswordRecovery";
import { Dashboard } from "Scenes/Dashboard";
import { LayoutPage } from "Scenes/Layout";
import { LoginPage } from "Scenes/Auth/Login";
import { RegisterPage } from "Scenes/Auth/Register";
import ConsultationDetailsPage from "Scenes/Consultation/components/ConsultationDetails";

export const RouteComponents = {
  LoginPage,
  RegisterPage,
  ResetPasswordPage,
  ChangePasswordPage,
  Dashboard,
  AppointmentPage,
  PatientRecord,
  PatientRecordForm,
  SubscriptionForm,
  ConsultationPage,
  HelpAndSupport,
  NotFound,
  LayoutPage,
  SubscriptionPage,
  ListTicketTypes,
  ConsultationDetailsPage,
};
