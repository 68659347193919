import { authClient } from "utils/httpClient";
import { API_ENDPOINTS } from "constants/apiEndpoints";
import { PatientRecord, PatientRecordResponseInterface } from "./type";

const apiClient = authClient;

export const patientRecordApi = {
  // Get all patient records
  getAll: async (): Promise<PatientRecordResponseInterface> => {
    try {
      const response = await apiClient.get<PatientRecordResponseInterface>(
        API_ENDPOINTS.PATIENTS.BASE
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get a patient record by ID
  getById: async (id: string): Promise<PatientRecord> => {
    try {
      const response = await apiClient.get<{
        success: boolean;
        data: PatientRecord;
        message?: string;
      }>(API_ENDPOINTS.PATIENTS.READ(id));
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  // Create a new patient record
  create: async (patientData: PatientRecord): Promise<PatientRecord> => {
    try {
      const response = await apiClient.post<PatientRecord>(
        API_ENDPOINTS.PATIENTS.CREATE,
        patientData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Update an existing patient record
  update: async (id: string, patientData: PatientRecord): Promise<PatientRecord> => {
    try {
      const response = await apiClient.put<PatientRecord>(
        API_ENDPOINTS.PATIENTS.UPDATE(id),
        patientData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Delete a patient record
  delete: async (id: string): Promise<void> => {
    try {
      await apiClient.delete<void>(API_ENDPOINTS.PATIENTS.DELETE(id));
    } catch (error) {
      throw error;
    }
  },
};
