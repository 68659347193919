/** @jsxImportSource @emotion/react */
import React from "react";
import { Card, Form, Input, Row, Col, Typography } from "antd";
import { css } from "@emotion/react";
import { cardStyle } from "../styles";

interface CardWithFormProps {
  title: string;
  formItems: React.ReactNode;
  additionalContent?: React.ReactNode;
}

const CardWithForm: React.FC<CardWithFormProps> = ({ title, formItems, additionalContent }) => (
  <Card
    title={title}
    style={{ flex: 1, boxShadow: "0px 8px 40px -10px #00000014" }}
    css={cardStyle}
  >
    <Form layout="vertical">{formItems}</Form>
    {additionalContent}
  </Card>
);

export default CardWithForm;
