import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { showNotification } from "utils/notification";

type QueryFn<T> = () => Promise<T>;
type MutationFn<T, V> = (variables: V) => Promise<T>;

export const useApiQuery = <T>(
  queryKey: any[],
  queryFn: QueryFn<T>,
  options?: { enabled: boolean }
): UseQueryResult<T, AxiosError> => {
  return useQuery<T, AxiosError>({
    queryKey,
    queryFn,
    ...options,
  });
};

export const useApiMutation = <T, V>(
  mutationFn: MutationFn<T, V>
): UseMutationResult<T, AxiosError, V> => {
  const queryClient = useQueryClient();
  return useMutation<T, AxiosError, V>({
    mutationFn,
    onSuccess: (data) => {
      const successMessage = (data as any)?.message || "Operation successful";
      showNotification("success", "Success", successMessage);
      queryClient.invalidateQueries();
    },
  });
};
