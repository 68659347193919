import { useMemo } from "react";
import { useQueryString } from "hooks/useQueryString";
import { useApiQuery, useApiMutation } from "utils/useApi";

export const useApiService = <T, V>(
  queryKey: string,
  api: {
    getAll?: () => Promise<T>;
    getById?: (id: string) => Promise<V>;
    create?: (data: V) => Promise<V>;
    update?: (id: string, data: V) => Promise<V>;
    patch?: (id: string, status: any) => Promise<void>;
    delete?: (id: string) => Promise<void>;
  }
) => {
  const queryString = useQueryString();
  const memoizedQueryKey = useMemo(() => [queryKey, queryString], [queryKey, queryString]);

  return {
    useGetAll: () => {
      return useApiQuery<T>(memoizedQueryKey, api.getAll || (() => Promise.resolve() as any));
    },
    useGetById: (id: string) => {
      return useApiQuery<V>(
        [queryKey, id],
        () => (api.getById ? api.getById(id) : (Promise.resolve() as any)),
        { enabled: !!id }
      );
    },
    useCreate: () => {
      return useApiMutation<V, V>(api.create || (() => Promise.resolve() as any));
    },
    useUpdate: () => {
      return useApiMutation<V, { id: string; data: V }>((params) =>
        api.update ? api.update(params.id, params.data) : (Promise.resolve() as any)
      );
    },
    usePatch: () => {
      return useApiMutation<void, { id: string; status: any }>((params) =>
        api.patch ? api.patch(params.id, params.status) : (Promise.resolve() as any)
      );
    },
    useDelete: () => {
      return useApiMutation<void, string>(api.delete || (() => Promise.resolve() as any));
    },
  };
};
