import styled from "@emotion/styled";
import { Button, Checkbox, Col, Radio, Row, Typography } from "antd";

export const LoginPageWrapper = styled(Row)`
  height: 100vh;
`;

export const LoginCard = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f7f7f8;
`;

export const LoginFormContainer = styled.div`
  max-width: 380px;
  width: 100%;
`;

export const Title = styled(Typography.Title)`
  color: #263446;
  font-weight: 700;
  margin: 3px;
`;

export const DescriptionText = styled(Typography.Text)`
  color: rgba(0, 0, 0, 0.45);
`;

export const CustomRadioGroup = styled(Radio.Group)`
  width: 100%;
  margin: 30px 0;
  height: 38px;
`;

export const CustomRadio = styled(Radio.Button)`
  width: 33.3%;
  text-align: center;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  height: 38px;
`;

export const ForgotPasswordLink = styled(Typography.Link)`
  margin-left: auto;
`;
