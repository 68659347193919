/** @jsxImportSource @emotion/react */
import React from "react";
import { useLogin } from "services/AuthService/useLogin";
import {
  LoginPageWrapper,
  LoginCard,
  LoginFormContainer,
  Title,
  DescriptionText,
  CustomRadioGroup,
  CustomRadio,
} from "../styles";
import LoginForm from "./LoginForm";
import LoginImageComponent from "./LoginImage";
import { Form } from "antd";

const LoginPage: React.FC = () => {
  const [form] = Form.useForm();
  const loginMutation = useLogin();

  const handleSubmit = async () => {
    const loginPayload = await form.validateFields();
    loginMutation.mutate(loginPayload);
  };

  return (
    <LoginPageWrapper justify="space-between">
      <LoginCard span={12}>
        <LoginFormContainer>
          <Title level={1} style={{ margin: 4 }}>
            Sign In
          </Title>
          <DescriptionText type="secondary">
            Enter your email and password to sign in to our system.
          </DescriptionText>
          <CustomRadioGroup defaultValue={1} buttonStyle="solid" size="middle">
            <CustomRadio value={1}>Admin</CustomRadio>
            <CustomRadio value={2}>Franchise</CustomRadio>
            <CustomRadio value={3}>Staff</CustomRadio>
          </CustomRadioGroup>

          <LoginForm form={form} onFinish={handleSubmit} isSubmitting={loginMutation.isPending} />
        </LoginFormContainer>
      </LoginCard>
      <LoginImageComponent />
    </LoginPageWrapper>
  );
};

export default LoginPage;
