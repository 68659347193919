import { coreClient } from "utils/httpClient";
import { API_ENDPOINTS } from "constants/apiEndpoints";
import { UserEmail } from "./type";
import { buildUrl } from "utils/apiUtils";

export const emailApi = {
  getAllEmails: async (): Promise<UserEmail[]> => {
    const response = await coreClient.get<UserEmail[]>(buildUrl(API_ENDPOINTS.USER_EMAILS.GET_ALL));
    return response.data;
  },
};
