import { useEffect, useMemo } from "react";
import { startOfWeek, endOfWeek, format, add } from "date-fns";
import { useAppointmentContext } from "../context";
import { Event } from "types/event.type";
import EventBlock from "../components/AppointmentCard";
import { events } from "../constants/EventList";
import { daysToWeekObject, getDayHoursEvents } from "utils/appointment";

type WeekDates = {
  startDate: Date;
  endDate: Date;
};

type DayList = string[];

export const useAppointmentCalendar = () => {
  const {
    startWeek,
    setStartWeek,
    viewOption,
    selectedDate,
    setOpen,
    setShouldViewDetails,
    setSelectedCell,
  } = useAppointmentContext();

  const weekDates: WeekDates = {
    startDate: startOfWeek(startWeek),
    endDate: endOfWeek(startWeek),
  };

  const getDayEvents = daysToWeekObject(events, startWeek);
  const dayEvents = getDayHoursEvents(weekDates, getDayEvents);

  const dayList: DayList = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const getCellOnClickHandler = (day: string) => (record: any, rowIndex: number) => ({
    onClick: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const hasMoreThanOneEvent = Array.isArray(record[day]) && record[day].length > 0;
      if (hasMoreThanOneEvent) {
        setShouldViewDetails(true);
      } else {
        setOpen(true);
        setSelectedCell(record?.hourObject);
      }
    },
    style: { cursor: "pointer" },
  });

  const getDayEventsRenderer = (day: string) => (events: Event[], row: any) => {
    const dayEvents = events.filter((event) => {
      const eventDate = new Date(event.startTime);
      return format(eventDate, "EEEE") === day;
    });

    return dayEvents.length > 0 ? (
      <div style={{ position: "relative", padding: "0" }}>
        {dayEvents.map((event, index) => (
          <EventBlock key={event.id} event={event} index={index} hour={row.hourObject} />
        ))}
      </div>
    ) : undefined;
  };

  const dayColumns = useMemo(() => {
    if (viewOption === "1 day") {
      const today = startWeek;
      const todayDayName = format(today, "EEEE");
      const todayFormatted = format(today, "iii dd MMMM");
      const todayIndex = dayList.indexOf(todayDayName);

      return [
        {
          title: todayFormatted,
          dataIndex: dayList[todayIndex],
          key: dayList[todayIndex],
          width: 1,
          align: "center",
          onCell: getCellOnClickHandler(dayList[todayIndex]),
          render: getDayEventsRenderer(dayList[todayIndex]),
        },
      ];
    }

    return dayList.map((day, counter) => {
      const columnDate = add(new Date(weekDates.startDate), { days: counter + 1 });
      const formattedDayAndMonth = format(columnDate, "iii dd MMMM");

      return {
        title: formattedDayAndMonth,
        dataIndex: day,
        key: day,
        onCell: getCellOnClickHandler(day),
        render: getDayEventsRenderer(day),
      };
    });
  }, [viewOption, weekDates.startDate, dayList, getCellOnClickHandler, getDayEventsRenderer]);

  const hourColumn = {
    title: "Hours",
    dataIndex: "hour",
    key: "hour",
    width: 1,
    render: (hour: string) => ({
      props: {
        style: { width: "10%" },
      },
      children: <div>{hour}</div>,
    }),
  };

  const tableColumns = useMemo(() => [hourColumn, ...dayColumns], [hourColumn, dayColumns]);

  useEffect(() => {
    if (selectedDate && startOfWeek(selectedDate).getTime() !== startWeek.getTime()) {
      setStartWeek(selectedDate);
    }
  }, [selectedDate, startWeek, setStartWeek]);

  return {
    tableColumns,
    dataSource: dayEvents as any,
  };
};
