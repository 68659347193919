import React from "react";
import { Form, Input } from "antd";

const EmailStep: React.FC = () => (
  <Form.Item name="email" rules={[{ required: true, message: "Please input your email!" }]}>
    <Input placeholder="Email" />
  </Form.Item>
);

export default EmailStep;
