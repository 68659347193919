import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Dropdown,
  Menu,
  Input,
  Typography,
  Button,
  Modal,
  Form,
  Upload,
} from "antd";
import { FaFileAlt, FaEye, FaTrashAlt } from "react-icons/fa";
import { MoreOutlined, UploadOutlined, InboxOutlined } from "@ant-design/icons";

const { Search } = Input;
const { Dragger } = Upload;

const documentList = [
  { id: 1, name: "Eye Test Report", size: "1 MB" },
  { id: 2, name: "Prescription - Glasses", size: "750 KB" },
  { id: 3, name: "Vision Acuity Test", size: "2.5 MB" },
  { id: 4, name: "Consultation Summary", size: "900 KB" },
  { id: 5, name: "Vision Acuity Test", size: "2.5 MB" },
  { id: 6, name: "Consultation Summary", size: "900 KB" },
];

const DocumentsTab: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleMenuClick = (key: string) => {
    if (key === "preview") {
      console.log("Preview clicked");
    } else if (key === "delete") {
      console.log("Delete clicked");
    }
  };

  const menu = (
    <Menu onClick={({ key }) => handleMenuClick(key)}>
      <Menu.Item key="preview" icon={<FaEye />}>
        Preview
      </Menu.Item>
      <Menu.Item key="delete" icon={<FaTrashAlt />}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const filteredDocuments = documentList.filter((doc) =>
    doc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUploadClick = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      console.log("Uploaded values: ", values);
      setIsModalVisible(false);
      form.resetFields();
    });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <Search
          placeholder="Search Document"
          onSearch={(value) => setSearchTerm(value)}
          enterButton
          style={{ maxWidth: "400px" }}
        />

        <Button type="primary" icon={<UploadOutlined />} onClick={handleUploadClick}>
          Upload Document
        </Button>
      </div>

      <Row gutter={[24, 24]}>
        {filteredDocuments.map((doc) => (
          <Col span={12} key={doc.id}>
            <Card
              style={{
                boxShadow: "0px 8px 40px -10px #00000014",
                borderRadius: "4px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: 50,
                    height: 50,
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 16,
                    borderRadius: "4px",
                  }}
                >
                  <FaFileAlt style={{ fontSize: 24 }} />
                </div>

                <div style={{ flex: 1 }}>
                  <Typography.Text strong>{doc.name}</Typography.Text>
                  <Typography.Paragraph style={{ margin: 0 }}>{doc.size}</Typography.Paragraph>
                </div>

                <Dropdown overlay={menu} trigger={["click"]}>
                  <MoreOutlined style={{ fontSize: 22, cursor: "pointer" }} />
                </Dropdown>
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        title="Add Document"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Upload"
      >
        <Form form={form} layout="vertical">
          <Form.Item name="upload">
            <Dragger>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading company data
                or other banned files.
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DocumentsTab;
