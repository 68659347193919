import { FieldConfig, SelectFieldConfig } from "types/form.config.type";
import { Input, DatePicker, Select, Checkbox } from "antd";
import { TextAreaProps, InputProps } from "antd/es/input";
import { DatePickerProps } from "antd/es/date-picker";
import { SelectProps } from "antd/es/select";
import { CheckboxProps } from "antd/es/checkbox";

const { TextArea } = Input;
const { Option } = Select;

const statusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

export const PatientRecordFilterConfig: (FieldConfig | SelectFieldConfig)[] = [
  {
    name: "fullName",
    label: "Full Name",
    component: Input,
    rules: [{ required: true, message: "Please enter full name" }],
    props: { placeholder: "Enter full name" } as InputProps,
    colSpan: 24,
    componentType: "Input",
  },
  {
    name: "email",
    label: "Email",
    component: Input,
    rules: [
      { required: true, message: "Please enter email" },
      { type: "email", message: "Please enter a valid email" },
    ],
    props: { placeholder: "Enter email" } as InputProps,
    colSpan: 24,
    componentType: "Input",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    component: Input,
    rules: [{ required: true, message: "Please enter phone number" }],
    props: { placeholder: "Enter phone number" } as InputProps,
    colSpan: 24,
    componentType: "Input",
  },
  {
    name: "dob",
    label: "Date of Birth",
    component: DatePicker,
    rules: [{ required: true, message: "Please select date of birth" }],
    props: { style: { width: "100%" }, placeholder: "Select date of birth" } as DatePickerProps,
    colSpan: 24,
    componentType: "DatePicker",
  },
  {
    name: "address",
    label: "Address",
    component: TextArea,
    rules: [{ required: true, message: "Please enter address" }],
    props: { placeholder: "Enter address", rows: 3 } as TextAreaProps,
    colSpan: 24,
  },
  {
    name: "medicareNumber",
    label: "Medicare Number",
    component: Input,
    props: { placeholder: "Enter Medicare number" } as InputProps,
    colSpan: 24,
    componentType: "Input",
  },
  {
    name: "visionPatientId",
    label: "Vision Patient ID",
    component: Input,
    props: { placeholder: "Enter Vision Patient ID" } as InputProps,
    colSpan: 24,
    componentType: "Input",
  },
  {
    name: "status",
    label: "Status",
    component: Select,
    options: statusOptions,
    props: { placeholder: "Select status" } as SelectProps,
    colSpan: 24,
    componentType: "Select",
  } as SelectFieldConfig,
  {
    name: "includeActivePatients",
    label: "Include Active Patients",
    component: Checkbox,
    valuePropName: "checked",
    props: { children: "Include Active Patients" } as CheckboxProps,
    colSpan: 24,
    componentType: "Checkbox",
  },
  {
    name: "includeDeceasedPatients",
    label: "Include Deceased Patients",
    component: Checkbox,
    valuePropName: "checked",
    props: { children: "Include Deceased Patients" } as CheckboxProps,
    colSpan: 24,
    componentType: "Checkbox",
  },
];

export default PatientRecordFilterConfig;
