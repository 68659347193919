/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button, Checkbox, DatePicker, Form, Input, Select, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as SaveIcon } from "assets/icon3.svg";
import { ReactComponent as CopyIcon } from "assets/icon1.svg";
import { ReactComponent as ClipboardIcon } from "assets/icon2.svg";
import { ReactComponent as UserIcon } from "assets/icon5.svg";
import { ReactComponent as PatientIcon } from "assets/icon6.svg";
import { FieldConfig, SelectFieldConfig } from "types/form.config.type";
import { NavLink } from "react-router-dom";
import {
  FormHeader,
  StyledRadioGroup,
  customRadioStyles,
  SmallButton,
  customCheckboxStyles,
} from "../styles";

const { Option } = Select;

const FormHeaderSection = () => (
  <FormHeader justify="space-between">
    <StyledRadioGroup
      options={[
        { label: "Walk In", value: "walk" },
        { label: "Appointment", value: "appointment" },
      ]}
      defaultValue="walk"
      optionType="button"
      buttonStyle="solid"
      css={customRadioStyles}
    />
    <Space>
      <SmallButton icon={<CopyIcon />} />
      <SmallButton icon={<ClipboardIcon />} />
      <SmallButton type="primary" icon={<SaveIcon />}>
        Save
      </SmallButton>
    </Space>
  </FormHeader>
);

const PatientSection = () => (
  <Space>
    <SmallButton type="primary" icon={<PatientIcon />} style={{ backgroundColor: "#263446" }}>
      <NavLink to="/patient-records">Find or create patient record</NavLink>
    </SmallButton>
    <SmallButton icon={<UserIcon />}>Recent Patient</SmallButton>
  </Space>
);

const AppointmentFormConfig: (FieldConfig | SelectFieldConfig)[] = [
  {
    name: "",
    component: FormHeaderSection,
    colSpan: 24,
  },
  {
    name: "firstName",
    label: "First Name",
    component: Input,
    props: { placeholder: "Enter your first name" },
    rules: [{ required: true, message: "This is required" }],
    colSpan: 12,
    componentType: "Input",
  },
  {
    name: "lastName",
    label: "Last Name",
    component: Input,
    props: { placeholder: "Enter your last name" },
    rules: [{ required: true, message: "This is required" }],
    colSpan: 12,
    componentType: "Input",
  },
  {
    name: "mobileNumber",
    label: "Mobile Number",
    component: Input,
    props: { placeholder: "Enter your mobile number" },
    rules: [{ required: true, message: "This is required" }],
    colSpan: 12,
    componentType: "Input",
  },
  {
    name: "dateOfBirth",
    label: "Date of Birth",
    component: DatePicker,
    props: { style: { width: "100%" }, placeholder: "Select date of birth" },
    colSpan: 12,
    componentType: "DatePicker",
  },
  {
    name: "email",
    label: "Email",
    component: Input,
    props: { placeholder: "Enter your email" },
    rules: [{ type: "email", message: "Invalid email type" }],
    colSpan: 12,
    componentType: "Input",
  },
  {
    name: "gender",
    label: "Gender",
    component: Select,
    props: { placeholder: "Select gender" },
    options: [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "other", label: "Other" },
    ],
    colSpan: 12,
    componentType: "Select",
  },
  {
    name: "",
    component: PatientSection,
    colSpan: 24,
  },
  {
    name: "appointmentType",
    label: "Appointment Type",
    component: Select,
    props: { placeholder: "Select type" },
    options: [
      { value: "walk", label: "Walk In" },
      { value: "appointment", label: "Appointment" },
    ],
    colSpan: 24,
    componentType: "Select",
  },
  {
    name: "dateAndTime",
    label: "Date and Time",
    component: DatePicker,
    props: { showTime: true, style: { width: "100%" }, placeholder: "Select date and time" },
    colSpan: 12,
    componentType: "DatePicker",
  },
  {
    name: "room",
    label: "Room",
    component: Select,
    props: { placeholder: "Select room" },
    options: [
      { value: "room1", label: "Room 1" },
      { value: "room2", label: "Room 2" },
    ],
    colSpan: 12,
    componentType: "Select",
  },
  {
    name: "status",
    label: "Status",
    component: Select,
    props: { placeholder: "Select status" },
    options: [
      { value: "scheduled", label: "Scheduled" },
      { value: "completed", label: "Completed" },
      { value: "cancelled", label: "Cancelled" },
    ],
    colSpan: 12,
    componentType: "Select",
  },
  {
    name: "scheduledPractitioner",
    label: "Scheduled Practitioner",
    component: Select,
    props: { placeholder: "Select practitioner" },
    options: [
      { value: "dr_smith", label: "Dr. Smith" },
      { value: "dr_jones", label: "Dr. Jones" },
    ],
    colSpan: 12,
    componentType: "Select",
  },
  {
    name: "bookingNotes",
    label: "Booking Notes",
    component: TextArea,
    props: { rows: 3, placeholder: "Enter booking notes" },
    colSpan: 24,
  },
  {
    name: "enableMessage",
    component: Checkbox,
    valuePropName: "checked",
    props: { defaultChecked: true, css: customCheckboxStyles },
    checkboxText: "Enable message to the patient for this appointment booking.",
    colSpan: 24,
    componentType: "Checkbox",
  },
  {
    name: "addBookingPerson",
    component: (props: any) => (
      <Form.Item>
        <Button type="primary" icon={<PlusOutlined />} style={{ fontSize: "13px" }}>
          Add Booking Person
        </Button>
      </Form.Item>
    ),
    colSpan: 24,
  },
];

export default AppointmentFormConfig;
