import { useApiService } from "hooks/useApiService";
import { AppointmentInterface, AppointmentResponseInterface } from "./types";
import { QUERY_KEYS } from "constants/queryKeys";
import { appointmentApi } from "./appointmentApi";

export const useAppointmentService = () => {
  return useApiService<AppointmentResponseInterface, AppointmentInterface>(
    QUERY_KEYS.APPOINTMENT,
    appointmentApi
  );
};
