import { useReducer, useCallback, useEffect } from "react";
import { SOCKET_EVENTS } from "enums/socketEvents";
import { Socket } from "socket.io-client";
import { storage } from "utils/storage";
import {
  SupportTicket,
  SupportTicketMessage,
  useSupportTicketService,
} from "services/SupportTicketService";
import { useTicketTypeService } from "services/TicketTypeService";

type SupportAction =
  | { type: "SET_SELECTED_TICKET"; payload: SupportTicket | null }
  | { type: "SET_MESSAGES"; payload: SupportTicketMessage[] }
  | { type: "SET_MESSAGE"; payload: string }
  | { type: "SET_MODAL_OPEN"; payload: boolean };

interface SupportState {
  selectedTicket: SupportTicket | null;
  messages: SupportTicketMessage[];
  message: string;
  isModalOpen: boolean;
}

const initialState: SupportState = {
  selectedTicket: null,
  messages: [
    {
      _id: "64f2b2f1e2b4f9d1d8b4e301",
      isDescription: false,
      isRead: false,
      content: "Hi, I noticed an issue with the app when trying to submit a form.",
      type: "text",
      receiver: "64f2b2f1e2b4f9d1d8b4e2c1", // Support agent
      sender: "64f2b2f1e2b4f9d1d8b4e2c2", // User
      ticketId: "64f2b2f1e2b4f9d1d8b4e2c3",
      version: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    {
      _id: "64f2b2f1e2b4f9d1d8b4e302",
      isDescription: true,
      isRead: true,
      content: "Thanks for reporting the issue. Could you please provide more details?",
      type: "text",
      receiver: "64f2b2f1e2b4f9d1d8b4e2c2", // User
      sender: "64f2b2f1e2b4f9d1d8b4e2c1", // Support agent
      ticketId: "64f2b2f1e2b4f9d1d8b4e2c3",
      version: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    {
      _id: "64f2b2f1e2b4f9d1d8b4e303",
      isDescription: false,
      isRead: true,
      content: "Sure, the issue happens when I click the submit button after filling out the form.",
      type: "text",
      receiver: "64f2b2f1e2b4f9d1d8b4e2c1", // Support agent
      sender: "64f2b2f1e2b4f9d1d8b4e2c2", // User
      ticketId: "64f2b2f1e2b4f9d1d8b4e2c3",
      version: 1,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    {
      _id: "64f2b2f1e2b4f9d1d8b4e304",
      isDescription: true,
      isRead: false,
      content: "Thanks for the details. We will investigate and get back to you shortly.",
      type: "text",
      receiver: "64f2b2f1e2b4f9d1d8b4e2c2", // User
      sender: "64f2b2f1e2b4f9d1d8b4e2c1", // Support agent
      ticketId: "64f2b2f1e2b4f9d1d8b4e2c3",
      version: 2,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    {
      _id: "64f2b2f1e2b4f9d1d8b4e305",
      isDescription: false,
      isRead: true,
      content: "Great, thank you! I'll wait for your update.",
      type: "text",
      receiver: "64f2b2f1e2b4f9d1d8b4e2c1", // Support agent
      sender: "64f2b2f1e2b4f9d1d8b4e2c2", // User
      ticketId: "64f2b2f1e2b4f9d1d8b4e2c3",
      version: 1,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
  ],
  message: "",
  isModalOpen: false,
};

const supportReducer = (state: SupportState, action: SupportAction): SupportState => {
  switch (action.type) {
    case "SET_SELECTED_TICKET":
      return { ...state, selectedTicket: action.payload };
    case "SET_MESSAGES":
      return { ...state, messages: action.payload };
    case "SET_MESSAGE":
      return { ...state, message: action.payload };
    case "SET_MODAL_OPEN":
      return { ...state, isModalOpen: action.payload };
    default:
      return state;
  }
};

const dummyTickets: SupportTicket[] = [
  {
    _id: "64f2b2f1e2b4f9d1d8b4e2c1",
    ticketCode: "TICKET-001",
    ticketType: {
      _id: "64f2b2f1e2b4f9d1d8b4e2c2",
      ticketType: "Bug Report",
    },
    ticketHandlers: ["64f2b2f1e2b4f9d1d8b4e2c3", "64f2b2f1e2b4f9d1d8b4e2c4"],
    currentTicketHandler: "64f2b2f1e2b4f9d1d8b4e2c5",
    ticketIssuer: "64f2b2f1e2b4f9d1d8b4e2c6",
    ticketIssuerType: "User",
    isDeleted: false,
    __v: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    _id: "64f2b2f1e2b4f9d1d8b4e2c7",
    ticketCode: "TICKET-002",
    ticketType: {
      _id: "64f2b2f1e2b4f9d1d8b4e2c8",
      ticketType: "Feature Request",
    },
    ticketHandlers: ["64f2b2f1e2b4f9d1d8b4e2c9"],
    currentTicketHandler: "64f2b2f1e2b4f9d1d8b4e2ca",
    ticketIssuer: "64f2b2f1e2b4f9d1d8b4e2cb",
    ticketIssuerType: "Admin",
    isDeleted: false,
    __v: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    _id: "64f2b2f1e2b4f9d1d8b4e2cc",
    ticketCode: "TICKET-003",
    ticketType: {
      _id: "64f2b2f1e2b4f9d1d8b4e2cd",
      ticketType: "Question",
    },
    ticketHandlers: ["64f2b2f1e2b4f9d1d8b4e2ce", "64f2b2f1e2b4f9d1d8b4e2cf"],
    currentTicketHandler: "64f2b2f1e2b4f9d1d8b4e2d0",
    ticketIssuer: "64f2b2f1e2b4f9d1d8b4e2d1",
    ticketIssuerType: "Support",
    isDeleted: false,
    __v: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    _id: "64f2b2f1e2b4f9d1d8b4e2d2",
    ticketCode: "TICKET-004",
    ticketType: {
      _id: "64f2b2f1e2b4f9d1d8b4e2d3",
      ticketType: "Bug Report",
    },
    ticketHandlers: ["64f2b2f1e2b4f9d1d8b4e2d4"],
    currentTicketHandler: "64f2b2f1e2b4f9d1d8b4e2d5",
    ticketIssuer: "64f2b2f1e2b4f9d1d8b4e2d6",
    ticketIssuerType: "User",
    isDeleted: true,
    __v: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    _id: "64f2b2f1e2b4f9d1d8b4e2d7",
    ticketCode: "TICKET-005",
    ticketType: {
      _id: "64f2b2f1e2b4f9d1d8b4e2d8",
      ticketType: "Feature Request",
    },
    ticketHandlers: ["64f2b2f1e2b4f9d1d8b4e2d9", "64f2b2f1e2b4f9d1d8b4e2da"],
    currentTicketHandler: "64f2b2f1e2b4f9d1d8b4e2db",
    ticketIssuer: "64f2b2f1e2b4f9d1d8b4e2dc",
    ticketIssuerType: "Admin",
    isDeleted: false,
    __v: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const useTicketManagement = () => {
  const [state, dispatch] = useReducer(supportReducer, initialState);
  const { useGetAll: useGetAllSupportTickets, usePatch: useMarkTicketComplete } =
    useSupportTicketService();
  const { data: tickets, isLoading: ticketsLoading } = useGetAllSupportTickets();
  const { useGetAll } = useTicketTypeService();
  const { data: ticketTypes } = useGetAll();
  const markCompleteMutation = useMarkTicketComplete();
  const user = storage.getUser();

  const handleSendMessage = useCallback(
    (message: string, selectedTicket: SupportTicket | null, socket: Socket | null) => {
      if (message && selectedTicket && socket) {
        const textMessage = {
          body: {
            messageType: "text",
            messageContent: message,
            ticket: selectedTicket._id,
            sender: "user@example.com",
            receiver:
              "user@example.com" === selectedTicket.currentTicketHandler
                ? selectedTicket.ticketIssuer
                : selectedTicket.currentTicketHandler,
          },
        };
        socket.emit(
          SOCKET_EVENTS.SEND_TICKET_MESSAGE,
          textMessage,
          (data: { data: SupportTicketMessage }) => {
            dispatch({ type: "SET_MESSAGES", payload: [...state.messages, data.data] });
            dispatch({ type: "SET_MESSAGE", payload: "" });
          }
        );
      }
    },
    [state.messages]
  );

  const handleMarkComplete = useCallback(
    async (selectedTicket: SupportTicket | null) => {
      if (selectedTicket) {
        try {
          await markCompleteMutation.mutateAsync({ id: selectedTicket._id, status: "Completed" });
        } catch (error) {
          console.error(error);
        }
      }
    },
    [markCompleteMutation]
  );

  return {
    state,
    dispatch,
    handleSendMessage,
    handleMarkComplete,
    tickets: tickets?.data ?? dummyTickets,
    ticketTypes: ticketTypes?.data ?? [],
    ticketsLoading,
  };
};
