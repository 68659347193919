import { ReactComponent as Logo } from "assets/svg/kyama-logo.svg";
import { ReactComponent as HomeIcon } from "assets/svg/Home.svg";
import { ReactComponent as NotificationIcon } from "assets/svg/notification.svg";
import { ReactComponent as PatientRecordIcon } from "assets/svg/patient.svg";
import { ReactComponent as ScheduleIcon } from "assets/svg/schedule.svg";
import { ReactComponent as InfoIcon } from "assets/svg/info.svg";
import { ReactComponent as MessageIcon } from "assets/svg/message.svg";
import { ReactComponent as PrintIcon } from "assets/svg/print.svg";
import { ReactComponent as AccountIcon } from "assets/svg/acount.svg";

export type IconType = {
  Logo: React.FC<React.SVGProps<SVGSVGElement>>;
  HomeIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  NotificationIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  PatientRecordIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  ScheduleIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  InfoIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  MessageIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  PrintIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  AccountIcon: React.FC<React.SVGProps<SVGSVGElement>>;
};

const icons: IconType = {
  Logo,
  HomeIcon,
  NotificationIcon,
  PatientRecordIcon,
  ScheduleIcon,
  InfoIcon,
  MessageIcon,
  PrintIcon,
  AccountIcon,
};

export default icons;
