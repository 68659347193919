export const API_ENDPOINTS = {
  PATIENTS: {
    BASE: "/patients",
    CREATE: "/patients",
    READ: (id: string) => `/patients/${id}`,
    UPDATE: (id: string) => `/patients/${id}`,
    DELETE: (id: string) => `/patients/${id}`,
  },

  CONSULTATIONS: {
    BASE: "/consultations",
    CREATE: "/consultations",
    READ: (id: string) => `/consultations/${id}`,
    UPDATE: (id: string) => `/consultations/${id}`,
    DELETE: (id: string) => `/consultations/${id}`,
  },

  SUBSCRIPTIONS: {
    BASE: "/subscription-package",
    CREATE: "/subscription-package",
    READ: (id: string) => `/subscription-package/${id}`,
    UPDATE: (id: string) => `/subscription-package/${id}`,
    DELETE: (id: string) => `/subscription-package/${id}`,
  },

  APPOINTMENTS: {
    BASE: "/appointments",
    CREATE: "/appointments",
    READ: (id: string) => `/appointments/${id}`,
    UPDATE: (id: string) => `/appointments/${id}`,
    DELETE: (id: string) => `/appointments/${id}`,
  },

  AUTH: {
    LOGIN: "/signin",
    REGISTER: "/signup",
  },

  CHANGE_PASSWORD: {
    REQUEST_OTP: "/change-password-request",
    VERIFY_OTP: "/otp-verify",
    CHANGE_PASSWORD: "/change-password",
  },

  RESET_PASSWORD: {
    REQUEST: "/forgot-password?isSendMail=false",
    VERIFY: "/otp-verify",
    RESET: "/reset-password",
  },

  SUPPORT_TICKETS: {
    BASE: "/support-ticket",
    GET_ALL: "/support-ticket",
    CREATE: "/support-ticket",
    UPDATE: (id: string) => `/support-ticket/${id}`,
    MARK_COMPLETE: (id: string) => `/support-ticket/${id}/status`,
    READ: (id: string) => `/support-ticket/${id}`,
  },

  SOCKET: {
    BASE: "/ws/v1/ticket-message",
  },

  TICKET_TYPES: {
    BASE: "/support-ticket-type",
    GET_ALL: "/support-ticket-type",
    GET_BY_ID: (id: string) => `/support-ticket-type/${id}`,
    CREATE: "/support-ticket-type",
    UPDATE: (id: string) => `/support-ticket-type/${id}`,
    DELETE: (id: string) => `/support-ticket-type/${id}`,
  },

  USER_EMAILS: {
    GET_ALL: "/all_users?search=test",
  },
};
