import React from "react";
import { Card, Col, FormInstance, Row } from "antd";
import FormFieldRenderer from "./FormFieldRenderer";
import { FormFieldConfig, SectionConfig } from "types/form.config.type";
import { FormContainer } from "./styles";

type FieldsConfigType = FormFieldConfig[] | Record<string, SectionConfig>;

interface DynamicFormBuilderProps<T> {
  form?: FormInstance<T>;
  fieldsConfig: FieldsConfigType;
}

const FieldList: React.FC<{ fields: FormFieldConfig[] }> = ({ fields }) => (
  <Row gutter={[24, 24]}>
    {fields.map((fieldConfig, index) => (
      <Col span={fieldConfig.colSpan || 24} key={index}>
        <FormFieldRenderer fieldConfig={fieldConfig} />
      </Col>
    ))}
  </Row>
);

const DynamicFormBuilder = <T,>({
  form,
  fieldsConfig,
}: DynamicFormBuilderProps<T>): JSX.Element => {
  if (Array.isArray(fieldsConfig)) {
    return <FieldList fields={fieldsConfig} />;
  }

  return (
    <>
      {Object.entries(fieldsConfig).map(([sectionKey, section]) => {
        if (!section.fields || !Array.isArray(section.fields)) return null;

        const content = <FieldList fields={section.fields} />;

        return (
          <div key={sectionKey} style={{ marginBottom: "24px" }}>
            {section.wrapWithCard ? (
              <FormContainer title={section.title} style={{ marginBottom: "24px" }}>
                {content}
              </FormContainer>
            ) : (
              <div>
                {section.title && <h3>{section.title}</h3>}
                {content}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default DynamicFormBuilder;
