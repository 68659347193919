import React from "react";
import { Button, Row, Col, DatePicker, Select, Space, Typography } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
  FunnelPlotFilled,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { format, startOfWeek, endOfWeek, subDays, addDays, addWeeks, getMonth } from "date-fns";
import { useAppointmentContext } from "../context";

const { Option } = Select;
const { Text } = Typography;

const MonthName: React.FC = () => {
  const { startWeek, viewOption } = useAppointmentContext();

  const getMonthName = () => {
    const today = startWeek;
    const todayFormatted = format(today, "iii dd MMMM");

    const startOfWeekDate = startOfWeek(today, { weekStartsOn: 1 });
    const endOfWeekDate = endOfWeek(startOfWeekDate, { weekStartsOn: 1 });

    const startDayName = format(startOfWeekDate, "EEE");
    const endDayName = format(endOfWeekDate, "EEE");

    const startMonth = format(startOfWeekDate, "MMM");
    const endMonth = format(endOfWeekDate, "MMM");

    const startDay = format(startOfWeekDate, "d");
    const endDay = format(endOfWeekDate, "d");

    if (viewOption === "1 day") {
      return todayFormatted;
    }

    if (getMonth(endOfWeekDate) === getMonth(startOfWeekDate)) {
      return `${startDayName} ${startMonth} ${startDay} - ${endDayName} ${endMonth} ${endDay}`;
    }

    return `${startDayName} ${startMonth} ${startDay} - ${endDayName} ${endMonth} ${endDay}`;
  };

  return <Text>{getMonthName()}</Text>;
};

const CalendarControls: React.FC = () => {
  const { setSelectedDate, setOpen, setViewOption, viewOption, setStartWeek, setIsEditMode } =
    useAppointmentContext();

  const handleDropdownChange = (value: string) => {
    setViewOption(value);
    setStartWeek(new Date());
  };

  const onChange = (date: any, dateString: any) => {
    const formattedDate = dateString ? new Date(dateString) : startOfWeek(new Date());
    setSelectedDate(formattedDate);
  };

  const handleWeekChange = (delta: number) => {
    if (viewOption === "1 day") {
      setStartWeek((prevStartWeek) =>
        delta < 0 ? subDays(prevStartWeek, 1) : addDays(prevStartWeek, 1)
      );
    } else {
      setStartWeek((prevStartWeek) =>
        delta < 0 ? addWeeks(prevStartWeek, -1) : addWeeks(prevStartWeek, 1)
      );
    }
  };

  return (
    <Row
      justify="space-between"
      align="middle"
      style={{
        marginBottom: "30px",
        padding: "20px",
        background: "#f5f5f5",
        borderRadius: "4px",
      }}
    >
      <Col>
        <Space>
          <Select
            onChange={handleDropdownChange}
            value={viewOption}
            style={{ width: 150 }}
            placeholder="Select view"
          >
            <Option value="7 days">7 days</Option>
            <Option value="1 day">1 day</Option>
          </Select>
          <Button icon={<FunnelPlotFilled />} style={{ color: "#80889E", border: "none" }}>
            Filter
          </Button>
          <Button icon={<QuestionCircleFilled />} style={{ color: "#80889E", border: "none" }}>
            Legend
          </Button>
        </Space>
      </Col>
      <Col>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              setViewOption("7 days");
              setStartWeek(startOfWeek(new Date()));
            }}
          >
            This Week
          </Button>
          <Button
            icon={<LeftOutlined />}
            onClick={() => handleWeekChange(-1)}
            style={{ marginRight: 5 }}
          />
          <Button>
            <MonthName />
          </Button>
          <Button
            icon={<RightOutlined />}
            onClick={() => handleWeekChange(1)}
            style={{ marginLeft: 5 }}
          />
          <DatePicker onChange={onChange} />
        </Space>
      </Col>
      <Col>
        <Button
          type="primary"
          onClick={() => {
            setIsEditMode(false);
            setOpen(true);
          }}
          icon={<PlusOutlined />}
        >
          Appointment
        </Button>
      </Col>
    </Row>
  );
};

export default CalendarControls;
