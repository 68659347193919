interface ServiceConfig {
  basePath: string;
}

export enum ServiceName {
  AuthService = "authService",
  SupportService = "supportService",
  Socket = "socket",
}

interface ApiConfig {
  globalBaseUrl: string;
  apiVersion: string;
  services: {
    [ServiceName.AuthService]: ServiceConfig;
    [ServiceName.SupportService]: ServiceConfig;
    [ServiceName.Socket]: ServiceConfig;
  };
}

const GLOBAL_BASE_URL = process.env.REACT_APP_API_ENDPOINT ?? "";
const API_VERSION = "/api/v1";

const apiConfig: Readonly<ApiConfig> = {
  globalBaseUrl: GLOBAL_BASE_URL,
  apiVersion: API_VERSION,
  services: {
    [ServiceName.AuthService]: {
      basePath: "/admin",
    },
    [ServiceName.SupportService]: {
      basePath: "/support-ticket",
    },
    [ServiceName.Socket]: {
      basePath: "/ws/v1/ticket-message",
    },
  },
};

export default apiConfig;
