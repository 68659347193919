/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Card, Form, Input, Dropdown, Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { cardStyle } from "../styles";

const NotesCard: React.FC<{ title: string }> = ({ title }) => {
  const [form] = Form.useForm();

  const handleMenuClick = (e: any) => {
    form.setFieldsValue({ [title]: e.key }); // Update form field value
  };

  const dropdownMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Episcleritis Simple Form">Episcleritis Simple Form</Menu.Item>
      <Menu.Item key="Trichiasis">Trichiasis</Menu.Item>
      <Menu.Item key="Verruca Digitata">Verruca Digitata</Menu.Item>
      <Menu.Item key="Granulomatous Scleritis">Granulomatous Scleritis</Menu.Item>
      <Menu.Item key="Pitosis">Pitosis</Menu.Item>
      <Menu.Item key="Verruca Vulagris">Verruca Vulagris</Menu.Item>
    </Menu>
  );

  return (
    <Card
      css={cardStyle}
      title={title}
      style={{ flex: 1, boxShadow: "0px 8px 40px -10px #00000014" }}
      extra={
        <Dropdown overlay={dropdownMenu} trigger={["click"]}>
          <MenuOutlined style={{ fontSize: "16px", cursor: "pointer", color: "#1499B8" }} />
        </Dropdown>
      }
    >
      <Form form={form} initialValues={{ [title]: "" }}>
        <Form.Item name={title}>
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default NotesCard;
