import React from "react";
import PageTemplate from "components/PageTemplate";
import DashboardHeader from "./DashboardHeader";
import DashboardSummary from "./DashboardSummary";
import DashboardActions from "./DashboardActions";
import DashboardCharts from "./DashboardCharts";
import { Col, Row } from "antd";

const Dashboard = () => {
  return (
    <PageTemplate showBreadcrumbs={false}>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <DashboardHeader />
        </Col>
        <Col span={18}>
          <DashboardSummary />
        </Col>
        <Col span={6}>
          <DashboardActions />
        </Col>
        <Col span={24}>
          <DashboardCharts />
        </Col>
      </Row>
    </PageTemplate>
  );
};

export default Dashboard;
