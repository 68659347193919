import queryString from "query-string";

export class QueryParamsConfig {
  filters: Record<string, any> = {};
  pagination: { page: number; pageSize: number } = { page: 1, pageSize: 10 };
  sort: { sortBy?: string; sortOrder?: "asc" | "desc" } = {};

  constructor(params?: Partial<QueryParamsConfig>) {
    Object.assign(this, params);
  }

  toQueryString(): string {
    const params: Record<string, any> = {};

    if (Object.keys(this.filters).length) {
      for (const [key, value] of Object.entries(this.filters)) {
        params[`filters[${key}]`] = value;
      }
    }

    if (this.pagination) {
      params.page = this.pagination.page;
      params.pageSize = this.pagination.pageSize;
    }

    if (this.sort) {
      if (this.sort.sortBy) params.sortBy = this.sort.sortBy;
      if (this.sort.sortOrder) params.sortOrder = this.sort.sortOrder;
    }

    return queryString.stringify(params);
  }

  static fromQueryString(query: string): QueryParamsConfig {
    const parsed = queryString.parse(query);
    const filters: Record<string, any> = {};

    for (const [key, value] of Object.entries(parsed)) {
      if (key.startsWith("filters[")) {
        const filterKey = key.slice(8, -1);
        filters[filterKey] = Array.isArray(value) ? value[0] : value;
      }
    }

    return new QueryParamsConfig({
      filters,
      pagination: {
        page: Number(parsed.page) || 1,
        pageSize: Number(parsed.pageSize) || 10,
      },
      sort: {
        sortBy: typeof parsed.sortBy === "string" ? parsed.sortBy : undefined,
        sortOrder: (typeof parsed.sortOrder === "string" ? parsed.sortOrder : undefined) as
          | "asc"
          | "desc"
          | undefined,
      },
    });
  }
}
