import React, { createContext, ReactNode, useContext, useState } from "react";
import { startOfWeek } from "date-fns";

interface AppointmentContextType {
  viewOption: string;
  setViewOption: React.Dispatch<React.SetStateAction<string>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  shouldViewDetails: boolean;
  setShouldViewDetails: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCell: any;
  setSelectedCell: React.Dispatch<React.SetStateAction<any>>;
  selectedDate: Date | null;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
  startWeek: Date;
  setStartWeek: React.Dispatch<React.SetStateAction<Date>>;
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppointmentContext = createContext<AppointmentContextType | undefined>(undefined);

export const useAppointmentContext = () => {
  const context = useContext(AppointmentContext);
  if (!context) {
    throw new Error("useAppointmentContext must be used within an AppointmentProvider");
  }
  return context;
};

export const AppointmentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [viewOption, setViewOption] = useState("7 days");
  const [open, setOpen] = useState(false);
  const [shouldViewDetails, setShouldViewDetails] = useState(false);
  const [selectedCell, setSelectedCell] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [startWeek, setStartWeek] = useState<Date>(startOfWeek(selectedDate || new Date()));
  const [isEditMode, setIsEditMode] = useState(false);

  const value = {
    viewOption,
    setViewOption,
    open,
    setOpen,
    shouldViewDetails,
    setShouldViewDetails,
    selectedCell,
    setSelectedCell,
    selectedDate,
    setSelectedDate,
    startWeek,
    setStartWeek,
    isEditMode,
    setIsEditMode,
  };

  return <AppointmentContext.Provider value={value}>{children}</AppointmentContext.Provider>;
};
