import styled from "@emotion/styled";
import { Card } from "antd";

const FormContainer = styled(Card)`
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 24px;
  margin-bottom: 24px;
`;

export { FormContainer };
