import React, { useState } from "react";
import {
  Row,
  Space,
  Button,
  Avatar,
  Typography,
  Drawer,
  Divider,
  Descriptions,
  Card,
  Tabs,
  List,
  Tag,
  Modal,
  Input,
  Col,
  Select,
  Badge,
  Dropdown,
  Menu,
} from "antd";
import {
  PlusOutlined,
  MoreOutlined,
  CalendarOutlined,
  UserOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
  DownOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { StyledTitle, HeaderContainer } from "../styles";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const medicineData = [
  { name: "Erythromycin Ointment", price: "$12.50" },
  { name: "Tobramycin Drops", price: "$9.75" },
  { name: "Ciprofloxacin Drops", price: "$11.00" },
  { name: "Polymyxin B Drops", price: "$10.25" },
  { name: "Ofloxacin Drops", price: "$13.00" },
];

const listData = [
  {
    title: "Ocular History",
    tags: [
      "Myopia since childhood",
      "Underwent cataract surgery in 2015",
      "Diagnosed with astigmatism in 2020",
      "History of retinal detachment",
    ],
  },
  {
    title: "Family History",
    tags: [
      "Father diagnosed with glaucoma at age 60",
      "Mother has type 2 diabetes",
      "Brother has hypertension",
    ],
  },
  {
    title: "Allergy",
    tags: ["Allergic to penicillin", "Reacts to pollen in spring", "Dust mites cause sneezing"],
  },
  {
    title: "Medications",
    tags: [
      "Latanoprost for glaucoma",
      "Metformin for diabetes",
      "Daily aspirin",
      "Simvastatin for cholesterol",
    ],
  },
  {
    title: "Lifestyle",
    tags: [
      "Non-smoker for 10 years",
      "Engages in moderate exercise 3 times a week",
      "Follows a vegetarian diet",
      "Occasional social drinking",
    ],
  },
  {
    title: "Others",
    tags: [
      "Experiences frequent headaches",
      "Sensitive to bright lights (photophobia)",
      "Chronic fatigue since 2021",
    ],
  },
];

const recommendations = [
  {
    date: "Sep 1, 2024",
    content: "Patient seems to have Dry Eye Syndrome. Recommended the use of Artificial Tears.",
    by: "Dr. Daniel Tu",
  },
  {
    date: "Aug 28, 2024",
    content:
      "Patient has a history of hypertension. Recommended monitoring blood pressure regularly.",
    by: "Dr. Daniel Tu",
  },
  {
    date: "Aug 15, 2024",
    content: "Patient shows signs of early cataracts. Suggested routine eye exams.",
    by: "Dr. Daniel Tu",
  },
  {
    date: "Jul 30, 2024",
    content: "Patient reports frequent headaches. Recommended a comprehensive eye exam.",
    by: "Dr. Daniel Tu",
  },
  {
    date: "Jul 10, 2024",
    content:
      "Patient is a smoker. Advised to quit smoking to reduce the risk of macular degeneration.",
    by: "Dr. Daniel Tu",
  },
];

const ProfileHeader: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentTitle, setCurrentTitle] = useState("");
  const [selectedOption, setSelectedOption] = useState("Eye Test");

  const toggleDrawer = (isVisible: boolean) => setVisible(isVisible);
  const toggleModal = (isVisible: boolean, title?: string) => {
    if (title) setCurrentTitle(title);
    setModalVisible(isVisible);
  };

  const renderAvatar = (size: number, initials: string = "DT") => (
    <Avatar
      size={size}
      style={{
        backgroundColor: "#C8EFF9",
        color: "#0C5C6E",
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      {initials}
    </Avatar>
  );

  const renderTitle = (title: string, date: string) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <StyledTitle level={2} style={{ marginBottom: 0 }}>
        {title}
      </StyledTitle>
      <Space>
        <Typography.Text
          type="secondary"
          style={{ display: "flex", alignItems: "center", marginLeft: 8 }}
        >
          <CalendarOutlined style={{ marginRight: 4 }} />
          {date}
        </Typography.Text>

        <Dropdown
          overlay={
            <Menu onClick={({ key }) => setSelectedOption(key)}>
              <Menu.Item key="Eye Test">Eye Test</Menu.Item>
              <Menu.Item key="Children Vision Testing">Children Vision Testing</Menu.Item>
              <Menu.Item key="Contact Lens Consultation">Contact Lens Consultation</Menu.Item>
              <Menu.Item key="Contact Lens Follow-up">Contact Lens Follow-up</Menu.Item>
              <Menu.Item key="Emergency Consult">Emergency Consult</Menu.Item>
              <Menu.Item key="Eyelash Removal">Eyelash Removal</Menu.Item>
              <Menu.Item key="Glaucoma Test">Glaucoma Test</Menu.Item>
              <Menu.Item key="OCT Test">OCT Test</Menu.Item>
              <Menu.Item key="Telehealth Video Consultation">
                Telehealth Video Consultation
              </Menu.Item>
              <Menu.Item key="Visual Field Test">Visual Field Test</Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Typography.Text
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: 8,
            }}
          >
            <Badge status="error" style={{ marginRight: "8px" }} />
            {selectedOption} <DownOutlined style={{ marginLeft: 4 }} />
          </Typography.Text>
        </Dropdown>
      </Space>
    </div>
  );

  const renderTagList = (tags: string[]) =>
    tags.map((tag) => (
      <Tag
        closable
        key={tag}
        style={{
          backgroundColor: "#F3F4F6",
          borderColor: "#D1D5DB",
          color: "#6B7280",
          marginBottom: "8px",
          marginRight: "8px",
        }}
      >
        {tag}
      </Tag>
    ));

  const renderRecommendationList = recommendations.map((rec, index) => (
    <Card key={index} style={{ marginBottom: 20, border: "1px solid #D1D5DB", padding: "16px" }}>
      <Row align="top" style={{ marginBottom: 8 }}>
        {renderAvatar(40)}
        <div style={{ flex: 1, marginLeft: 12 }}>
          {rec.content}
          <div
            style={{
              marginTop: "8px",
              display: "flex",
              gap: "12px",
              alignItems: "center",
            }}
          >
            <span>
              <CalendarOutlined style={{ marginRight: 4 }} />
              <Typography.Text strong>Recommended Date:</Typography.Text> {rec.date}
            </span>

            <span
              style={{
                border: "1px solid #A3E4F5",
                backgroundColor: "#E3F7FC",
                color: "#0C5C6E",
                padding: "2px 6px",
                borderRadius: "6px",
              }}
            >
              <UserOutlined style={{ marginRight: 4 }} />
              <span>Recommended By: {rec.by}</span>
            </span>
          </div>
        </div>
      </Row>
    </Card>
  ));

  const renderMedicineList = medicineData.map((item, index) => (
    <Card bordered style={{ marginBottom: "16px", border: "1px solid #D1D5DB" }} key={index}>
      <Row align="middle" justify="space-between">
        <Col>
          <Typography.Title level={5}>Medicine {item.name}</Typography.Title>
          <Typography.Text type="secondary" style={{ fontSize: "16px" }}>
            Price: {item.price}
          </Typography.Text>
        </Col>
        <Col>
          <Button icon={<ShoppingCartOutlined />} type="primary">
            Add to Cart
          </Button>
        </Col>
      </Row>
    </Card>
  ));

  const renderTabs = (
    <Tabs defaultActiveKey="1" style={{ marginTop: "16px" }}>
      <TabPane tab="Notes" key="1">
        <List
          itemLayout="horizontal"
          dataSource={listData}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  icon={<PlusOutlined />}
                  style={{
                    color: "#007BFF",
                    fontWeight: "bold",
                    borderRadius: "4px",
                    backgroundColor: "#E3F7FC",
                    borderColor: "#007BFF",
                    borderWidth: "1px",
                  }}
                  onClick={() => toggleModal(true, item.title)}
                >
                  Add
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={<span style={{ fontWeight: "bold" }}>{item.title}</span>}
                description={renderTagList(item.tags)}
              />
            </List.Item>
          )}
        />
      </TabPane>
      <TabPane tab="Recommendations" key="2">
        {renderRecommendationList}
      </TabPane>
      <TabPane tab="Consult Billing" key="3">
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Input placeholder="Search medicine..." prefix={<SearchOutlined />} />
          </Col>
        </Row>
        {renderMedicineList}
      </TabPane>
    </Tabs>
  );

  return (
    <div>
      <HeaderContainer>
        <Row justify="space-between" align="middle">
          <Space align="center">
            {renderAvatar(48)}
            {renderTitle("Mr. Daniel TU", "Sep 2, 2024")}
          </Space>
          <Space>
            <Button type="primary" icon={<PlusOutlined />}>
              Book Now
            </Button>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1" icon={<EyeOutlined />} onClick={() => toggleDrawer(true)}>
                    View Profile
                  </Menu.Item>
                  <Menu.Item key="2" icon={<SearchOutlined />}>
                    Search Consultation
                  </Menu.Item>
                  <Menu.Item key="3" icon={<PlusOutlined />}>
                    Add Appointment
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <Button
                icon={<MoreOutlined />}
                style={{
                  fontSize: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </Dropdown>
          </Space>
        </Row>
      </HeaderContainer>

      <Drawer
        title=""
        placement="right"
        onClose={() => toggleDrawer(false)}
        visible={visible}
        width="100%"
        bodyStyle={{ padding: 0 }}
      >
        <div style={{ padding: "16px", margin: "0 180px" }}>
          <Row justify="space-between" align="middle">
            <Space align="center">
              {renderAvatar(64)}
              {renderTitle("Mr. Daniel TU", "Sep 2, 2024")}
            </Space>
            <Space>
              <Button type="primary" icon={<PlusOutlined />}>
                Book Now
              </Button>
              <Button icon={<MoreOutlined />} />
            </Space>
          </Row>

          <Divider />
          <Card
            style={{
              border: "2px dotted #9CA3AF",
              backgroundColor: "#F3F4F6",

              borderStyle: "dotted",
              borderWidth: "2px",
              borderColor: "#9CA3AF",
            }}
          >
            <Descriptions
              layout="horizontal"
              column={3}
              labelStyle={{ fontWeight: "bold", color: "black" }}
            >
              <Descriptions.Item label="Phone Number">09xxxxxxxxx</Descriptions.Item>
              <Descriptions.Item label="Email">example@example.com</Descriptions.Item>
              <Descriptions.Item label="Gender">Male</Descriptions.Item>
              <Descriptions.Item label="Date of Birth">20/12/1980</Descriptions.Item>
              <Descriptions.Item label="Nationality">Nepali</Descriptions.Item>
              <Descriptions.Item label="Address">Kathmandu, Nepal</Descriptions.Item>
            </Descriptions>
          </Card>

          {renderTabs}
        </div>
      </Drawer>

      <Modal
        title={currentTitle}
        visible={modalVisible}
        onCancel={() => toggleModal(false)}
        footer={
          <Space>
            <Button onClick={() => toggleModal(false)}>Cancel</Button>
            <Button type="primary">Submit</Button>
          </Space>
        }
      >
        <TextArea rows={4} placeholder={`Add ${currentTitle}...`} />
      </Modal>
    </div>
  );
};

export default ProfileHeader;
