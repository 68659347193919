/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import React, { ReactNode } from "react";
import CardWithTable from "./CardsWithTable";
import { matrixTableStyle } from "../styles";

const MatrixTableCard: React.FC<{
  title: string;
  dataSource: any[];
  columns: any[];
  extra?: ReactNode;
}> = ({ title, dataSource, columns, extra }) => (
  <CardWithTable
    title={title}
    dataSource={dataSource}
    columns={columns}
    tableStyle={matrixTableStyle}
    extra={extra}
  />
);

export default MatrixTableCard;
