/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import { Tabs, Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import PageTemplate from "components/PageTemplate";
import ProfileHeader from "./ProfileHeader";
import {
  FaCalendarCheck,
  FaEnvelopeOpenText,
  FaUserMd,
  FaBell,
  FaShoppingCart,
} from "react-icons/fa";
import DefaultLayout from "./DefaultLayout";
import InitialContactLens from "./InitialContactLens";
import ImagerySection from "./ImageryTab";
import DocumentsTab from "./DocumentsTab";
import CorrespondenceTab from "./CorrespondenceTab";
import RecallSettingTab from "./RecallSettingTab";
import BillingDetails from "./BillingDetails";
import { tabsStyle } from "../styles";

const { TabPane } = Tabs;

const ConsultationDetailsPage: React.FC = () => {
  const [selectedLayout, setSelectedLayout] = useState<string>("default");

  const handleMenuClick = (e: any) => {
    setSelectedLayout(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="default">Default Layout</Menu.Item>
      <Menu.Item key="initial">Initial Contact Lens</Menu.Item>
    </Menu>
  );

  const tabItems = [
    {
      label: (
        <Dropdown overlay={menu} trigger={["click"]} css={dropdownStyle}>
          <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
            <span>{selectedLayout === "default" ? "Default Layout" : "Initial Contact Lens"}</span>
            <DownOutlined style={{ marginLeft: 8 }} />
          </div>
        </Dropdown>
      ),
      key: "layout",
      content: selectedLayout === "default" ? <DefaultLayout /> : <InitialContactLens />,
    },
    {
      label: (
        <span>
          <FaCalendarCheck /> Imagery
        </span>
      ),
      key: "imagery",
      content: <ImagerySection />,
    },
    {
      label: (
        <span>
          <FaEnvelopeOpenText /> Documents
        </span>
      ),
      key: "documents",
      content: <DocumentsTab />,
    },
    {
      label: (
        <span>
          <FaUserMd /> Correspondence
        </span>
      ),
      key: "correspondence",
      content: <CorrespondenceTab />,
    },
    {
      label: (
        <span>
          <FaBell /> Recall Settings
        </span>
      ),
      key: "recall",
      content: <RecallSettingTab />,
    },
    {
      label: (
        <span>
          <FaShoppingCart /> Billing Details
        </span>
      ),
      key: "billingdetails",
      content: <BillingDetails />,
    },
  ];

  return (
    <PageTemplate currentNode="Consult">
      <ProfileHeader />
      <Tabs
        defaultActiveKey="layout"
        tabBarStyle={{ background: "#f5f5f5", borderBottom: "1px solid #e0e0e0" }}
        tabBarGutter={16}
        css={tabsStyle}
      >
        {tabItems.map((tab) => (
          <TabPane tab={tab.label} key={tab.key}>
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </PageTemplate>
  );
};

const dropdownStyle = css`
  .ant-dropdown {
    display: inline-block;
    cursor: pointer;
  }
`;

export default ConsultationDetailsPage;
