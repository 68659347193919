import React, { useEffect } from "react";
import { Button, Form, Input, Modal, Spin, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { StyledTextArea } from "../../styles";
import { useTicketTypeService } from "services/TicketTypeService";

const { Title } = Typography;

interface AddTicketTypesProps {
  id?: string;
  isModalOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

const AddTicketTypes: React.FC<AddTicketTypesProps> = ({ id, isModalOpen, onClose, onSave }) => {
  const [form] = useForm();
  const isEditMode = Boolean(id);
  const { useGetById, useCreate, useUpdate } = useTicketTypeService();

  const { data, isFetching } = useGetById(id || "");
  const createTicketTypeMutation = useCreate();
  const updateTicketTypeMutation = useUpdate();

  useEffect(() => {
    if (isEditMode && data) {
      form.setFieldsValue({
        ticketType: data.ticketType,
        description: data.description,
      });
    } else {
      form.resetFields();
    }
  }, [data, form, isEditMode]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const payload = { ...values };

      const mutation = isEditMode
        ? updateTicketTypeMutation.mutateAsync({ id: id!, data: payload })
        : createTicketTypeMutation.mutateAsync(payload);

      await mutation;

      form.resetFields();
      onSave();
      onClose();
    } catch (error) {}
  };

  return (
    <Modal
      title={isEditMode ? "Update Ticket Type" : "Add Ticket Type"}
      open={isModalOpen}
      onCancel={onClose}
      onOk={handleSubmit}
      okButtonProps={{
        loading: isEditMode
          ? updateTicketTypeMutation.isPending
          : createTicketTypeMutation.isPending,

        disabled: isEditMode && isFetching,
      }}
    >
      {isFetching && isEditMode ? (
        <div
          style={{
            height: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Form.Item
            name="ticketType"
            label="Ticket Type"
            rules={[{ required: true, message: "This is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "This is required" }]}
          >
            <StyledTextArea rows={5} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AddTicketTypes;
