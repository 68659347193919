import { Area, Pie } from "@ant-design/charts";
import styled from "@emotion/styled";

export const StyledAreaChart = styled(Area)`
  fill: linear-gradient(-90deg, white 0%, darkgreen 100%);
`;

export const StyledPieChart = styled(Pie)`
  // Add any additional styles for the Pie chart here
`;
