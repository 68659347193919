import { SubscriptionPackageInterface, SubscriptionPackageResponseInterface } from "./types";
import { coreClient } from "utils/httpClient";
import { API_ENDPOINTS } from "constants/apiEndpoints";

const apiClient = coreClient;

export const subscriptionApi = {
  getAll: async (): Promise<SubscriptionPackageResponseInterface> => {
    const response = await apiClient.get<SubscriptionPackageResponseInterface>(
      API_ENDPOINTS.SUBSCRIPTIONS.BASE
    );
    return response.data;
  },

  getById: async (id: string): Promise<SubscriptionPackageInterface> => {
    const response = await apiClient.get<{
      success: boolean;
      data: SubscriptionPackageInterface;
      message?: string;
    }>(API_ENDPOINTS.SUBSCRIPTIONS.READ(id));
    return response.data.data;
  },

  create: async (
    subscriptionData: SubscriptionPackageInterface
  ): Promise<SubscriptionPackageInterface> => {
    const response = await apiClient.post<SubscriptionPackageInterface>(
      API_ENDPOINTS.SUBSCRIPTIONS.CREATE,
      subscriptionData
    );
    return response.data;
  },

  update: async (
    id: string,
    subscriptionData: SubscriptionPackageInterface
  ): Promise<SubscriptionPackageInterface> => {
    const response = await apiClient.put<SubscriptionPackageInterface>(
      API_ENDPOINTS.SUBSCRIPTIONS.UPDATE(id),
      subscriptionData
    );
    return response.data;
  },

  delete: async (id: string) => {
    await apiClient.delete<void>(API_ENDPOINTS.SUBSCRIPTIONS.DELETE(id));
  },
};
