import React from "react";
import { Select, Radio, Checkbox, Input, DatePicker } from "antd";
import { CheckboxProps, InputProps, DatePickerProps } from "antd/lib";
import { RadioGroupFieldConfig, SelectFieldConfig } from "types/form.config.type";

interface CheckboxFieldConfig {
  props: CheckboxProps;
  checkboxText?: string;
}

export const componentMap: Record<string, React.ElementType<any>> = {
  Select: ({ fieldConfig, ...props }: { fieldConfig: SelectFieldConfig }) => (
    <Select {...props}>
      {fieldConfig.options?.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  ),
  RadioGroup: ({ fieldConfig, ...props }: { fieldConfig: RadioGroupFieldConfig }) => (
    <Radio.Group {...props}>{fieldConfig.children}</Radio.Group>
  ),
  Checkbox: ({ fieldConfig, ...props }: { fieldConfig: CheckboxFieldConfig }) => (
    <Checkbox {...props}>{fieldConfig.checkboxText || "Default Checkbox Label"}</Checkbox>
  ),
  Input: (props: InputProps) => <Input {...props} />,
  DatePicker: (props: DatePickerProps) => <DatePicker {...props} />,
};
