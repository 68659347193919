import { useApiService } from "hooks/useApiService";
import { UserEmail } from "./type";
import { emailApi } from "./emailApi";
import { QUERY_KEYS } from "constants/queryKeys";

export const useEmailService = () => {
  return useApiService<UserEmail[], UserEmail>(QUERY_KEYS.USER_EMAILS, {
    getAll: emailApi.getAllEmails,
    getById: undefined,
    create: undefined,
    update: undefined,
    patch: undefined,
    delete: undefined,
  });
};
