import React, { useEffect } from "react";
import { Table, Button, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ActionKeys } from "enums/actionKeys";

interface ActionConfig<T extends Record<string, any>> {
  key: ActionKeys;
  onClick: (record: T) => void;
}

interface PaginatedTableProps<T extends Record<string, any>> {
  data: T[];
  total: number;
  isFetching: boolean;
  fetchData: () => void;
  columns: ColumnsType<T>;
  pagination: {
    current: number;
    pageSize: number;
    total: number;
    onChange: (page: number, pageSize: number) => void;
    showSizeChanger: boolean;
  };
  actionsConfig?: ActionConfig<T>[];
}

const PaginatedTable = <T extends Record<string, any>>({
  data,
  total,
  isFetching,
  fetchData,
  columns,
  pagination,
  actionsConfig = [],
}: PaginatedTableProps<T>) => {
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const actionsColumn = {
    title: "Actions",
    key: "actions",
    render: (text: any, record: T) => (
      <Space>
        {actionsConfig.map((action) => {
          switch (action.key) {
            case ActionKeys.EDIT:
              return (
                <Button
                  key={action.key}
                  onClick={() => action.onClick(record)}
                  type="link"
                  icon={<EditOutlined />}
                >
                  Edit
                </Button>
              );
            case ActionKeys.DELETE:
              return (
                <Button
                  key={action.key}
                  onClick={() => action.onClick(record)}
                  type="link"
                  icon={<DeleteOutlined />}
                  danger
                >
                  Delete
                </Button>
              );
            default:
              return null;
          }
        })}
      </Space>
    ),
  };

  const enhancedColumns = actionsConfig.length ? [...columns, actionsColumn] : columns;

  return (
    <Table<T>
      columns={enhancedColumns}
      dataSource={data}
      pagination={pagination}
      loading={isFetching}
    />
  );
};

export default PaginatedTable;
