import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Input, Select, Button, Checkbox, Radio, Drawer, Form, Row } from "antd";

export const CustomForm = styled(Form)`
  padding: 18px;
`;

export const FormHeader = styled(Row)`
  margin-bottom: 0px;
`;

export const FormRow = styled(Row)`
  margin-bottom: 16px;
`;

export const FormFooter = styled(Row)`
  margin-top: 24px;
`;

export const StyledInput = styled(Input)`
  font-size: 13px;
`;

export const StyledSelect = styled(Select)`
  font-size: 13px;
`;

export const SmallButton = styled(Button)`
  font-size: 13px;
`;

export const StyledLabel = styled.label`
  font-size: 13px;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-wrapper {
    font-size: 13px;
  }
`;

export const customRadioStyles = css`
  .ant-radio,
  [class^="ant-radio"],
  [class*=" ant-radio"] {
    font-family: "Manrope", sans-serif;
    font-size: 13px;
    box-sizing: border-box;
  }
`;

export const customCheckboxStyles = css`
  &.ant-checkbox-wrapper {
    font-size: 13px;
  }
`;

export const StyledDrawer = styled(Drawer)`
  &.ant-drawer,
  .ant-drawer-header {
    background-color: #1499b8;
  }
  &.ant-drawer,
  .ant-drawer-title {
    color: white;
  }

  &.ant-drawer,
  .ant-drawer-close {
    color: white;
  }
  &.ant-drawer,
  .ant-drawer-body {
    padding: 34px;
  }
`;

const customCheckboxWrapperStyles = css`
  display: flex;
  align-items: center;
  font-size: 13px;
  gap: 5px;
`;

export default {
  CustomForm,
  FormHeader,
  FormRow,
  FormFooter,
  StyledInput,
  StyledSelect,
  SmallButton,
  StyledLabel,
  StyledRadioGroup,
  customRadioStyles,
  customCheckboxStyles,
  StyledDrawer,
  customCheckboxWrapperStyles,
};
