/** @jsxImportSource @emotion/react */
import React from "react";
import { Row, Col } from "antd";
import { css } from "@emotion/react";
import { StyledAreaCard, StyledPieCard } from "../common/CardWrapper";
import { StyledAreaChart, StyledPieChart } from "../common/Chart";

const DashboardCharts: React.FC = () => {
  return (
    <Row gutter={16}>
      <Col span={16}>
        <StyledAreaCard>
          <StyledAreaChart
            data={{
              type: "fetch",
              value: "https://assets.antv.antgroup.com/g2/stocks.json",
              transform: [{ type: "filter", callback: (d: any) => d.symbol === "GOOG" }],
            }}
            xField={(d: any) => new Date(d.date)}
            yField="price"
            axis={{
              y: { labelFormatter: "~s" },
            }}
            line={{
              style: {
                stroke: "darkgreen",
                strokeWidth: 2,
              },
            }}
          />
        </StyledAreaCard>
      </Col>
      <Col span={8}>
        <StyledPieCard>
          <StyledPieChart
            data={[
              { type: "Option 1", value: 27 },
              { type: "Option 2", value: 25 },
              { type: "Option 3", value: 18 },
              { type: "Option 4", value: 15 },
              { type: "Other", value: 15 },
            ]}
            appendPadding={10}
            angleField="value"
            colorField="type"
            radius={1}
            label={{
              offset: "-30%",
              style: {
                fontSize: 14,
                textAlign: "center",
              },
            }}
            interactions={[{ type: "element-active" }]}
          />
        </StyledPieCard>
      </Col>
    </Row>
  );
};

export default DashboardCharts;
