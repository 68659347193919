import React from "react";
import withFormHandler from "hoc/withFormHandler";
import DynamicFormBuilder from "components/DynamicFormBuilder";
import {
  AppointmentInterface,
  AppointmentType,
  Gender,
  Practitioner,
  Room,
  Status,
  useAppointmentService,
} from "services/AppointmentService";
import AppointmentFormConfig from "./AppointmentFormConfig";
import { StyledDrawer } from "../styles";
import { useAppointmentContext } from "../context";
import dayjs from "dayjs";

const dummyAppointmentData: AppointmentInterface = {
  _id: "605c72ef0c6f7f1d7c0d0e32",
  firstName: "John",
  lastName: "Doe",
  mobileNumber: "+1234567890",
  dateOfBirth: dayjs("1980-05-15T00:00:00.000Z"),
  email: "john.doe@example.com",
  gender: Gender.Male,
  appointmentType: AppointmentType.Appointment,
  dateAndTime: dayjs("2024-09-10T15:30:00.000Z"),
  room: Room.Room1,
  status: Status.Scheduled,
  scheduledPractitioner: Practitioner.DrSmith,
  bookingNotes: "Follow-up visit for routine check-up.",
  enableMessage: true,
  addBookingPerson: "Jane Smith",
};

const AppointmentConfigurationForm = () => {
  const { useCreate, useUpdate, useGetById } = useAppointmentService();
  const { isEditMode } = useAppointmentContext();

  const AppointmentFormHandler = withFormHandler(DynamicFormBuilder, {
    useCreate: useCreate,
    useUpdate: useUpdate,
    useFetchById: (id: string) => useGetById(id),
    onSuccess: () => {},
    onError: (error) => {},
    fieldsConfig: AppointmentFormConfig,
    wrapInCard: false,
    redirectUrl: "appointment",
    initialValues: isEditMode ? dummyAppointmentData : undefined,
  });

  return <AppointmentFormHandler />;
};

const AppointmentForm = () => {
  const { open, setOpen, isEditMode, setIsEditMode } = useAppointmentContext();

  return (
    <StyledDrawer
      closable
      destroyOnClose
      title={isEditMode ? "Edit Appointment" : "New Appointment"}
      placement="right"
      open={open}
      onClose={() => {
        setOpen(false);
        setIsEditMode(false);
      }}
      width={500}
    >
      <AppointmentConfigurationForm />
    </StyledDrawer>
  );
};

export default AppointmentForm;
