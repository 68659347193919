/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Card, Space, Typography, Tag, Avatar } from "antd";
import {
  TagOutlined,
  UserOutlined,
  ClockCircleOutlined,
  CodeOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { SupportTicket } from "services/SupportTicketService";

const { Text } = Typography;

type TicketCardsProps = {
  selectedTicket: SupportTicket | null;
  setSelectedTicket: (ticket: SupportTicket | null) => void;
  item: SupportTicket;
};

const TicketCards: React.FC<TicketCardsProps> = ({ selectedTicket, setSelectedTicket, item }) => {
  const isSelected = selectedTicket?._id === item._id;

  const renderStyles = () => css`
    background-color: ${isSelected ? "#e0f7fa" : "#f5f5f5"};
    border-radius: 12px;
    box-shadow: ${isSelected ? "0 8px 24px rgba(0, 0, 0, 0.2)" : "0 4px 12px rgba(0, 0, 0, 0.15)"};
    border: 1px solid ${isSelected ? "#b2ebf2" : "#d9d9d9"};
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    &:hover {
      background-color: #e6e6e6;
      transform: scale(1.03);
    }
    margin-bottom: 18px;
  `;

  return (
    <Card
      onClick={() => setSelectedTicket(item)}
      bordered={false}
      css={renderStyles()}
      bodyStyle={{ padding: "20px" }}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 16px;
        `}
      >
        <Avatar
          src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
          size="large"
          icon={<UserOutlined />}
          style={{ backgroundColor: "#1890ff", marginRight: "16px" }}
        />
        <div>
          <Text
            style={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#1d1d1d",
              marginBottom: "4px",
            }}
          >
            {item?.ticketIssuerType}
          </Text>
          <Text
            type="secondary"
            style={{ display: "block", fontSize: "14px", color: "#4d4d4d", marginBottom: "8px" }}
          >
            {item.ticketIssuer}
          </Text>
          <Text
            type="secondary"
            style={{ fontSize: "14px", color: "#4d4d4d", display: "flex", alignItems: "center" }}
          >
            <CodeOutlined style={{ marginRight: "8px", color: "#ff6f61" }} />
            Ticket Code: {item.ticketCode}
          </Text>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
        `}
      >
        <Space style={{ marginBottom: "8px" }}>
          <Tag
            icon={<TagOutlined />}
            color="geekblue"
            style={{
              fontSize: "14px",
              fontWeight: 500,
              borderRadius: "8px",
              background: "#00c6ff", // Solid color without gradient
              color: "#fff",
              padding: "6px 12px",
              border: "none",
            }}
          >
            {item.ticketType?.ticketType}
          </Tag>
        </Space>
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "14px",
            color: "#4d4d4d",
          }}
        >
          <Text style={{ display: "flex", alignItems: "center" }}>
            <ClockCircleOutlined style={{ marginRight: "8px", color: "#40a9ff" }} />
            {item?.createdAt && new Date(item?.createdAt)?.toDateString()}
          </Text>
          <Tag
            color="default"
            style={{
              fontSize: "14px",
              fontWeight: 600,
              borderRadius: "12px",
              padding: "6px 12px",
              color: "#fff",
              background: "#ff6f61",
              border: "none",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ExclamationCircleOutlined style={{ marginRight: "4px" }} />
            Pending
          </Tag>
        </Space>
      </div>
    </Card>
  );
};

export default TicketCards;
