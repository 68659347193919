import { useEffect, useState, useCallback } from "react";
import { SOCKET_EVENTS } from "enums/socketEvents";
import { SupportTicketMessage } from "services/SupportTicketService";

interface FetchMessagesResponse {
  data: SupportTicketMessage[];
}

export const useSocketMessages = (socket: any, ticketId: string | undefined) => {
  const [messages, setMessages] = useState<SupportTicketMessage[]>([]);

  const handleNewMessage = useCallback((newMessage: SupportTicketMessage) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  }, []);

  useEffect(() => {
    if (!socket || !ticketId) return;

    socket.on(SOCKET_EVENTS.TICKET_NEW_MESSAGE, handleNewMessage);

    const fetchMessages = () => {
      const payload = {
        query: { page: 1, pageSize: 10, sort: "1createdAt" },
        params: { ticketId },
      };

      socket.emit(
        SOCKET_EVENTS.FETCH_OLD_TICKET_MESSAGES_AFTER_JOIN,
        payload,
        (data: FetchMessagesResponse) => {
          setMessages(data.data);
        }
      );
    };

    fetchMessages();

    return () => {
      socket.off(SOCKET_EVENTS.TICKET_NEW_MESSAGE, handleNewMessage);
    };
  }, [socket, ticketId, handleNewMessage]);

  return messages;
};
