import { ColumnsType } from "antd/es/table";
import { TicketType } from "services/TicketTypeService/types";

export const ticketTypeColumns: ColumnsType<TicketType> = [
  {
    title: "Ticket Title",
    dataIndex: "ticketType",
    key: "ticketType",
  },
];
