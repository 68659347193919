import React, { useState } from "react";
import {
  Table,
  Button,
  Input,
  Typography,
  Space,
  Card,
  Modal,
  Form,
  DatePicker,
  Select,
} from "antd";
import { PlusOutlined, MoreOutlined, FilterOutlined } from "@ant-design/icons";

const { Option } = Select;

const RecallSettingTab: React.FC = () => {
  const [dataSource, setDataSource] = useState([
    {
      key: "1",
      sn: 1,
      test: "Blood Test",
      repeat: "Every 6 months",
      recallJourney: "Stage 1",
      nextRecallDate: "2024-10-15",
      timeRemaining: "45 days",
    },
    {
      key: "2",
      sn: 2,
      test: "X-Ray",
      repeat: "Every year",
      recallJourney: "Stage 2",
      nextRecallDate: "2024-12-20",
      timeRemaining: "90 days",
    },
  ]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = (values: any) => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "S.N",
      dataIndex: "sn",
      key: "sn",
    },
    {
      title: "Test",
      dataIndex: "test",
      key: "test",
    },
    {
      title: "Repeat",
      dataIndex: "repeat",
      key: "repeat",
    },
    {
      title: "Recall Journey",
      dataIndex: "recallJourney",
      key: "recallJourney",
    },
    {
      title: "Next Recall Date",
      dataIndex: "nextRecallDate",
      key: "nextRecallDate",
    },
    {
      title: "Time Remaining",
      dataIndex: "timeRemaining",
      key: "timeRemaining",
    },
    {
      title: "Action",
      key: "action",
      render: () => <Button type="text" icon={<MoreOutlined />} />,
    },
  ];

  return (
    <>
      <Card
        style={{
          padding: "40px 20px",
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <div style={{ width: "100%" }}>
          <Space
            style={{
              marginBottom: 16,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Space>
              <Button icon={<FilterOutlined />} type="primary" />

              <Input.Search placeholder="Search settings" style={{ width: 200 }} />
            </Space>

            <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
              Add Recall
            </Button>
          </Space>

          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            bordered={false}
            style={{ marginTop: 16 }}
          />
        </div>
      </Card>

      <Modal title="Add Recall" visible={isModalVisible} onCancel={handleCancel} footer={null}>
        <Form layout="vertical" onFinish={handleOk}>
          <Form.Item
            name="recallDate"
            label="Recall Date"
            rules={[{ required: true, message: "Please select a recall date!" }]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="repeat"
            label="Repeat"
            rules={[{ required: true, message: "Please select a repeat interval!" }]}
          >
            <Select placeholder="Select repeat interval">
              <Option value="Every 3 months">Every 3 months</Option>
              <Option value="Every 6 months">Every 6 months</Option>
              <Option value="Every year">Every year</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Space style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RecallSettingTab;
