import { CommonSchemaInterface, ObjectID } from "types/common.interface";
import { PaginationInterface } from "types/pagination.interface";

export enum PackageTimeType {
  ANNUALLY = "annually",
  MONTHLY = "monthly",
}

export enum TaxTypeEnum {
  INCLUSIVE = "inc",
  EXCLUSIVE = "exc",
  EXEMPTED = "exe",
}

export enum PaymentModeEnum {
  STRIPE = "STRIPE",
  FREE = "FREE",
}

export enum PackagePurchaseType {
  SUBSCRIPTION = "subscription",
  PROPERTY = "property",
}

export enum SubscriptionDaysEnum {
  DAYS = "days",
  WEEKS = "weeks",
  MONTHS = "months",
  YEARS = "years",
}

export interface PlanInterface {
  planName: string;
  allowed: boolean;
}

export interface TimePackageCostInterface {
  enable: boolean;
  cost: number;
  taxRate: number;
  discountPercent: number;
  taxType: TaxTypeEnum;
  flatPropertyCost: number;
}

export interface SubscriptionPackageInterface extends CommonSchemaInterface {
  _id?: ObjectID;
  purchaseDisable: boolean;
  name: string;
  description: string;
  allowUserType: string[];
  allowBooking: boolean;
  trial: {
    enable: boolean;
    days: number;
    text: string;
  };
  annualCost: TimePackageCostInterface;
  monthlyCost: TimePackageCostInterface;
  storage: {
    base: number;
  };
  plans: PlanInterface[];
  subtitle: string;
  isDeleted?: boolean;
  isSeed?: boolean;
  isFreeForever: boolean;
  canDelete: boolean;
}

export interface SubscriptionPackageResponseInterface extends PaginationInterface {
  data: SubscriptionPackageInterface[];
}
