import React from "react";
import { Layout } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import AppMenu from "./Menu";
import { useQueryClient } from "@tanstack/react-query";
import { StyledContent } from "../styles";
import { getParentRoute } from "utils/url-formatter";

const MainLayout: React.FC = () => {
  const navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = React.useState<string>("");
  const location = useLocation();

  const handleSelect = (e: any) => {
    navigate(e.key);
  };

  React.useEffect(() => {
    const pathname = location.pathname;
    const parentRoute = getParentRoute(pathname);
    setSelectedKeys(parentRoute ?? "");
  }, [location]);

  return (
    <Layout>
      <Header />
      <AppMenu onSelect={handleSelect} selectedKeys={selectedKeys} />
      <StyledContent>
        <Outlet />
      </StyledContent>
    </Layout>
  );
};

export default MainLayout;
