import { UserInLocalStorage } from "types/user.type";

const withErrorHandling = <T>(fn: () => T): T => {
  try {
    return fn();
  } catch (error) {
    console.error("LocalStorage operation failed:", error);
    return undefined as unknown as T;
  }
};

export const storage = {
  getUser(): UserInLocalStorage | null {
    return withErrorHandling(() => {
      const user = localStorage.getItem("user");
      return user ? JSON.parse(user) : null;
    });
  },

  setUser(user: UserInLocalStorage): void {
    withErrorHandling(() => {
      localStorage.setItem("user", JSON.stringify(user));
    });
  },

  getToken(): string | null {
    return withErrorHandling(() => localStorage.getItem("token"));
  },

  setToken(token: string): void {
    withErrorHandling(() => {
      localStorage.setItem("token", token);
    });
  },
};
