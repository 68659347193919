/** @jsxImportSource @emotion/react */
import { Button, Card, Col, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import { cardStyle } from "../styles";
import RxTableSection from "./RxTableSection";
import { DataType, initialLensData, trialsData } from "../data";
import MatrixTableCard from "../cards/MatrixTableCard";
import { trialsColumns } from "../handlers/tableColumns";
import { FaPlus, FaPrescription } from "react-icons/fa";

const InitialContactLens = () => {
  const [data, setData] = useState<DataType[]>(initialLensData);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <React.Fragment>
      <Row gutter={[24, 24]} style={{ marginTop: "24px", display: "flex", flexWrap: "wrap" }}>
        <Col span={24}>
          <Card
            css={cardStyle}
            title="Initial Prescription"
            extra={[<Button icon={<FaPrescription />}>Previous Prescription</Button>]}
          >
            <RxTableSection data={data} setData={setData} showActions={false} />
          </Card>
        </Col>

        <Col span={24}>
          <MatrixTableCard
            dataSource={trialsData}
            columns={trialsColumns}
            title="Trials"
            extra={[
              <Button key="add-notes" icon={<FaPlus />} onClick={showModal}>
                Add Notes
              </Button>,
            ]}
          />
        </Col>
      </Row>

      <Modal
        title="Add Note"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Cancel"
      >
        <Input.TextArea
          rows={4}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Enter your note here"
        />
      </Modal>
    </React.Fragment>
  );
};

export default InitialContactLens;
