import React from "react";
import styled from "@emotion/styled";
import { Card, Space, Typography } from "antd";
import {
  UserOutlined,
  ClockCircleOutlined,
  FileDoneOutlined,
  AimOutlined,
} from "@ant-design/icons";
import { format } from "date-fns";
import { Event } from "types/event.type";

const AppointmentCard = styled(Card)`
  border-radius: 8px;
  background-color: #fce5c7;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  padding: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #f0f0f0;
    transform: translateY(-2px);
  }
`;

const iconStyle: React.CSSProperties = {
  fontSize: "16px",
  color: "#333",
};

const textStyle: React.CSSProperties = {
  color: "#333",
  fontSize: "12px",
  fontWeight: 500,
  marginLeft: "8px",
};

const AppointmentDetail: React.FC<{ icon: React.ReactNode; text: string }> = ({ icon, text }) => (
  <Space style={{ marginBottom: "4px" }}>
    {icon}
    <Typography.Text style={textStyle}>{text}</Typography.Text>
  </Space>
);

const EventBlock: React.FC<{
  event: Event;
  index: number;
  hour: String;
}> = ({ event, index }) => {
  const eventStartTime = new Date(event.startTime);
  const formattedTime = format(eventStartTime, "h:mm a");

  return (
    <AppointmentCard key={index}>
      <Space direction="vertical" size="small">
        <AppointmentDetail
          icon={<UserOutlined style={{ color: "#ff5722", ...iconStyle }} />}
          text="John Adekaye"
        />
        <AppointmentDetail
          icon={<FileDoneOutlined style={{ color: "#4caf50", ...iconStyle }} />}
          text={event.title}
        />
        <AppointmentDetail
          icon={<ClockCircleOutlined style={{ color: "#2196f3", ...iconStyle }} />}
          text={formattedTime}
        />
        <AppointmentDetail
          icon={<AimOutlined style={{ color: "#9c27b0", ...iconStyle }} />}
          text="Eye Clinic"
        />
      </Space>
    </AppointmentCard>
  );
};

export default EventBlock;
