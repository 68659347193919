import React from "react";
import styled from "@emotion/styled";
import {
  Alert,
  Button,
  Drawer,
  Row,
  Space,
  Typography,
  Tag,
  Col,
  Menu,
  Dropdown,
  Radio,
  Input,
} from "antd";
import {
  CopyOutlined,
  SnippetsOutlined,
  EditOutlined,
  DeleteOutlined,
  ClockCircleOutlined,
  MoreOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { useAppointmentContext } from "../context";

const StyledDrawer = styled(Drawer)`
  &.ant-drawer,
  .ant-drawer-header {
    background-color: #1499b8;
  }
  &.ant-drawer,
  .ant-drawer-title {
    color: white;
  }

  &.ant-drawer,
  .ant-drawer-close {
    color: white;
  }

  &.ant-drawer,
  .ant-drawer-body {
  }
`;

const InfoRow = styled(Row)`
  padding: 12px 0px;
`;

const InfoColumn = styled(Col)`
  flex: 1;
  padding: 0 12px;
  text-align: left;
`;

const InfoTitle = styled(Typography.Text)`
  font-size: 14px;
  color: #6b7280;
  display: block;
`;

const InfoValue = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 500;
  display: block;
`;

const MessageContainer = styled.div`
  background-color: #f3f4f6;
  padding: 24px;
`;

const MessageTemplate = styled.div`
  background-color: #18b6da;
  padding: 12px;
  color: white;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const Timestamp = styled(Typography.Text)`
  display: block;
  font-size: 12px;
  color: white;
`;

const AppointmentDetails: React.FC = () => {
  const { shouldViewDetails, setShouldViewDetails, setOpen, setIsEditMode } =
    useAppointmentContext();

  const handleEditAppointment = () => {
    setShouldViewDetails(false);
    setIsEditMode(true);
    setOpen(true);
  };

  const menu = (
    <Menu>
      <Menu.Item key="edit" icon={<EditOutlined />} onClick={handleEditAppointment}>
        Edit Appointment
      </Menu.Item>
      <Menu.Item key="delete" icon={<DeleteOutlined />}>
        Delete Appointment
      </Menu.Item>
      <Menu.Item key="copy" icon={<CopyOutlined />}>
        Copy Details
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <StyledDrawer
        closable
        destroyOnClose
        title={
          <Row justify={"space-between"} align={"middle"}>
            <Typography.Text strong style={{ color: "white" }}>
              John Adekaye Appointment for Eye Test
            </Typography.Text>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button ghost icon={<MoreOutlined />} />
            </Dropdown>
          </Row>
        }
        placement="right"
        open={shouldViewDetails}
        onClose={() => setShouldViewDetails(false)}
        width={500}
      >
        <InfoRow>
          <InfoColumn>
            <InfoTitle>Appointment Date</InfoTitle>
            <InfoValue>12th Sep 2024</InfoValue>
          </InfoColumn>
          <InfoColumn>
            <InfoTitle>Appointment Type</InfoTitle>
            <InfoValue>Eye Test</InfoValue>
          </InfoColumn>
        </InfoRow>

        <InfoRow>
          <InfoColumn>
            <InfoTitle>Patient Mobile</InfoTitle>
            <InfoValue>+123 456 7890</InfoValue>
          </InfoColumn>
          <InfoColumn>
            <InfoTitle>Patient Email</InfoTitle>
            <InfoValue>johndoe@example.com</InfoValue>
          </InfoColumn>
        </InfoRow>

        <InfoRow>
          <InfoColumn>
            <InfoTitle>Appointment With</InfoTitle>
            <InfoValue>Dr. Smith</InfoValue>
          </InfoColumn>
          <InfoColumn>
            <InfoTitle>Status Message</InfoTitle>
            <InfoValue>Booked</InfoValue>
          </InfoColumn>
        </InfoRow>

        <InfoRow>
          <InfoColumn span={24}>
            <InfoTitle>Messages to the patient for appointment booking</InfoTitle>
            <InfoValue>
              <Tag color={true ? "green" : "red"}>{true ? "Enabled" : "Disabled"}</Tag>
            </InfoValue>
          </InfoColumn>
        </InfoRow>

        <InfoRow>
          <InfoColumn span={24}>
            <InfoTitle>Booking Notes</InfoTitle>
            <InfoValue>Please arrive 10 minutes early for your appointment.</InfoValue>
          </InfoColumn>
        </InfoRow>

        <InfoRow>
          <InfoColumn span={24}>
            <InfoTitle>Correspondence</InfoTitle>
            <InfoValue>
              <Radio.Group
                defaultValue="SMS"
                optionType="button"
                buttonStyle="solid"
                style={{ marginTop: "8px" }}
              >
                <Radio value="SMS">Send SMS</Radio>
                <Radio value="Email">Send Email</Radio>
              </Radio.Group>
            </InfoValue>
          </InfoColumn>
        </InfoRow>

        <MessageContainer>
          <MessageTemplate>
            <Timestamp>12th Sep 2024, 10:00 AM</Timestamp>
            Your appointment is confirmed. Please arrive 10 minutes early.
          </MessageTemplate>
          <MessageTemplate>
            <Timestamp>12th Sep 2024, 10:05 AM</Timestamp>
            Reminder: Your appointment is scheduled for today at 3:00 PM.
          </MessageTemplate>

          {/* <InfoRow>
            <InfoColumn span={24}>
              <Input
                placeholder="Type your message..."
                suffix={<SendOutlined style={{ color: "#1499b8", cursor: "pointer" }} />}
              />
            </InfoColumn>
          </InfoRow> */}
        </MessageContainer>
      </StyledDrawer>
    </>
  );
};

export default AppointmentDetails;
