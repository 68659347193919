import React from "react";
import withFormHandler from "hoc/withFormHandler";
import DynamicFormBuilder from "components/DynamicFormBuilder";
import { usePatientRecordService } from "services/PatientRecordService";
import PatientFormConfig from "./PatientRecordConfig";
import { useParams } from "react-router-dom";
import Title from "antd/es/typography/Title";

const PatientRecordConfigurationForm = () => {
  const { useCreate, useUpdate, useGetById } = usePatientRecordService();
  const PatientRecordFormHandler = withFormHandler(DynamicFormBuilder, {
    useCreate: useCreate,
    useUpdate: useUpdate,
    useFetchById: (id: string) => useGetById(id),
    onSuccess: () => {},
    onError: (error) => {},
    fieldsConfig: PatientFormConfig,
    wrapInCard: false,
    redirectUrl: "patient-records",
  });
  return <PatientRecordFormHandler />;
};

const PatientRecordForm = () => {
  const { id } = useParams<{ id?: string }>();
  return (
    <React.Fragment>
      <Title level={3}>Add/Update Patient</Title>
      <PatientRecordConfigurationForm />
    </React.Fragment>
  );
};

export default PatientRecordForm;
