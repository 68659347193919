import React from "react";
import { Card, Typography, Divider, Space, Button, Popconfirm, Tag } from "antd";
import { EditOutlined, DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import Background from "assets/background.jpg";

const { Title, Text } = Typography;

const StyledCard = styled(Card)`
  border-radius: 16px;
  box-shadow: 6px 6px 54px 0px #0000000d;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 24px 30px;
  margin-bottom: 24px;
  height: 750px; // Ensure consistent card height
  width: 350px; // Ensure consistent card width
`;

interface SubscriptionCardProps {
  title: string;
  monthlyCharge: string;
  annualCharge: string;
  features: string[];
  trialInfo: { enable: boolean; days: number; text: string } | undefined;
  storage: { base: number } | undefined;
  canDelete: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  title,
  monthlyCharge,
  annualCharge,
  features = [],
  trialInfo,
  storage,
  onEdit,
  onDelete,
}) => {
  return (
    <StyledCard>
      <div style={{ textAlign: "center", height: "100%" }}>
        <Title level={4}>{title}</Title>
        <Text type="secondary">Monthly Charge</Text>
        <Title style={{ color: "#4880FF", marginBottom: 16 }}>${monthlyCharge}</Title>
        <Text type="secondary">Annual Charge</Text>
        <Title style={{ color: "#FF6F61", marginBottom: 16 }}>${annualCharge}</Title>
        <Divider />
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <div>
            <Text strong>Features:</Text>
            {features.length ? (
              features.map((feature, idx) => (
                <Text key={idx} style={{ display: "block" }}>
                  <InfoCircleOutlined style={{ marginRight: 8, color: "#1890FF" }} />
                  {feature}
                </Text>
              ))
            ) : (
              <Text type="secondary">No features available</Text>
            )}
          </div>
          {trialInfo && (
            <div>
              <Text strong>Trial Information:</Text>
              <Text>
                {trialInfo.enable
                  ? `Enabled for ${trialInfo.days} days: ${trialInfo.text}`
                  : "No trial available"}
              </Text>
            </div>
          )}
          {storage && (
            <div>
              <Text strong>Storage:</Text>
              <Text>{storage.base} GB</Text>
            </div>
          )}
          <Divider />
          <Button type="primary" ghost style={{ width: "100%", borderRadius: 28 }} onClick={onEdit}>
            <EditOutlined /> Edit
          </Button>
          {true && (
            <Popconfirm
              title="Are you sure you want to delete this subscription plan?"
              onConfirm={onDelete}
              okText="Yes"
              cancelText="No"
              placement="topRight"
            >
              <Button type="link" danger style={{ width: "100%", marginTop: 8 }}>
                <DeleteOutlined /> Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      </div>
    </StyledCard>
  );
};

export default SubscriptionCard;
