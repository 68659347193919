import React from "react";
import { Button, Row, Space } from "antd";
import { FunnelPlotOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";

interface PatientActionsProps {
  onSearch: () => void;
  onAddNew: () => void;
}

const PatientActions: React.FC<PatientActionsProps> = ({ onSearch, onAddNew }) => (
  <Row justify="space-between" style={{ marginBottom: "34px" }}>
    <Space>
      <Button icon={<FunnelPlotOutlined />} onClick={onSearch} />
      <Search placeholder="input search text" enterButton="Search" suffix={<SearchOutlined />} />
    </Space>
    <Button type="primary" icon={<PlusOutlined />} onClick={onAddNew}>
      New Patient
    </Button>
  </Row>
);

export default PatientActions;
