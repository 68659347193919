import { Dayjs } from "dayjs";
import { ObjectID } from "types/common.interface";
import { PaginationInterface } from "types/pagination.interface";

// Enum for Gender
export enum Gender {
  Male = "male",
  Female = "female",
  Other = "other",
}

// Enum for Appointment Type
export enum AppointmentType {
  WalkIn = "walk",
  Appointment = "appointment",
}

// Enum for Room
export enum Room {
  Room1 = "room1",
  Room2 = "room2",
}

// Enum for Status
export enum Status {
  Scheduled = "scheduled",
  Completed = "completed",
  Cancelled = "cancelled",
}

// Enum for Practitioners
export enum Practitioner {
  DrSmith = "dr_smith",
  DrJones = "dr_jones",
}

// Interface for Appointment
export interface AppointmentInterface {
  _id?: ObjectID;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  dateOfBirth: Dayjs; // ISO string format or Date object
  email: string;
  gender: Gender;
  appointmentType: AppointmentType;
  dateAndTime: Dayjs; // ISO string format or Date object
  room: Room;
  status: Status;
  scheduledPractitioner: Practitioner;
  bookingNotes: string;
  enableMessage: boolean;
  addBookingPerson: string; // assuming this might be an ID or name
}

export interface AppointmentResponseInterface extends PaginationInterface {
  data: AppointmentInterface[];
}
