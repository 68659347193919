/** @jsxImportSource @emotion/react */
import React from "react";
import { Card, Spin } from "antd";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

const StyledCard = styled(Card)`
  width: 100%;
  height: calc(100vh - 206px); // Adjust the height based on your requirement
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border-radius: 10px; // Rounded corners for a more modern feel
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
`;

const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px; // Match the border radius of the card
`;

const LoadingCard: React.FC = () => {
  return (
    <StyledCard bordered={false}>
      <SpinWrapper>
        <Spin tip="Loading..." />
      </SpinWrapper>
    </StyledCard>
  );
};

export default LoadingCard;
