/** @jsxImportSource @emotion/react */
import React from "react";
import { Col, Form } from "antd";
import RegisterForm from "./RegisterForm";
import LoginImage from "assets/signin.svg";
import { ROUTES } from "constants/routeConstants";
import {
  RegisterPageWrapper,
  RegisterCard,
  RegisterFormContainer,
  Title,
  DescriptionText,
} from "../styles";

const RegisterPage: React.FC = () => {
  const [form] = Form.useForm();

  const handleFinish = (values: any) => {
    console.log("Registration Values:", values);
  };

  const handleLoginRedirect = () => {
    window.location.href = ROUTES.AUTH.LOGIN;
  };

  return (
    <RegisterPageWrapper justify="space-between">
      <RegisterCard span={12}>
        <RegisterFormContainer>
          <Title level={1}>Sign Up</Title>
          <DescriptionText type="secondary">
            Enter your information to create an account in our system.
          </DescriptionText>
          <RegisterForm form={form} onFinish={handleFinish} onLoginRedirect={handleLoginRedirect} />
        </RegisterFormContainer>
      </RegisterCard>
      <Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <img src={LoginImage} alt="Login" />
      </Col>
    </RegisterPageWrapper>
  );
};

export default RegisterPage;
