import React from "react";
import { Row, Col, Space, Statistic } from "antd";
import { StyledStatisticCard, StyledSmallCard } from "../common/CardWrapper";
import { StatisticTitle } from "../common/Typography";
import styled from "@emotion/styled";
import { ReactComponent as UpPathIcon } from "assets/uppath.svg";

const IconContainer = styled.div<{ backgroundColor: string }>`
  height: 60px;
  width: 60px;
  border-radius: 24px;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

interface StatisticWithIconCardProps {
  title: string;
  value: number;
  icon: React.ReactNode;
  backgroundColor: string;
  trend?: string;
  isSmall?: boolean;
}

const StatisticWithIconCard: React.FC<StatisticWithIconCardProps> = ({
  title,
  value,
  icon,
  backgroundColor,
  trend,
  isSmall,
}) => {
  const CardComponent = isSmall ? StyledSmallCard : StyledStatisticCard;

  return (
    <Col span={8}>
      <CardComponent
        style={{
          boxShadow: "6px 6px 54px 0px #0000000d",
          borderRadius: "12px",
          padding: "12px",
          height: isSmall ? "100%" : "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row justify="space-between" align="middle" style={{ flex: 1 }}>
          <div>
            <StatisticTitle>{title}</StatisticTitle>
            <Statistic value={value} valueStyle={{ fontSize: "24px", fontWeight: 500 }} />
          </div>
          <IconContainer backgroundColor={backgroundColor}>{icon}</IconContainer>
        </Row>
        {trend && (
          <Space style={{ marginTop: 12 }}>
            <UpPathIcon />
            <span style={{ color: "#00b69b" }}>{trend}</span>
          </Space>
        )}
      </CardComponent>
    </Col>
  );
};

export default StatisticWithIconCard;
