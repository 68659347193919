export const themeConfig = {
  token: {
    fontFamily: `"Manrope", sans-serif`,
    colorPrimary: "#1499B8",
    borderRadius: 4,
  },
  components: {
    Button: {
      algorithm: true,
      borderRadius: 4,
    },
    Input: {
      algorithm: true,
      borderRadius: 4,
    },
    Typography: {
      colorLink: "#1499B8",
      algorithm: true,
      fontFamily: `"Manrope", sans-serif`,
    },
  },
};
