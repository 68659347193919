import { useEffect, useReducer } from "react";
import io, { Socket } from "socket.io-client";
import apiConfig from "config/api.config";

type SocketState = {
  socket: Socket | null;
  connected: boolean;
  error: string | null;
};

type SocketAction =
  | { type: "CONNECT"; payload: Socket }
  | { type: "DISCONNECT" }
  | { type: "ERROR"; payload: string };

const initialState: SocketState = {
  socket: null,
  connected: false,
  error: null,
};

const socketReducer = (state: SocketState, action: SocketAction): SocketState => {
  switch (action.type) {
    case "CONNECT":
      return { ...state, socket: action.payload, connected: true, error: null };
    case "DISCONNECT":
      return { ...state, socket: null, connected: false };
    case "ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

const SOCKET_SERVER = process.env.REACT_APP_API_ENDPOINT;

export const useSocket = (ticketId: string | undefined) => {
  const [state, dispatch] = useReducer(socketReducer, initialState);

  useEffect(() => {
    if (!ticketId) return;

    const newSocket = io(`${SOCKET_SERVER}${apiConfig.services.socket.basePath}`, {
      query: { ticketId },
    });

    newSocket.on("connect", () => {
      dispatch({ type: "CONNECT", payload: newSocket });
    });

    newSocket.on("connect_error", (err) => {
      dispatch({ type: "ERROR", payload: err.message });
    });

    return () => {
      newSocket.disconnect();
      dispatch({ type: "DISCONNECT" });
    };
  }, [ticketId]);

  return state;
};
