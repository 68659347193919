import styled from "@emotion/styled";
import { Select, Button, Card, Input } from "antd";

const { TextArea } = Input;

export const TicketContainer = styled.div`
  overflow: auto;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 12px;
  height: calc(100vh - 338px);
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-select-selection-item {
    display: flex;
    justify-content: flex-start;
  }
  .ant-space {
    padding-left: 15px;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 18px;
`;

export const CardHeader = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  // border: 0.5px solid #f5f5f5;
  align-items: center;
  padding: 15px 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const StyledButton = styled(Button)`
  border-radius: 33px;
  height: 35px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const ActionWrapper = styled.div`
  height: 30px;
  width: 30px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #9ca1a7;
`;

export const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 0px;
  }
  background-color: #f6f6f7;
`;

export const CardBody = styled.div``;

export const SendButton = styled(Button)`
  color: white;
  border: none;
  &.ant-btn > span {
    font-size: 13px !important;
  }
  height: 38px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
`;

export const MessageInput = styled(Input)`
  &.ant-input:placeholder-shown {
  }
  &.ant-input {
    padding: 9px 12px;
    border: none;
    background-color: #f6f6f7;
  }
`;

export const StyledTextArea = styled(TextArea)`
  border-radius: 18px;
`;

export const MessageContainer = styled.div`
  height: calc(100vh - 340px);
  overflow: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #007bff #e0f7fa; /* For Firefox */

  &::-webkit-scrollbar {
    width: 8px; /* For Chrome, Safari */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #e0f7fa;
    border-radius: 4px;
  }
`;
