import React, { useState } from "react";
import { Form, Select, Typography, Radio, Row, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { StyledTextArea } from "../../styles";
import type { RadioChangeEvent } from "antd";
import { storage } from "utils/storage";
import { useEmailService } from "services/EmailService/useEmailService";
import { useSupportTicketService } from "services/SupportTicketService";
import { useTicketTypeService } from "services/TicketTypeService";

const { Option } = Select;

interface AddIssueTicketProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}

const AddIssueTicket: React.FC<AddIssueTicketProps> = ({ isModalOpen, setIsModalOpen }) => {
  const [form] = useForm();
  const [checked, setChecked] = useState<number>(1);
  const { useGetAll } = useTicketTypeService();
  const { useCreate } = useSupportTicketService();
  const { useGetAll: useGetUserEmails } = useEmailService();

  const createIssueTicketMutation = useCreate();
  const { data: ticketTypes } = useGetAll();
  const { data: emails } = useGetUserEmails();

  const user = storage.getUser();

  const handleCancel = () => setIsModalOpen(false);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const ticketIssuer = checked === 2 ? JSON.parse(values.ticketIssuer).email : user?.email;
      const ticketIssuerType = checked === 2 ? JSON.parse(values.ticketIssuer).userType : "admin";

      const payload = {
        ...values,
        ticketIssuerRole: "admin",
        ticketIssuer,
        ticketIssuerType,
      };

      createIssueTicketMutation.mutate(payload, {
        onSuccess: () => {
          form.resetFields();
          setIsModalOpen(false);
        },
      });
    } catch (error) {}
  };

  return (
    <Modal
      title="Add Issue Ticket"
      open={isModalOpen}
      onCancel={handleCancel}
      width={420}
      onOk={handleSubmit}
      confirmLoading={createIssueTicketMutation.isPending}
    >
      <div>
        <Form layout="vertical" form={form}>
          <Row style={{ marginBottom: "28px" }}>
            <Radio.Group
              onChange={(e: RadioChangeEvent) => setChecked(e.target.value)}
              value={checked}
            >
              <Radio value={1}>Create ticket as logged-in user</Radio>
              <Radio value={2}>Create ticket for other users</Radio>
            </Radio.Group>
          </Row>

          {checked === 2 && (
            <Form.Item
              name="ticketIssuer"
              label="Ticket Issuer"
              rules={[{ required: true, message: "Ticket issuer is required" }]}
            >
              <Select placeholder="Select ticket issuer">
                {emails?.map((email) => (
                  <Option key={email.email} value={JSON.stringify(email)}>
                    {email.email}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="ticketType"
            label="Ticket Type"
            rules={[{ required: true, message: "Ticket type is required" }]}
          >
            <Select placeholder="Select ticket type">
              {ticketTypes?.data?.map((type) => (
                <Option key={type._id} value={type._id}>
                  {type.ticketType}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="ticketDetails"
            label="Ticket Details"
            rules={[{ required: true, message: "Ticket details are required" }]}
          >
            <StyledTextArea rows={5} placeholder="Describe the issue" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddIssueTicket;
