import { FieldConfig, SelectFieldConfig } from "types/form.config.type";
import { Input, InputNumber, Select, Switch } from "antd";

const { TextArea } = Input;
const { Option } = Select;

export const SubscriptionFormConfig: (FieldConfig | SelectFieldConfig)[] = [
  {
    name: "name",
    label: "Package Name",
    component: Input,
    rules: [{ required: true, message: "Please input the package name!" }],
    colSpan: 12,
    componentType: "Input",
  },
  {
    name: "subtitle",
    label: "Subtitle",
    component: Input,
    rules: [{ required: true, message: "Please input the subtitle!" }],
    colSpan: 12,
    componentType: "Input",
  },
  {
    name: "description",
    label: "Description",
    component: TextArea,
    props: { rows: 4 },
    colSpan: 24,
  },
  {
    name: "allowBooking",
    label: "Allow Booking",
    component: Switch,
    valuePropName: "checked",
    componentType: "Switch",
  },
  {
    name: "allowUserType",
    label: "Allow User Types",
    component: Select,
    props: {
      mode: "multiple",
      placeholder: "Select user types",
    },
    options: [
      { value: "free", label: "Free" },
      { value: "paid", label: "Paid" },
      { value: "trial", label: "Trial" },
    ],
    rules: [{ required: true, message: "Please select user types!" }],
    componentType: "Select",
  },
  {
    name: ["trial", "enable"],
    label: "Trial Period",
    component: Switch,
    valuePropName: "checked",
    componentType: "Switch",
  },
  {
    name: ["trial", "days"],
    label: "Trial Days",
    component: InputNumber,
    props: { min: 0, style: { width: "100%" } },
    rules: [{ required: true, message: "Please input the number of trial days!" }],
    colSpan: 12,
    componentType: "InputNumber",
  },
  {
    name: ["trial", "text"],
    label: "Trial Text",
    component: Input,
    colSpan: 12,
    componentType: "Input",
  },
  {
    name: ["annualCost", "enable"],
    label: "Annual Cost",
    component: Switch,
    valuePropName: "checked",
    props: { checkedChildren: "Enabled", unCheckedChildren: "Disabled" },
    componentType: "Switch",
    children: [
      {
        name: ["annualCost", "cost"],
        label: "Cost",
        component: InputNumber,
        props: { min: 0, style: { width: "100%" } },
        rules: [{ required: true, message: "Please input the annual cost!" }],
        colSpan: 8,
        componentType: "InputNumber",
      },
      {
        name: ["annualCost", "taxRate"],
        label: "Tax Rate (%)",
        component: InputNumber,
        props: { min: 0, style: { width: "100%" } },
        rules: [{ required: true, message: "Please input the tax rate!" }],
        colSpan: 8,
        componentType: "InputNumber",
      },
      {
        name: ["annualCost", "discountPercent"],
        label: "Discount (%)",
        component: InputNumber,
        props: { min: 0, style: { width: "100%" } },
        rules: [{ required: true, message: "Please input the discount percentage!" }],
        colSpan: 8,
        componentType: "InputNumber",
      },
      {
        name: ["annualCost", "taxType"],
        label: "Tax Type",
        component: Select,
        options: [
          { value: "inc", label: "Inclusive" },
          { value: "exc", label: "Exclusive" },
          { value: "exe", label: "Exempted" },
        ],
        rules: [{ required: true, message: "Please select the tax type!" }],
        componentType: "Select",
      } as SelectFieldConfig,
      {
        name: ["annualCost", "flatPropertyCost"],
        label: "Flat Property Cost",
        component: InputNumber,
        props: { min: 0, style: { width: "100%" } },
        componentType: "InputNumber",
      },
    ],
  },
  {
    name: ["monthlyCost", "enable"],
    label: "Monthly Cost",
    component: Switch,
    valuePropName: "checked",
    componentType: "Switch",
    children: [
      {
        name: ["monthlyCost", "cost"],
        label: "Cost",
        component: InputNumber,
        props: { min: 0, style: { width: "100%" } },
        rules: [{ required: true, message: "Please input the monthly cost!" }],
        colSpan: 8,
        componentType: "InputNumber",
      },
      {
        name: ["monthlyCost", "taxRate"],
        label: "Tax Rate (%)",
        component: InputNumber,
        props: { min: 0, style: { width: "100%" } },
        rules: [{ required: true, message: "Please input the tax rate!" }],
        colSpan: 8,
        componentType: "InputNumber",
      },
      {
        name: ["monthlyCost", "discountPercent"],
        label: "Discount (%)",
        component: InputNumber,
        props: { min: 0, style: { width: "100%" } },
        rules: [{ required: true, message: "Please input the discount percentage!" }],
        colSpan: 8,
        componentType: "InputNumber",
      },
      {
        name: ["monthlyCost", "taxType"],
        label: "Tax Type",
        component: Select,
        options: [
          { value: "inc", label: "Inclusive" },
          { value: "exc", label: "Exclusive" },
          { value: "exe", label: "Exempted" },
        ],
        rules: [{ required: true, message: "Please select the tax type!" }],
        componentType: "Select",
      } as SelectFieldConfig,
      {
        name: ["monthlyCost", "flatPropertyCost"],
        label: "Flat Property Cost",
        component: InputNumber,
        props: { min: 0, style: { width: "100%" } },
        componentType: "InputNumber",
      },
    ],
  },
  {
    name: ["storage", "base"],
    label: "Storage (Base)",
    component: InputNumber,
    props: { min: 0, style: { width: "100%" } },
    rules: [{ required: true, message: "Please input the base storage!" }],
    componentType: "InputNumber",
  },
  {
    name: "plans",
    label: "Plans",
    component: Select,
    props: { mode: "multiple", placeholder: "Select plans" },
    options: [
      { value: "Basic Plan", label: "Basic Plan" },
      { value: "Premium Plan", label: "Premium Plan" },
    ],
    rules: [{ required: true, message: "Please select at least one plan!" }],
    componentType: "Select",
  },
  {
    name: "purchaseDisable",
    label: "Purchase Disable",
    component: Switch,
    valuePropName: "checked",
    componentType: "Switch",
  },
  {
    name: "isFreeForever",
    label: "Free Forever",
    component: Switch,
    valuePropName: "checked",
    componentType: "Switch",
  },
  {
    name: "canDelete",
    label: "Can Delete",
    component: Switch,
    valuePropName: "checked",
    componentType: "Switch",
  },
];

export default SubscriptionFormConfig;
