import React from "react";
import { Breadcrumb } from "antd";
import styled from "@emotion/styled";
import { HomeOutlined } from "@ant-design/icons";

const BreadcrumbWrapper = styled.div`
  margin-bottom: 18px;
  font-size: 16px; // Adjust font size
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  .ant-breadcrumb-link {
    color: #1890ff; // Link color
    font-weight: 500; // Font weight for links
    transition: color 0.3s ease; // Smooth transition for hover effect

    &:hover {
      color: #40a9ff; // Hover color
      text-decoration: underline; // Underline on hover
    }
  }

  .ant-breadcrumb-separator {
    color: #d9d9d9; // Separator color
    font-size: 18px; // Adjust separator size
  }
`;

type BreadcrumbItem = {
  href: string;
  label: string;
  icon?: React.ReactNode;
};

type Props = {
  items: BreadcrumbItem[];
};

const Breadcrumbs = ({ items }: Props) => (
  <BreadcrumbWrapper>
    <StyledBreadcrumb>
      {items.map((item, index) => (
        <Breadcrumb.Item key={index} href={item.href}>
          {item.icon || null}
          <span>{item.label}</span>
        </Breadcrumb.Item>
      ))}
    </StyledBreadcrumb>
  </BreadcrumbWrapper>
);

export default Breadcrumbs;
