/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  FaBox,
  FaMoneyBillWave,
  FaStethoscope,
  FaClipboardList,
  FaCalendarAlt,
} from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import { MdHelpOutline } from "react-icons/md";
import { GrHostMaintenance } from "react-icons/gr";

const iconStyle = css`
  margin-right: 8px;
  font-size: 16px;
  color: #1499b8;
`;

const menuLabelStyle = css`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #263446;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  padding: 8px 16px;

  &:hover {
    background-color: #f0f2f5;
    color: #1499b8;
  }
`;

const dropdownIconStyle = css`
  font-size: 16px;
  color: #999;
  margin-left: auto;
`;

export const menuItems = [
  {
    label: (
      <span css={menuLabelStyle}>
        <FaBox css={iconStyle} /> Inventory <RiArrowDropDownLine css={dropdownIconStyle} />
      </span>
    ),
    key: "/inventory",
    children: [
      { label: "Inventory levels", key: "inventory:1" },
      { label: "Transfer Stock", key: "inventory:2" },
      { label: "Inventory Audit", key: "inventory:3" },
    ],
  },
  {
    label: (
      <span css={menuLabelStyle}>
        <FaMoneyBillWave css={iconStyle} /> Financial{" "}
        <RiArrowDropDownLine css={dropdownIconStyle} />
      </span>
    ),
    key: "/financial",
    children: [
      { label: "Sales History", key: "financial:1" },
      { label: "Purchase History", key: "financial:2" },
      { label: "Daily Statement PDF", key: "financial:3" },
    ],
  },
  {
    label: (
      <span css={menuLabelStyle}>
        <FaStethoscope css={iconStyle} /> Consultation
      </span>
    ),
    key: "/consultation",
  },
  {
    label: (
      <span css={menuLabelStyle}>
        <FaCalendarAlt css={iconStyle} /> Appointment
      </span>
    ),
    key: "/appointment",
  },
  {
    label: (
      <span css={menuLabelStyle}>
        <FaClipboardList css={iconStyle} /> Subscription
      </span>
    ),
    key: "/subscription",
  },
  {
    label: (
      <span css={menuLabelStyle}>
        <GrHostMaintenance css={iconStyle} /> Maintenance{" "}
        <RiArrowDropDownLine css={dropdownIconStyle} />
      </span>
    ),
    key: "/maintenance",
    children: [
      { label: "Equipment Maintenance", key: "maintenance:1" },
      { label: "Facility Maintenance", key: "maintenance:2" },
    ],
  },
  {
    label: (
      <span css={menuLabelStyle}>
        <MdHelpOutline css={iconStyle} /> Help and Support
      </span>
    ),
    key: "/help-support",
    children: [
      { label: "Support", key: "/help-support" },
      { label: "Ticket Types", key: "/ticket-types" },
    ],
  },
];
