import React from "react";
import { Button, Result, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const CustomResult = styled(Result)`
  .ant-result-icon {
    font-size: 120px;
  }
  .ant-result-title {
    font-size: 32px;
    color: #333;
  }
  .ant-result-subtitle {
    font-size: 18px;
    color: #555;
  }
  .ant-btn-primary {
    height: 40px;
    font-size: 16px;
  }
`;

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <CustomResult
      status="404"
      title={<Typography.Title level={1}>Page Not Found</Typography.Title>}
      subTitle={
        <Typography.Text type="secondary">
          We're sorry, the page you were looking for doesn't exist.
        </Typography.Text>
      }
      extra={
        <Button type="primary" size="large" onClick={() => navigate("/")}>
          Back to Home
        </Button>
      }
    />
  );
};

export default NotFound;
