import React, { useState } from "react";
import { Card } from "antd";
import PageTemplate from "components/PageTemplate";
import PatientTable from "./PatientTable";
import PatientActions from "./PatientActions";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routeConstants";
import { PatientRecords } from "../data";
import PatientRecordFilter from "./PatientRecordFilter";

const PatientRecordPage: React.FC = () => {
  const navigate = useNavigate();
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState<boolean>(false);

  const handleSearchClick = () => {
    setIsAdvancedSearchOpen(true);
  };

  const handleAddNewClick = () => {
    navigate(ROUTES.PATIENT_RECORDS.ADD_UPDATE);
  };

  return (
    <PageTemplate currentNode="Patient Record">
      <Card
        style={{
          padding: "40px 20px",
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <PatientActions onSearch={handleSearchClick} onAddNew={handleAddNewClick} />
        <PatientTable dataSource={PatientRecords} />
      </Card>
      <PatientRecordFilter open={isAdvancedSearchOpen} setOpen={setIsAdvancedSearchOpen} />
    </PageTemplate>
  );
};

export default PatientRecordPage;
