import { RouteObject } from "react-router-dom";
import { ROUTES } from "constants/routeConstants";
import { RouteComponents } from "./routeComponents";
import PrivateRoute from "components/PrivateRoute";

const createRoute = (path: string, Component: JSX.Element, isPrivate = false): RouteObject => ({
  path,
  element: isPrivate ? <PrivateRoute element={Component} /> : Component,
});

const getAuthRoutes = (): RouteObject[] => [
  createRoute(ROUTES.AUTH.LOGIN, <RouteComponents.LoginPage />),
  createRoute(ROUTES.AUTH.REGISTER, <RouteComponents.RegisterPage />),
  createRoute(ROUTES.AUTH.RESET_PASSWORD, <RouteComponents.ResetPasswordPage />),
];

const getDashboardRoutes = (): RouteObject[] => [
  createRoute(ROUTES.DASHBOARD, <RouteComponents.Dashboard />, true),
];

const getAppointmentRoutes = (): RouteObject[] => [
  createRoute(ROUTES.APPOINTMENT, <RouteComponents.AppointmentPage />, true),
];

const getPatientRecordsRoutes = (): RouteObject[] => [
  createRoute(ROUTES.PATIENT_RECORDS.BASE, <RouteComponents.PatientRecord />, true),
  createRoute(ROUTES.PATIENT_RECORDS.ADD_UPDATE, <RouteComponents.PatientRecordForm />, true),
  createRoute(`${ROUTES.PATIENT_RECORDS.BASE}/:id`, <RouteComponents.PatientRecordForm />, true),
];

const getSubscriptionRoutes = (): RouteObject[] => [
  createRoute(ROUTES.SUBSCRIPTION.BASE, <RouteComponents.SubscriptionPage />, true),
  createRoute(ROUTES.SUBSCRIPTION.CREATE, <RouteComponents.SubscriptionForm />, true),
];

const getConsultationRoutes = (): RouteObject[] => [
  createRoute(ROUTES.CONSULTATION.BASE, <RouteComponents.ConsultationPage />, true),
  createRoute(`${ROUTES.CONSULTATION.BASE}/:id`, <RouteComponents.ConsultationDetailsPage />, true),
];

const getSupportRoutes = (): RouteObject[] => [
  createRoute(ROUTES.HELP_SUPPORT, <RouteComponents.HelpAndSupport />, true),
  createRoute(ROUTES.TICKET_TYPES, <RouteComponents.ListTicketTypes />, true),
];

export const routeConfigs = {
  authRoutes: getAuthRoutes(),
  dashboardRoutes: getDashboardRoutes(),
  appointmentRoutes: getAppointmentRoutes(),
  patientRecordsRoutes: getPatientRecordsRoutes(),
  subscriptionRoutes: getSubscriptionRoutes(),
  consultationRoutes: getConsultationRoutes(),
  supportRoutes: getSupportRoutes(),
};
