/** @jsxImportSource @emotion/react */
import React from "react";
import { Card, Col, Row, Space, Typography } from "antd";
import { css } from "@emotion/react";
import { StyledSmallCard, StyledStyledSpaceDiv } from "../common/CardWrapper";

const { Text } = Typography;

const DashboardActions: React.FC = () => {
  return (
    <>
      <Card
        css={css`
          box-shadow: 6px 6px 54px 0px #0000000d;
          border-radius: 12px;
        `}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <StyledSmallCard
            css={css`
              width: 100%;
              border: 1px dashed #b5b6b7;
            `}
          >
            <Space size="large">
              <StyledStyledSpaceDiv />
              <Text>Open Patient File</Text>
            </Space>
          </StyledSmallCard>
          <StyledSmallCard
            css={css`
              width: 100%;
              border: 1px dashed #b5b6b7;
            `}
          >
            <Space size="large">
              <StyledStyledSpaceDiv />
              <Text>Create New Appointment</Text>
            </Space>
          </StyledSmallCard>
          <StyledSmallCard
            css={css`
              width: 100%;
              border: 1px dashed #b5b6b7;
            `}
          >
            <Space size="large">
              <StyledStyledSpaceDiv />
              <Text>Appointments</Text>
            </Space>
          </StyledSmallCard>
          <StyledSmallCard
            css={css`
              width: 100%;
              border: 1px dashed #b5b6b7;
            `}
          >
            <Space size="large">
              <StyledStyledSpaceDiv />
              <Text>Consultation</Text>
            </Space>
          </StyledSmallCard>
        </Space>
      </Card>
    </>
  );
};

export default DashboardActions;
