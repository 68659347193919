/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Button, Dropdown, Menu, Modal, Input, Form, Row, Col, Divider, Select } from "antd";
import {
  EllipsisOutlined,
  FileTextOutlined,
  PlusCircleOutlined,
  CompassOutlined,
  LineChartOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { css } from "@emotion/react";

const { TextArea } = Input;
const { Option } = Select;

type ActionColumnProps = {
  showActions: boolean;
};

const eyeFields = [
  { name: "Sph", placeholder: "Enter Sph" },
  { name: "Cyl", placeholder: "Enter Cyl" },
  { name: "Axis", placeholder: "Enter Axis" },
  { name: "Add", placeholder: "Enter Add" },
  { name: "Inter", placeholder: "Enter Inter" },
  { name: "VA", placeholder: "Enter VA" },
];

const EyeFormSection = ({ eye, formFields }: { eye: string; formFields: typeof eyeFields }) => (
  <>
    <h3
      css={css`
        margin-bottom: 8px;
        font-size: 14px;
        color: #333;
        text-align: center;
        width: 100%;
      `}
    >
      {eye} Eye
    </h3>
    <Col span={24}>
      <Row gutter={8}>
        {formFields.map((field) => (
          <Col span={4} key={field.name}>
            <Form.Item
              label={field.name}
              name={`${eye.toLowerCase()}Eye${field.name}`}
              rules={[{ required: true, message: `Please enter ${eye} Eye ${field.name} value` }]}
            >
              <Input
                style={{
                  borderRadius: "4px",
                  padding: "6px",
                  borderColor: "#d9d9d9",
                }}
              />
            </Form.Item>
          </Col>
        ))}
      </Row>
    </Col>
  </>
);

const EyePrismFormSection = ({ eye }: { eye: string }) => (
  <Col span={12}>
    <div
      css={css`
        padding: 16px;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        background-color: #fafafa;
        margin-bottom: 16px;
      `}
    >
      <h3
        css={css`
          margin-bottom: 16px;
          font-size: 16px;
          color: #333;
          text-align: center;
        `}
      >
        {eye} Eye
      </h3>
      <Form.Item
        label="H (Horizontal)"
        name={`${eye.toLowerCase()}EyeH`}
        rules={[{ required: true, message: `Please enter ${eye} Eye Horizontal value` }]}
      >
        <Input
          style={{
            borderRadius: "6px",
            borderColor: "#d9d9d9",
          }}
        />
      </Form.Item>
      <Form.Item
        label="V (Vertical)"
        name={`${eye.toLowerCase()}EyeV`}
        rules={[{ required: true, message: `Please select ${eye} Eye Vertical value` }]}
      >
        <Select
          placeholder="Select value"
          style={{
            width: "100%",
            borderRadius: "6px",
            borderColor: "#d9d9d9",
          }}
        >
          <Option value="1">1</Option>
          <Option value="2">2</Option>
          <Option value="3">3</Option>
        </Select>
      </Form.Item>
    </div>
  </Col>
);

const ActionColumn: React.FC<ActionColumnProps> = ({ showActions }) => {
  const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);
  const [isPrescriptionModalVisible, setIsPrescriptionModalVisible] = useState<null | "SU" | "GI">(
    null
  );
  const [isAddPrismModalVisible, setIsAddPrismModalVisible] = useState(false);

  const handleMenuClick = (e: any) => {
    if (e.key === "notes") {
      setIsNotesModalVisible(true);
    } else if (e.key === "add-prism") {
      setIsAddPrismModalVisible(true);
    }
  };

  const handleModalCancel = () => {
    setIsNotesModalVisible(false);
    setIsPrescriptionModalVisible(null);
    setIsAddPrismModalVisible(false);
  };

  const handlePrescriptionModalOpen = (type: "SU" | "GI") => {
    setIsPrescriptionModalVisible(type);
  };

  const dropdownMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="notes" icon={<FileTextOutlined />}>
        Notes
      </Menu.Item>
      <Menu.Item key="add-prism" icon={<PlusCircleOutlined />}>
        Add Prism
      </Menu.Item>
      <Menu.Item key="compare" icon={<CompassOutlined />}>
        Compare
      </Menu.Item>
      <Menu.Item key="comparison" icon={<LineChartOutlined />}>
        Comparison
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: center;
          gap: 8px;
        `}
      >
        {showActions && (
          <>
            <Button
              type="primary"
              css={css`
                border-radius: 4px;
              `}
              icon={<PlusOutlined />}
              onClick={() => handlePrescriptionModalOpen("SU")}
            >
              SU
            </Button>
            <Button
              type="primary"
              css={css`
                border-radius: 4px;
                margin-left: 8px;
              `}
              icon={<PlusOutlined />}
              onClick={() => handlePrescriptionModalOpen("GI")}
            >
              GI
            </Button>
          </>
        )}

        <Dropdown overlay={dropdownMenu} trigger={["click"]}>
          <Button
            icon={<EllipsisOutlined />}
            css={css`
              border-radius: 4px;
            `}
          />
        </Dropdown>
      </div>

      <Modal
        title="Note"
        visible={isNotesModalVisible}
        onOk={handleModalCancel}
        onCancel={handleModalCancel}
      >
        <TextArea rows={4} placeholder="Enter your note here" />
      </Modal>

      <Modal
        title={
          isPrescriptionModalVisible === "SU" ? "Suggested Prescription" : "Given Prescription"
        }
        visible={isPrescriptionModalVisible !== null}
        onOk={handleModalCancel}
        onCancel={handleModalCancel}
        centered
        bodyStyle={{
          padding: "16px",
          backgroundColor: "#f9f9f9",
        }}
        width={600}
      >
        <Form layout="vertical">
          <Row gutter={16}>
            <EyeFormSection eye="Left" formFields={eyeFields} />
          </Row>
          <Divider
            css={css`
              margin: 16px 0;
            `}
          ></Divider>
          <Row gutter={16}>
            <EyeFormSection eye="Right" formFields={eyeFields} />
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Add Prism"
        visible={isAddPrismModalVisible}
        onOk={handleModalCancel}
        onCancel={handleModalCancel}
        centered
        bodyStyle={{
          padding: "24px",
        }}
        width={600}
      >
        <Form layout="vertical">
          <Row gutter={16}>
            <EyePrismFormSection eye="Left" />
            <EyePrismFormSection eye="Right" />
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ActionColumn;
