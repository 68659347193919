import React from "react";
import { Col } from "antd";
import NotesCard from "../cards/NotestCard";

const NotesSection: React.FC = () => {
  return (
    <>
      <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
        <NotesCard title="Pupils" />
      </Col>
      <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
        <NotesCard title="Opthalmoscopy" />
      </Col>
      <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
        <NotesCard title="Biomicroscopy" />
      </Col>
    </>
  );
};

export default NotesSection;
