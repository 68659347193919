import React from "react";
import { Navigate } from "react-router-dom";
import AuthUtilities from "utils/auth";

type PrivateRouteProps = {
  element: React.ReactElement;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const isAuthenticated = true;
  return isAuthenticated ? element : <Navigate to="/signin" />;
};

export default PrivateRoute;
