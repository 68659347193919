import React from "react";
import { Button, Row, Space } from "antd";
import { StyledTitle, StyledSecondaryText } from "../common/Typography";
import { FilterButton, ClockButton, PlusButton } from "../common/Buttons";

const DashboardHeader = () => {
  return (
    <Row justify="space-between" style={{ marginTop: "-20px" }}>
      <div>
        <StyledTitle level={2} style={{ marginBottom: "0px" }}>
          Welcome back,
        </StyledTitle>
        <StyledSecondaryText type="secondary">Your analytics dashboard</StyledSecondaryText>
      </div>
      <Space>
        <FilterButton />
        <ClockButton />
        <PlusButton />
        <Button type="primary">Appointment</Button>
      </Space>
    </Row>
  );
};

export default DashboardHeader;
