import { Event } from "types/event.type";

const today = new Date();
const startOfWeek1 = new Date(today.setDate(today.getDate() - today.getDay()));

export const events: Event[] = [
  {
    id: "1",
    startTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate(),
      11,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate(),
      12,
      0,
      0
    ),
    title: "Project Kickoff Meeting",
    allDay: false,
  },
  {
    id: "2",
    startTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate() + 1,
      12,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate() + 1,
      13,
      0,
      0
    ),
    title: "Eye Test",
    allDay: false,
  },
  {
    id: "3",
    startTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate() + 2,
      10,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate() + 2,
      11,
      0,
      0
    ),
    title: "Medical Test",
    allDay: false,
  },
  {
    id: "4",
    startTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate() + 3,
      14,
      30,
      0
    ),
    endTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate() + 3,
      15,
      30,
      0
    ),
    title: "Dental Checkup",
    allDay: false,
  },
  {
    id: "5",
    startTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate() + 4,
      9,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate() + 4,
      10,
      0,
      0
    ),
    title: "Follow-up Appointment",
    allDay: false,
  },
  {
    id: "6",
    startTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate() + 5,
      15,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate() + 5,
      16,
      0,
      0
    ),
    title: "Physiotherapy Session",
    allDay: false,
  },
  {
    id: "7",
    startTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate() + 6,
      11,
      0,
      0
    ),
    endTime: new Date(
      startOfWeek1.getFullYear(),
      startOfWeek1.getMonth(),
      startOfWeek1.getDate() + 6,
      12,
      0,
      0
    ),
    title: "Nutrition Consultation",
    allDay: false,
  },
];
