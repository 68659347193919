import { useApiService } from "hooks/useApiService";
import { PatientRecord, PatientRecordResponseInterface } from "./type";
import { QUERY_KEYS } from "constants/queryKeys";
import { patientRecordApi } from "./patientRecordApi";

export const usePatientRecordService = () => {
  return useApiService<PatientRecordResponseInterface, PatientRecord>(
    QUERY_KEYS.PATIENT_RECORDS,
    patientRecordApi
  );
};
