import { useMemo } from "react";
import queryParamsManager from "core/QueryParamsManager";

export const useQueryString = (): string => {
  const queryParams = queryParamsManager.getQueryParams();

  return useMemo(
    () => queryParams.toQueryString(),
    [
      queryParams.pagination.page,
      queryParams.pagination.pageSize,
      queryParams.sort.sortBy,
      queryParams.sort.sortOrder,
      ...Object.values(queryParams.filters),
    ]
  );
};
