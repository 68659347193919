import { authClient } from "utils/httpClient";
import { ChangePasswordPayload, VerifyOtpRequest } from "./types";
import { ApiResponse } from "types/api.response";
import { API_ENDPOINTS } from "constants/apiEndpoints";

export const requestOtp = async (email: string): Promise<ApiResponse> => {
  try {
    const response = await authClient.post<ApiResponse>(API_ENDPOINTS.CHANGE_PASSWORD.REQUEST_OTP, {
      email,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOtp = async (data: VerifyOtpRequest): Promise<ApiResponse> => {
  try {
    const response = await authClient.post<ApiResponse>(
      API_ENDPOINTS.CHANGE_PASSWORD.VERIFY_OTP,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (data: ChangePasswordPayload): Promise<ApiResponse> => {
  try {
    const response = await authClient.post<ApiResponse>(
      API_ENDPOINTS.CHANGE_PASSWORD.CHANGE_PASSWORD,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
