import { MutationCache, QueryCache, QueryClientConfig } from "@tanstack/react-query";
import useGlobalErrorHandler from "hooks/useGlobalErrorHandler";
import { formatError } from "utils/notification";

const useQueryClientConfig = (): QueryClientConfig => {
  const handleGlobalError = useGlobalErrorHandler();

  return {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
    queryCache: new QueryCache({
      onError: (error) => handleGlobalError(error),
    }),
    mutationCache: new MutationCache({
      onError: (error) => formatError(error),
    }),
  };
};

export default useQueryClientConfig;
