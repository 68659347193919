/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useCallback } from "react";
import { Card, Avatar, Popconfirm, Row, Col, Button, Space, Empty } from "antd";
import { MoreOutlined, MailOutlined, PhoneOutlined, CheckCircleOutlined } from "@ant-design/icons";
import Message from "../SupportMessage/Message";
import {
  ActionWrapper,
  CardBody,
  CardHeader,
  MessageContainer,
  MessageInput,
  SendButton,
  StyledButton,
  StyledCard,
} from "../../styles";
import { useSocket } from "hooks/useSocket";
import { Socket } from "socket.io-client";
import { SupportTicket, SupportTicketMessage } from "services/SupportTicketService";

interface TicketDetailProps {
  selectedTicket: SupportTicket | null;
  messages: SupportTicketMessage[];
  message: string;
  setMessage: (msg: string) => void;
  handleSendMessage: (
    message: string,
    selectedTicket: SupportTicket | null,
    socket: Socket | null
  ) => void;
  handleMarkComplete: () => void;
}

const TicketDetail: React.FC<TicketDetailProps> = ({
  selectedTicket,
  messages,
  message,
  setMessage,
  handleSendMessage,
  handleMarkComplete,
}) => {
  const { socket } = useSocket(selectedTicket?._id);

  const handleSendMessageClick = useCallback(() => {
    if (selectedTicket) {
      handleSendMessage(message, selectedTicket, socket);
    }
  }, [handleSendMessage, message, selectedTicket, socket]);

  if (!selectedTicket) {
    return (
      <Col span={17}>
        <StyledCard
          css={css`
            overflow: hidden;
            position: relative;
            border-radius: 12px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: #ffffff;
            border: 1px solid #e0e0e0;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          `}
        >
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No ticket selected"
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </StyledCard>
      </Col>
    );
  }

  return (
    <Col span={17}>
      <StyledCard
        css={css`
          overflow: hidden;
          position: relative;
          border-radius: 12px;
          height: 100%;
        `}
      >
        <CardHeader
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            border-bottom: 1px solid #dcdcdc;
            background: #e3f2fd; /* Light blue background */
            border-radius: 12px 12px 0 0;
          `}
        >
          <Space>
            <Avatar
              src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              size={64}
              css={css`
                margin-right: 16px;
              `}
            />
            <div>
              <h4
                css={css`
                  margin: 0;
                  font-size: 18px;
                  font-weight: 600;
                  color: #333;
                `}
              >
                {selectedTicket.ticketIssuerType}
              </h4>
              <p
                css={css`
                  margin: 0;
                  color: #666;
                `}
              >
                {selectedTicket.ticketIssuer}
              </p>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  margin-top: 8px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-right: 12px;
                  `}
                >
                  <MailOutlined
                    style={{ color: "#40a9ff", fontSize: "18px", marginRight: "4px" }}
                  />
                  <span
                    css={css`
                      font-size: 14px;
                      color: #40a9ff;
                    `}
                  >
                    Email
                  </span>
                </div>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <PhoneOutlined
                    style={{ color: "#73d13d", fontSize: "18px", marginRight: "4px" }}
                  />
                  <span
                    css={css`
                      font-size: 14px;
                      color: #73d13d;
                    `}
                  >
                    Call
                  </span>
                </div>
              </div>
            </div>
          </Space>
          <Popconfirm
            title="Mark as completed"
            description="Are you sure you want to mark this ticket as completed?"
            onConfirm={handleMarkComplete}
            okText="Yes"
            cancelText="No"
          >
            <StyledButton
              type="primary"
              css={css`
                padding: 8px 16px;
                border-radius: 20px; /* More rounded corners */
                background-color: #ff6f61; /* Stylish color */
                border: none;
                color: white;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background-color 0.3s, transform 0.3s;
                &:hover {
                  background-color: #ff3d3d;
                  transform: scale(1.05); /* Slight zoom effect */
                }
              `}
            >
              <CheckCircleOutlined
                css={css`
                  margin-right: 8px;
                `}
              />
              Mark as Complete
            </StyledButton>
          </Popconfirm>
          <ActionWrapper
            css={css`
              display: flex;
              align-items: center;
              margin-left: 12px;
            `}
          >
            <MoreOutlined
              css={css`
                font-size: 18px;
                color: #666;
                cursor: pointer;
                transition: color 0.3s ease;
                &:hover {
                  color: #333;
                }
              `}
            />
          </ActionWrapper>
        </CardHeader>
        <CardBody
          css={css`
            padding: 12px;
            background: #f9f9f9;
          `}
        >
          <MessageContainer>
            <Message messages={messages} />
            <div id="data-wrapper" />
          </MessageContainer>
          <Card
            css={css`
              padding: 16px;
              border: 1px solid #ddd;
              border-radius: 8px;
              background: #fff;
              position: absolute;
              box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
              bottom: 2%;
              width: calc(100% - 32px);
            `}
            bordered={false}
          >
            <Row gutter={16}>
              <Col span={21}>
                <MessageInput
                  placeholder="Type your message..."
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  css={css`
                    border-radius: 8px;
                    border: 1px solid #ddd;
                    padding: 8px;
                    width: 100%;
                    font-size: 14px;
                  `}
                />
              </Col>
              <Col span={3}>
                <SendButton onClick={handleSendMessageClick} type="primary">
                  Send
                </SendButton>
              </Col>
            </Row>
          </Card>
        </CardBody>
      </StyledCard>
    </Col>
  );
};

export default TicketDetail;
