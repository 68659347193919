import React from "react";
import { Form, Button, Drawer } from "antd";
import DynamicFormBuilder from "components/DynamicFormBuilder";
import PatientRecordFilterConfig from "../form/PatientRecordFilterConfig";

interface PatientRecordFilterProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const PatientRecordFilter: React.FC<PatientRecordFilterProps> = ({ open, setOpen }) => {
  const [form] = Form.useForm();

  const handleFinish = (values: any) => {};
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer title="Advance Search" onClose={onClose} open={open} placement="left">
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <DynamicFormBuilder form={form} fieldsConfig={PatientRecordFilterConfig} />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default PatientRecordFilter;
