export const BASE_PATHS = {
  DASHBOARD: "/dashboard",
  APPOINTMENT: "/appointment",
  HELP_SUPPORT: "/help-support",
  TICKET_TYPES: "/ticket-types",
};

export const AUTH_ROUTES = {
  LOGIN: "/signin",
  REGISTER: "/signup",
  RESET_PASSWORD: "/resetpassword",
  CHANGE_PASSWORD: "/changepassword",
};

export const PATIENT_RECORDS_ROUTES = {
  BASE: "/patient-records",
  ADD_UPDATE: "/patient-records/add/update",
  DETAIL: (id: string) => `/patient-records/${id}`,
};

export const SUBSCRIPTION_ROUTES = {
  BASE: "/subscription",
  CREATE: "/subscription/create",
};

export const CONSULTATION_ROUTES = {
  BASE: "/consultation",
  DETAIL: (id: string) => `/consultation/${id}`,
};

export const Fallback_ROUTES = {
  NOT_FOUND: "*",
};

export const ROUTES = {
  AUTH: AUTH_ROUTES,
  DASHBOARD: BASE_PATHS.DASHBOARD,
  APPOINTMENT: BASE_PATHS.APPOINTMENT,
  PATIENT_RECORDS: PATIENT_RECORDS_ROUTES,
  SUBSCRIPTION: SUBSCRIPTION_ROUTES,
  CONSULTATION: CONSULTATION_ROUTES,
  HELP_SUPPORT: BASE_PATHS.HELP_SUPPORT,
  NOT_FOUND: Fallback_ROUTES.NOT_FOUND,
  TICKET_TYPES: BASE_PATHS.TICKET_TYPES,
};
