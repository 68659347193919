import { authClient } from "utils/httpClient";
import { LoginResponseDTO, UserCredentialsDTO } from "./types";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { storage } from "utils/storage";
import { AxiosError } from "axios";
import { showNotification } from "utils/notification";
import { API_ENDPOINTS } from "constants/apiEndpoints";
import { ROUTES } from "constants/routeConstants";

const loginFn = async (credentials: UserCredentialsDTO): Promise<LoginResponseDTO> => {
  try {
    const response = await authClient.post<LoginResponseDTO>(API_ENDPOINTS.AUTH.LOGIN, credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useLogin = (): UseMutationResult<LoginResponseDTO, AxiosError, UserCredentialsDTO> => {
  const navigate = useNavigate();

  return useMutation<LoginResponseDTO, AxiosError, UserCredentialsDTO>({
    mutationFn: loginFn,
    onSuccess: ({ data, token, message }) => {
      showNotification("success", "Success", message || "Logged in successful");
      storage.setUser(data);
      storage.setToken(token);
      navigate(ROUTES.DASHBOARD);
    },
  });
};
