import styled from "@emotion/styled";
import { Card } from "antd";

export const CardWrapper = styled(Card)`
  box-shadow: 6px 6px 54px 0px #0000000d;
  border-radius: 12px;
`;

export const StyledStatisticCard = styled(CardWrapper)`
  padding: 12px;
  box-shadow: 6px 6px 54px 0px #0000000d;
  border-radius: 12px;
`;

export const StyledSmallCard = styled(CardWrapper)`
  &.ant-card .ant-card-body {
    padding: 16px;
  }
`;

export const StyledAreaCard = styled(CardWrapper)`
  height: 100%;
`;

export const StyledPieCard = styled(CardWrapper)`
  height: 100%;
`;

export const StyledStyledSpaceDiv = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 6px 0px #0000001f;
  border-radius: 4px;
`;
