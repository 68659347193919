/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Col, Card, Table, Form, Typography, Row, Button } from "antd";
import MatrixTableCard from "../cards/MatrixTableCard";
import {
  kReadingColumns,
  pdColumns,
  visualAcuityColumns,
  averageColumns,
  cylColumns,
} from "../handlers/tableColumns";
import { kReadingData, matrixTableData2, matrixTableData3 } from "../data";
import TextArea from "antd/es/input/TextArea";
import IOPCard from "../cards/IOPCard";
import BinocularCard from "../cards/BinoccularCard";
import NotesCard from "../cards/NotestCard";
import TherapeuticPrescription from "./TherepeuticPrescription";
import { cardStyle } from "../styles";

const KReadingSection: React.FC = () => {
  return (
    <>
      <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
        <MatrixTableCard
          title="Interpupillary Distance"
          dataSource={matrixTableData2}
          columns={pdColumns}
        />
      </Col>
      <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
        <MatrixTableCard
          title="Visual Acuity"
          dataSource={matrixTableData3}
          columns={visualAcuityColumns}
        />
      </Col>
      <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
        <IOPCard />
      </Col>
      <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
        <BinocularCard />
      </Col>
      <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Card css={cardStyle}>
              <Typography.Title level={5}>K Reading</Typography.Title>
              <Table
                columns={kReadingColumns}
                dataSource={kReadingData}
                pagination={false}
                size="small"
                rowKey="key"
                showHeader
              />
              <Typography.Title level={5}>Average</Typography.Title>
              <Table
                columns={averageColumns}
                dataSource={kReadingData}
                pagination={false}
                size="small"
                rowKey="key"
                showHeader
              />
              <Typography.Title level={5}>Cyl</Typography.Title>
              <Table
                columns={cylColumns}
                dataSource={kReadingData}
                pagination={false}
                size="small"
                rowKey="key"
                showHeader
              />

              <Form layout="vertical" style={{ marginTop: "12px" }}>
                <Form.Item label="Notes" name="notes">
                  <TextArea rows={4} />
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col span={12}>
            <NotesCard title="Visual Field" />
          </Col>
          <Col span={12}>
            <NotesCard title="Color Vision" />
          </Col>
          <TherapeuticPrescription />
        </Row>
      </Col>
    </>
  );
};

export default KReadingSection;
