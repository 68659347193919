/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Row, Col, Card, Table, Button, Input, Tag } from "antd";
import { SearchOutlined, FilterOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routeConstants";
import { PatientRecord } from "services/PatientRecordService";
import { PatientRecords } from "Scenes/PatientRecords/data";
import PageTemplate from "components/PageTemplate";

const { Search } = Input;

const ConsultationPage = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>("");

  const handleViewDetails = (record: PatientRecord) => {
    navigate(ROUTES.CONSULTATION.DETAIL("1"));
  };

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (text: any, record: PatientRecord) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text: any, record: PatientRecord) =>
        `${record.address.street}, ${record.address.city}, ${record.address.state} ${record.address.postalCode}`,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        let color;
        let borderColor;
        let textColor;

        if (status === "Finalised") {
          color = "#E3F7FC";
          borderColor = "#A3E4F5";
          textColor = "#0C5C6E";
        } else if (status === "Incomplete") {
          color = "#F3F4F6";
          borderColor = "#D1D5DB";
          textColor = "#6B7280";
        }

        return (
          <Tag
            style={{
              backgroundColor: color,
              borderColor: borderColor,
              color: textColor,
              borderRadius: "4px",
              padding: "4px 8px",
              fontWeight: "bold",
            }}
          >
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Options",
      key: "action",
      render: (text: any, record: PatientRecord) => (
        <Button onClick={() => handleViewDetails(record)} type="text" icon={<EyeOutlined />} />
      ),
    },
  ];

  return (
    <PageTemplate currentNode="Consultation">
      <Card
        style={{
          padding: "40px 20px",
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Row gutter={16} align="middle" style={{ marginBottom: "16px" }}>
          <Col>
            <Search
              placeholder="Search by Name or Email"
              onChange={(e) => setSearchText(e.target.value)}
              style={{ width: 300 }}
              prefix={<SearchOutlined />}
            />
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => console.log("Filter clicked")}
              icon={<FilterOutlined />}
            >
              Filter
            </Button>
          </Col>
        </Row>
        <Table dataSource={PatientRecords} columns={columns} />
      </Card>
    </PageTemplate>
  );
};

export default ConsultationPage;
