import { useApiService } from "hooks/useApiService";
import { SubscriptionPackageInterface, SubscriptionPackageResponseInterface } from "./types";
import { QUERY_KEYS } from "constants/queryKeys";
import { subscriptionApi } from "./subscriptionApi";

export const useSubscriptionService = () => {
  return useApiService<SubscriptionPackageResponseInterface, SubscriptionPackageInterface>(
    QUERY_KEYS.SUBSCRIPTION_PLANS,
    subscriptionApi
  );
};
