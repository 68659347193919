import { QueryParamsConfig } from "./QueryParamsConfig";

class QueryParamsManager {
  private queryParams: QueryParamsConfig;

  constructor() {
    this.queryParams = new QueryParamsConfig();
  }

  getQueryParams(): QueryParamsConfig {
    return this.queryParams;
  }

  setQueryParams(newParams: Partial<QueryParamsConfig>): void {
    this.queryParams = new QueryParamsConfig({
      ...this.queryParams,
      ...newParams,
    });
  }

  updateQueryParams(updates: Partial<QueryParamsConfig>): void {
    this.queryParams = new QueryParamsConfig({
      ...this.queryParams,
      ...updates,
    });
  }

  toQueryString(): string {
    return this.queryParams.toQueryString();
  }

  parseQueryString(queryString: string): void {
    this.queryParams = QueryParamsConfig.fromQueryString(queryString);
  }
}

const queryParamsManager = new QueryParamsManager();
export default queryParamsManager;
