import { PatientRecord } from "services/PatientRecordService/type";

export const PatientRecords: PatientRecord[] = [
  {
    id: "1",
    firstName: "John",
    lastName: "Doe",
    dateOfBirth: "1990-01-01",
    gender: "Male",
    address: {
      street: "123 Main St",
      city: "Anytown",
      state: "CA",
      postalCode: "12345",
    },
    phoneNumber: "555-1234",
    email: "john.doe@example.com",
    medicalHistory: ["Asthma", "High Blood Pressure"],
    allergies: ["Peanuts"],
    emergencyContact: {
      name: "Jane Doe",
      relationship: "Wife",
      phoneNumber: "555-5678",
    },
    createdAt: "2024-01-01T10:00:00Z",
    updatedAt: "2024-01-01T10:00:00Z",
    status: "Finalised",
  },
  {
    id: "2",
    firstName: "Mary",
    lastName: "Smith",
    dateOfBirth: "1985-05-15",
    gender: "Female",
    address: {
      street: "456 Elm St",
      city: "Othertown",
      state: "TX",
      postalCode: "67890",
    },
    phoneNumber: "555-9876",
    email: "mary.smith@example.com",
    medicalHistory: ["Diabetes"],
    allergies: ["Penicillin"],
    emergencyContact: {
      name: "John Smith",
      relationship: "Husband",
      phoneNumber: "555-6543",
    },
    createdAt: "2024-01-01T11:00:00Z",
    updatedAt: "2024-01-01T11:00:00Z",
    status: "Finalised",
  },
  {
    id: "3",
    firstName: "Alex",
    lastName: "Johnson",
    dateOfBirth: "1978-11-30",
    gender: "Other",
    address: {
      street: "789 Oak St",
      city: "Sometown",
      state: "FL",
      postalCode: "11223",
    },
    phoneNumber: "555-3333",
    email: "alex.johnson@example.com",
    medicalHistory: ["Heart Disease"],
    allergies: ["Latex"],
    emergencyContact: {
      name: "Sam Johnson",
      relationship: "Sibling",
      phoneNumber: "555-1111",
    },
    createdAt: "2024-01-01T12:00:00Z",
    updatedAt: "2024-01-01T12:00:00Z",
    status: "Finalised",
  },
  {
    id: "4",
    firstName: "Emily",
    lastName: "Williams",
    dateOfBirth: "1995-07-22",
    gender: "Female",
    address: {
      street: "321 Pine St",
      city: "Newcity",
      state: "NY",
      postalCode: "44556",
    },
    phoneNumber: "555-2222",
    email: "emily.williams@example.com",
    medicalHistory: ["None"],
    allergies: ["None"],
    emergencyContact: {
      name: "Mark Williams",
      relationship: "Father",
      phoneNumber: "555-8888",
    },
    createdAt: "2024-01-01T13:00:00Z",
    updatedAt: "2024-01-01T13:00:00Z",
    status: "Incomplete",
  },
  {
    id: "5",
    firstName: "Michael",
    lastName: "Brown",
    dateOfBirth: "1982-03-17",
    gender: "Male",
    address: {
      street: "654 Maple St",
      city: "Oldtown",
      state: "WA",
      postalCode: "99887",
    },
    phoneNumber: "555-4444",
    email: "michael.brown@example.com",
    medicalHistory: ["Arthritis"],
    allergies: ["Shellfish"],
    emergencyContact: {
      name: "Laura Brown",
      relationship: "Mother",
      phoneNumber: "555-7777",
    },
    createdAt: "2024-01-01T14:00:00Z",
    updatedAt: "2024-01-01T14:00:00Z",
    status: "Incomplete",
  },
];
