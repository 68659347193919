import { AppointmentInterface, AppointmentResponseInterface } from "./types";
import { authClient } from "utils/httpClient";
import { API_ENDPOINTS } from "constants/apiEndpoints";

const apiClient = authClient;

export const appointmentApi = {
  // Get all appointments
  getAll: async (): Promise<AppointmentResponseInterface> => {
    try {
      const response = await apiClient.get<AppointmentResponseInterface>(
        API_ENDPOINTS.APPOINTMENTS.BASE
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get an appointment by ID
  getById: async (id: string): Promise<AppointmentInterface> => {
    try {
      const response = await apiClient.get<{
        success: boolean;
        data: AppointmentInterface;
        message?: string;
      }>(API_ENDPOINTS.APPOINTMENTS.READ(id));
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  // Create a new appointment
  create: async (appointmentData: AppointmentInterface): Promise<AppointmentInterface> => {
    try {
      const response = await apiClient.post<AppointmentInterface>(
        API_ENDPOINTS.APPOINTMENTS.CREATE,
        appointmentData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Update an existing appointment
  update: async (
    id: string,
    appointmentData: AppointmentInterface
  ): Promise<AppointmentInterface> => {
    try {
      const response = await apiClient.put<AppointmentInterface>(
        API_ENDPOINTS.APPOINTMENTS.UPDATE(id),
        appointmentData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Delete an appointment
  delete: async (id: string): Promise<void> => {
    try {
      await apiClient.delete<void>(API_ENDPOINTS.APPOINTMENTS.DELETE(id));
    } catch (error) {
      throw error;
    }
  },
};
