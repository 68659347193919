/** @jsxImportSource @emotion/react */
import React from "react";
import { Col, Space } from "antd";
import { StyledHeader, StyledRow, StyledCol, StyledDiv, CenteredDiv } from "../styles";
import DropdownMenu from "./DropdownMenu";
import icons from "icons";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const {
    Logo,
    HomeIcon,
    NotificationIcon,
    PatientRecordIcon,
    ScheduleIcon,
    InfoIcon,
    MessageIcon,
    PrintIcon,
    AccountIcon,
  } = icons;

  return (
    <StyledHeader>
      <StyledRow>
        <StyledCol>
          <Logo />
        </StyledCol>
        <StyledCol flex={16}>
          <Space>
            <StyledDiv onClick={() => navigate("/dashboard")}>
              <HomeIcon />
            </StyledDiv>
            <StyledDiv>
              <ScheduleIcon />
            </StyledDiv>
            <StyledDiv>
              <PatientRecordIcon />
            </StyledDiv>
          </Space>
        </StyledCol>
        <Col
          span={5}
          css={css`
            display: flex;
            align-items: center;
            justify-content: end;
          `}
        >
          <Space>
            <StyledDiv>
              <InfoIcon />
            </StyledDiv>
            <StyledDiv>
              <MessageIcon />
            </StyledDiv>
            <StyledDiv>
              <PrintIcon />
            </StyledDiv>
            <StyledDiv>
              <NotificationIcon />
            </StyledDiv>
            <DropdownMenu />
          </Space>
        </Col>
      </StyledRow>
    </StyledHeader>
  );
};

export default Header;
