import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { requestOtp, verifyOtp, resetPassword } from "./resetPasswordService";
import { ResetPasswordRequest, VerifyOtpRequest } from "./types";
import { AxiosError } from "axios";
import { showNotification } from "utils/notification";
import { ApiResponse } from "types/api.response";

interface UseResetPassword {
  requestOtpMutation: UseMutationResult<ApiResponse, AxiosError, string>;
  verifyOtpMutation: UseMutationResult<ApiResponse, AxiosError, VerifyOtpRequest>;
  resetPasswordMutation: UseMutationResult<ApiResponse, AxiosError, ResetPasswordRequest>;
}

export const useResetPassword = (): UseResetPassword => {
  const requestOtpMutation = useMutation<ApiResponse, AxiosError, string>({
    mutationFn: (email: string) => requestOtp(email),
    onSuccess: (data) => {
      showNotification("success", "Success", data.message || "OTP has been sent to your email.");
    },
  });

  const verifyOtpMutation = useMutation<ApiResponse, AxiosError, VerifyOtpRequest>({
    mutationFn: (data: VerifyOtpRequest) => verifyOtp(data),
    onSuccess: (data) => {
      showNotification("success", "Success", data.message || "OTP verified successfully.");
    },
  });

  const resetPasswordMutation = useMutation<ApiResponse, AxiosError, ResetPasswordRequest>({
    mutationFn: (data: ResetPasswordRequest) => resetPassword(data),
    onSuccess: (data) => {
      showNotification("success", "Success", data.message || "Password reset successfully.");
    },
  });

  return {
    requestOtpMutation,
    verifyOtpMutation,
    resetPasswordMutation,
  };
};
