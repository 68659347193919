import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";

const { confirm } = Modal;

type DeleteMutationVariables = string;
type DeleteMutationResponse = void;

const useConfirmDelete = (
  deleteMutation: UseMutationResult<
    DeleteMutationResponse,
    AxiosError<unknown, any>,
    DeleteMutationVariables
  >
) => {
  const confirmDelete = (id: string) => {
    confirm({
      title: "Do you want to delete this item?",
      icon: <ExclamationCircleFilled />,
      content:
        "Once you delete this item, it will be removed permanently. Are you sure you want to proceed?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { loading: deleteMutation.isPending },
      onOk() {
        return deleteMutation.mutateAsync(id).catch(() => null);
      },
      onCancel() {},
    });
  };

  return {
    confirmDelete,
  };
};

export default useConfirmDelete;
