import { useMemo } from "react";
import { HomeOutlined } from "@ant-design/icons";

type BreadcrumbItem = {
  href: string;
  label: string;
  icon?: React.ReactNode;
};

type UseBreadcrumbsProps = {
  currentNode?: string;
  additionalItems?: BreadcrumbItem[];
};

export const useBreadcrumbs = ({ currentNode, additionalItems }: UseBreadcrumbsProps) => {
  return useMemo(() => {
    const items: BreadcrumbItem[] = [
      { href: "/dashboard", label: "Dashboard", icon: <HomeOutlined /> },
      ...(additionalItems || []),
      ...(currentNode ? [{ href: "", label: currentNode }] : []),
    ];
    return items;
  }, [currentNode, additionalItems]);
};
