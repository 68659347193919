import { coreClient } from "utils/httpClient";
import { API_ENDPOINTS } from "constants/apiEndpoints";
import { SupportTicket, SupportTicketsResponse, TicketStatus } from "./types";
import { buildUrl } from "utils/apiUtils";

const apiClient = coreClient;

export const supportTicketApi = {
  getAllSupportTickets: async (): Promise<SupportTicketsResponse> => {
    const response = await apiClient.get<SupportTicketsResponse>(
      buildUrl(API_ENDPOINTS.SUPPORT_TICKETS.GET_ALL)
    );
    return response.data;
  },

  createSupportTicket: async (data: SupportTicket): Promise<SupportTicket> => {
    const response = await apiClient.post<SupportTicket>(
      buildUrl(API_ENDPOINTS.SUPPORT_TICKETS.CREATE),
      data
    );
    return response.data;
  },

  markTicketComplete: async (id: string, status: TicketStatus): Promise<void> => {
    await apiClient.patch<void>(buildUrl(API_ENDPOINTS.SUPPORT_TICKETS.MARK_COMPLETE(id)), {
      status: status,
    });
  },
};
