import { TicketType, TicketTypesResponse } from "./types";
import { coreClient } from "utils/httpClient";
import { API_ENDPOINTS } from "constants/apiEndpoints";
import { buildUrl } from "utils/apiUtils";

const apiClient = coreClient;

export const ticketTypeApi = {
  getAll: async () => {
    const response = await apiClient.get<TicketTypesResponse>(
      buildUrl(API_ENDPOINTS.TICKET_TYPES.GET_ALL)
    );
    return response.data;
  },
  getById: async (id: string) => {
    const response = await apiClient.get<{ success: boolean; data: TicketType; message?: string }>(
      API_ENDPOINTS.TICKET_TYPES.GET_BY_ID(id)
    );
    return response.data.data;
  },
  create: async (data: TicketType) => {
    const response = await apiClient.post<TicketType>(API_ENDPOINTS.TICKET_TYPES.CREATE, data);
    return response.data;
  },
  update: async (id: string, data: TicketType) => {
    const response = await apiClient.put<TicketType>(API_ENDPOINTS.TICKET_TYPES.UPDATE(id), data);
    return response.data;
  },
  delete: async (id: string) => {
    await apiClient.delete<void>(API_ENDPOINTS.TICKET_TYPES.DELETE(id));
  },
};
