import React from "react";
import { Menu } from "antd";
import { menuItems } from "constants/menu";
import { StyledMenu, MenuWrapper } from "../styles";

interface MenuProps {
  onSelect: (e: any) => void;
  selectedKeys: string;
}

const AppMenu: React.FC<MenuProps> = ({ onSelect, selectedKeys }) => {
  return (
    <MenuWrapper>
      <StyledMenu
        mode="horizontal"
        items={menuItems}
        onClick={onSelect}
        selectedKeys={[selectedKeys]}
      />
    </MenuWrapper>
  );
};

export default AppMenu;
