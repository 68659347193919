/** @jsxImportSource @emotion/react */
import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { LogoutOutlined, UserOutlined, SettingOutlined, KeyOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routeConstants";
import authService from "utils/auth";
import styled from "@emotion/styled";

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
  background-color: #1499b8; /* Classic blue */
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: #117a9d; /* Subtle hover color */
    border-color: #117a9d;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
`;

const StyledMenu = styled(Menu)`
  min-width: 280px;
  border-radius: 8px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  background: #ffffff; /* Clean white background */
  font-family: "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #e1e1e1;

  .ant-dropdown-menu-item {
    padding: 12px 16px; /* Comfortable padding */
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f5f5f5; /* Subtle hover effect */
    }
  }
`;

const MenuItemIcon = styled.div`
  color: #555; /* Muted, unified icon color */
  margin-right: 12px; /* Space between icon and text */
  font-size: 16px; /* Adjusted icon size for consistency */
  display: inline-flex;
  align-items: center;
`;

const MenuItemText = styled.span`
  color: #333; /* Darker text color for readability */
  vertical-align: middle;
`;

const StyledDivider = styled(Menu.Divider)`
  margin: 8px 0;
  border-top: 1px solid #e1e1e1;
`;

const DropdownMenu: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // authService.logout();
    // window.location.reload();
    navigate("/signin");
  };

  const handleChangePassword = () => {
    navigate(ROUTES.AUTH.CHANGE_PASSWORD);
  };

  const menu = (
    <StyledMenu>
      <Menu.Item key="profile">
        <MenuItemIcon>
          <UserOutlined />
        </MenuItemIcon>
        <MenuItemText>Profile</MenuItemText>
      </Menu.Item>
      <Menu.Item key="settings">
        <MenuItemIcon>
          <SettingOutlined />
        </MenuItemIcon>
        <MenuItemText>Settings</MenuItemText>
      </Menu.Item>
      <Menu.Item key="password" onClick={handleChangePassword}>
        <MenuItemIcon>
          <KeyOutlined />
        </MenuItemIcon>
        <MenuItemText>Change Password</MenuItemText>
      </Menu.Item>
      <StyledDivider />
      <Menu.Item key="logout" onClick={handleLogout}>
        <MenuItemIcon>
          <LogoutOutlined />
        </MenuItemIcon>
        <MenuItemText>Logout</MenuItemText>
      </Menu.Item>
    </StyledMenu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <StyledAvatar icon={<UserOutlined />} />
    </Dropdown>
  );
};

export default DropdownMenu;
