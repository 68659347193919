import React from "react";
import { Form, Input } from "antd";

const OtpStep: React.FC = () => (
  <Form.Item name="otp" rules={[{ required: true, message: "Please input the OTP!" }]}>
    <Input placeholder="OTP" />
  </Form.Item>
);

export default OtpStep;
