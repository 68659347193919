import { useApiService } from "hooks/useApiService";
import { SupportTicket, SupportTicketsResponse } from "./types";
import { supportTicketApi } from "./supportTicketApi";
import { QUERY_KEYS } from "constants/queryKeys";

export const useSupportTicketService = () => {
  return useApiService<SupportTicketsResponse, SupportTicket>(QUERY_KEYS.SUPPORT_TICEKTS, {
    getAll: supportTicketApi.getAllSupportTickets,
    getById: undefined,
    create: supportTicketApi.createSupportTicket,
    update: undefined,
    patch: supportTicketApi.markTicketComplete,
    delete: undefined,
  });
};
