import { Input } from "antd";

interface InputColumnProps {
  key: string;
  defaultValue?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
}

const inputColumn = ({ key, defaultValue, onChange, style }: InputColumnProps) => ({
  title: key,
  dataIndex: key,
  key,
  render: (text: any) => (
    <Input
      defaultValue={defaultValue || text}
      onChange={onChange}
      style={{ width: "100%", ...style }}
    />
  ),
});

const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  console.log("Input changed:", e.target.value);
};

const commonLabelColumn = {
  title: "",
  dataIndex: "label",
  key: "label",
  render: (text: any) => <strong>{text}</strong>,
};

export const pdColumns = [
  { ...commonLabelColumn, title: "PD" },
  inputColumn({ key: "dist", onChange: handleInputChange }),
  inputColumn({ key: "near", onChange: handleInputChange }),
];

export const visualAcuityColumns = [
  commonLabelColumn,
  inputColumn({ key: "R", onChange: handleInputChange }),
  inputColumn({ key: "L", onChange: handleInputChange }),
  inputColumn({ key: "OU", onChange: handleInputChange }),
];

export const iopColumns = [
  commonLabelColumn,
  inputColumn({ key: "Right", onChange: handleInputChange }),
  inputColumn({ key: "Left", onChange: handleInputChange }),
  inputColumn({ key: "@", onChange: handleInputChange }),
];

export const matrixTableColumns = [
  commonLabelColumn,
  inputColumn({ key: "H", onChange: handleInputChange }),
  inputColumn({ key: "V", onChange: handleInputChange }),
];

export const matrixTableColumns2 = [
  commonLabelColumn,
  inputColumn({ key: "RE", onChange: handleInputChange }),
  inputColumn({ key: "LE", onChange: handleInputChange }),
  inputColumn({ key: "OU", onChange: handleInputChange }),
];

export const matrixTableColumnsExtended = [
  ...matrixTableColumns,
  inputColumn({ key: "PRC", onChange: handleInputChange }),
  inputColumn({ key: "NRC", onChange: handleInputChange }),
];

export const kReadingColumns = [
  commonLabelColumn,
  inputColumn({ key: "MM", onChange: handleInputChange }),
  inputColumn({ key: "D", onChange: handleInputChange }),
  inputColumn({ key: "Axis", onChange: handleInputChange }),
];

export const averageColumns = [
  commonLabelColumn,
  inputColumn({ key: "MM", onChange: handleInputChange }),
  inputColumn({ key: "D", onChange: handleInputChange }),
];

export const cylColumns = [
  commonLabelColumn,
  inputColumn({ key: "D", onChange: handleInputChange }),
  inputColumn({ key: "Axis", onChange: handleInputChange }),
];

export const trialsColumns = [
  commonLabelColumn,
  inputColumn({ key: "BCOR", onChange: handleInputChange }),
  inputColumn({ key: "BCOD", onChange: handleInputChange }),
  inputColumn({ key: "Sph", onChange: handleInputChange }),
  inputColumn({ key: "Cyl", onChange: handleInputChange }),
  inputColumn({ key: "Axis", onChange: handleInputChange }),
  inputColumn({ key: "Add", onChange: handleInputChange }),
  inputColumn({ key: "Va", onChange: handleInputChange }),
];
