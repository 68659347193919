import React, { createContext, useContext, ReactNode, useState, useCallback } from "react";
import { Modal, Button, Result } from "antd";

interface ErrorContextType {
  openErrorModal: (details: {
    message: string;
    description: string;
    isTokenExpired: boolean;
  }) => void;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const ErrorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [errorDetails, setErrorDetails] = useState<{
    message: string;
    description: string;
    isTokenExpired: boolean;
  } | null>(null);

  const openErrorModal = useCallback(
    (details: { message: string; description: string; isTokenExpired: boolean }) => {
      setErrorDetails(details);
      setIsVisible(true);
    },
    []
  );

  const handleCancel = () => {
    setIsVisible(false);
  };

  return (
    <ErrorContext.Provider value={{ openErrorModal }}>
      {children}
      {errorDetails && (
        <Modal open={isVisible} onCancel={handleCancel} footer={null} centered>
          <Result
            status="error"
            title={errorDetails.message}
            subTitle={errorDetails.description}
            extra={[
              errorDetails.isTokenExpired ? (
                <Button
                  key="retry"
                  type="primary"
                  onClick={() => (window.location.href = "/signin")}
                >
                  Go to Login
                </Button>
              ) : (
                <Button key="retry" type="primary" onClick={() => window.location.reload()}>
                  Retry
                </Button>
              ),
              <Button key="contact" onClick={() => (window.location.href = "/dashboard")}>
                Go to Home
              </Button>,
            ]}
          />
        </Modal>
      )}
    </ErrorContext.Provider>
  );
};

export const useErrorContext = (): ErrorContextType => {
  const context = useContext(ErrorContext);
  if (context === undefined) {
    throw new Error("useErrorContext must be used within an ErrorProvider");
  }
  return context;
};
