import React, { useState } from "react";
import { Row, Col, Card, Dropdown, Menu, Typography, Button, Modal, Form, Upload } from "antd";
import { FaImage, FaEye, FaTrashAlt } from "react-icons/fa";
import { MoreOutlined, UploadOutlined, InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const imageList = [
  { id: 1, name: "Left Eye Pupil", size: "500 KB" },
  { id: 2, name: "Right Eye Pupil", size: "750 KB" },
  { id: 3, name: "Left Eye Convex", size: "1.2 MB" },
  { id: 4, name: "Right Eye Convex", size: "900 KB" },
  { id: 5, name: "Left Eye Convex", size: "1.2 MB" },
  { id: 6, name: "Right Eye Convex", size: "900 KB" },
];

const ImagerySection: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleMenuClick = (key: string) => {
    if (key === "preview") {
      console.log("Preview clicked");
    } else if (key === "delete") {
      console.log("Delete clicked");
    }
  };

  const menu = (
    <Menu onClick={({ key }) => handleMenuClick(key)}>
      <Menu.Item key="preview" icon={<FaEye />}>
        Preview
      </Menu.Item>
      <Menu.Item key="delete" icon={<FaTrashAlt />}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      console.log("Uploaded files:", values.upload);
      setIsModalVisible(false);
      form.resetFields();
    });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 24 }}>
        <Button type="primary" icon={<UploadOutlined />} onClick={showModal}>
          Upload Image
        </Button>
      </div>

      <Row gutter={[24, 24]}>
        {imageList.map((image) => (
          <Col span={12} key={image.id}>
            <Card
              style={{
                boxShadow: "0px 8px 40px -10px #00000014",
                borderRadius: "4px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: 50,
                    height: 50,
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 16,
                    borderRadius: "4px",
                  }}
                >
                  <FaImage style={{ fontSize: 24 }} />
                </div>
                <div style={{ flex: 1 }}>
                  <Typography.Text strong>{image.name}</Typography.Text>
                  <Typography.Paragraph style={{ margin: 0 }}>{image.size}</Typography.Paragraph>
                </div>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <MoreOutlined style={{ fontSize: 22, cursor: "pointer" }} />
                </Dropdown>
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        title="Add Image"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Upload"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="upload"
            valuePropName="fileList"
            getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
            rules={[{ required: true, message: "Please upload an image!" }]}
          >
            <Dragger beforeUpload={() => false}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading company data
                or other banned files.
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ImagerySection;
