/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { storage } from "utils/storage";
import { Avatar, Typography } from "antd";
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { SupportTicketMessage } from "services/SupportTicketService";

const { Text } = Typography;

const MessageContainer = styled.div<{ isSender: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isSender }) => (isSender ? "flex-end" : "flex-start")};
  margin-top: 15px;
  padding: 8px;
`;

const SentMessage = styled.div`
  background-color: #e0f7fa;
  color: #00796b;
  border-radius: 16px;
  padding: 12px 18px;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #b2ebf2;
  margin-bottom: 8px;
`;

const ReceivedMessage = styled.div`
  display: flex;
  align-items: center;
  max-width: 70%;
  margin-bottom: 8px;
`;

const MessageContent = styled.div`
  background-color: #ffffff;
  color: #333;
  border-radius: 16px;
  padding: 12px 18px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #dcdcdc;
`;

const MessageText = styled.div`
  margin-bottom: 5px;
`;

const TimeStamp = styled(Text)`
  font-size: 12px;
  color: #888;
  display: flex;
  align-items: center;
`;

const avatarStyle = css`
  margin-right: 12px;
`;

const iconStyle = css`
  margin-right: 4px;
`;

type Props = {
  messages: SupportTicketMessage[] | [];
};

const SerializeMessages: React.FC<Props> = ({ messages }) => {
  return (
    <>
      {messages?.map((m) => (
        <FormatMessage key={m._id} message={m} />
      ))}
    </>
  );
};

const FormatMessage = ({ message }: { message: SupportTicketMessage }) => {
  const user = storage?.getUser();
  const getTimeStamp = () => {
    const newDate = message?.createdAt && new Date(message.createdAt);
    return newDate?.toLocaleTimeString();
  };

  return (
    <MessageContainer isSender={message?.sender === "64f2b2f1e2b4f9d1d8b4e2c1"}>
      {message?.sender === "64f2b2f1e2b4f9d1d8b4e2c1" ? (
        <SentMessage>
          <MessageText>{message?.content}</MessageText>
          <TimeStamp>
            <ClockCircleOutlined css={iconStyle} />
            {getTimeStamp()}
          </TimeStamp>
        </SentMessage>
      ) : (
        <ReceivedMessage>
          <Avatar
            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
            size="large"
            css={avatarStyle}
            icon={<UserOutlined />}
          />
          <MessageContent>
            {message?.content}
            <TimeStamp>
              <ClockCircleOutlined css={iconStyle} />
              {getTimeStamp()}
            </TimeStamp>
          </MessageContent>
        </ReceivedMessage>
      )}
    </MessageContainer>
  );
};

export default SerializeMessages;
