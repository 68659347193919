import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { requestOtp, verifyOtp, changePassword } from "./changePasswordService";
import { ChangePasswordPayload, VerifyOtpRequest } from "./types";
import { AxiosError } from "axios";
import { showNotification } from "utils/notification";
import { ApiResponse } from "types/api.response";

interface UseChangePassword {
  requestOtpMutation: UseMutationResult<ApiResponse, AxiosError, string>;
  verifyOtpMutation: UseMutationResult<ApiResponse, AxiosError, VerifyOtpRequest>;
  changePasswordMutation: UseMutationResult<ApiResponse, AxiosError, ChangePasswordPayload>;
}

export const useChangePassword = (): UseChangePassword => {
  const requestOtpMutation = useMutation<ApiResponse, AxiosError, string>({
    mutationFn: (email: string) => requestOtp(email),
    onSuccess: (data) => {
      showNotification("success", "Success", data.message || "OTP has been sent to your email.");
    },
  });

  const verifyOtpMutation = useMutation<ApiResponse, AxiosError, VerifyOtpRequest>({
    mutationFn: (data: VerifyOtpRequest) => verifyOtp(data),
    onSuccess: (data) => {
      showNotification("success", "Success", data.message || "OTP verified successfully.");
    },
  });

  const changePasswordMutation = useMutation<ApiResponse, AxiosError, ChangePasswordPayload>({
    mutationFn: (data: ChangePasswordPayload) => changePassword(data),
    onSuccess: (data) => {
      showNotification("success", "Success", data.message || "Password changed successfully.");
    },
  });

  return {
    requestOtpMutation,
    verifyOtpMutation,
    changePasswordMutation,
  };
};
