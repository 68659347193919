import React from "react";
import LoginImage from "assets/signin.svg";
import { Col } from "antd";

const LoginImageComponent: React.FC = () => (
  <Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <img src={LoginImage} alt="Login" />
  </Col>
);

export default LoginImageComponent;
