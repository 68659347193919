import React from "react";
import { Table } from "antd";
import { PatientRecord } from "services/PatientRecordService/type";
import { EllipsisOutlined } from "@ant-design/icons";

interface PatientTableProps {
  dataSource: PatientRecord[];
}

const columns = [
  {
    title: "Patient Name",
    dataIndex: "fullName",
    key: "fullName",
    render: (text: any, record: PatientRecord) => `${record.firstName} ${record.lastName}`,
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    render: (text: any, record: PatientRecord) =>
      `${record.address.street}, ${record.address.city}, ${record.address.state} ${record.address.postalCode}`,
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Date of Birth",
    dataIndex: "dateOfBirth",
    key: "dateOfBirth",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    key: "updatedAt",
  },
  {
    title: "Options",
    key: "action",
    render: (text: any, record: PatientRecord) => <EllipsisOutlined />,
  },
];

const PatientTable: React.FC<PatientTableProps> = ({ dataSource }) => (
  <Table dataSource={dataSource} columns={columns} />
);

export default PatientTable;
