import React from "react";
import { Table, TableColumnProps } from "antd";
import { useAppointmentCalendar } from "../hooks/useAppointmentCalender";
import { Event } from "types/event.type";

const ALL_DAY_ROW = 0;

const AppointmentCalendar: React.FC = () => {
  const { tableColumns, dataSource } = useAppointmentCalendar();

  return (
    <Table<Event>
      rowKey={(record) => record?.eventId}
      dataSource={dataSource}
      columns={tableColumns as TableColumnProps<Event>[]}
      pagination={false}
      bordered
      showHeader
      onRow={(record, rowIndex) => ({
        height: rowIndex === ALL_DAY_ROW ? "100px" : undefined,
      })}
    />
  );
};

export default AppointmentCalendar;
