/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode } from "react";
import { Card, Table } from "antd";
import { CSSProperties } from "react";
import { cardStyle, tableLabelStyle } from "../styles";

interface CardWithTableProps {
  title: string;
  dataSource: any[];
  columns: any[];
  tableStyle?: any;
  extra?: ReactNode;
}

const CardWithTable: React.FC<CardWithTableProps> = ({ title, dataSource, columns, extra }) => (
  <Card
    title={title}
    style={{ flex: 1, boxShadow: "0px 8px 40px -10px #00000014" }}
    css={cardStyle}
    extra={extra}
  >
    <Table
      columns={columns.map((col) => ({
        ...col,
        onCell: () => ({
          style: { whiteSpace: "nowrap" },
        }),
      }))}
      dataSource={dataSource}
      pagination={false}
      rowKey="key"
      css={tableLabelStyle}
    />
  </Card>
);

export default CardWithTable;
