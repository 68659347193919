import { authClient } from "utils/httpClient";
import { ResetPasswordRequest, VerifyOtpRequest } from "./types";
import { ApiResponse } from "types/api.response";
import { API_ENDPOINTS } from "constants/apiEndpoints";

export const requestOtp = async (email: string): Promise<ApiResponse> => {
  try {
    const response = await authClient.post<ApiResponse>(API_ENDPOINTS.RESET_PASSWORD.REQUEST, {
      email,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOtp = async (data: VerifyOtpRequest): Promise<ApiResponse> => {
  try {
    const response = await authClient.post<ApiResponse>(API_ENDPOINTS.RESET_PASSWORD.VERIFY, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (data: ResetPasswordRequest): Promise<ApiResponse> => {
  try {
    const response = await authClient.post<ApiResponse>(API_ENDPOINTS.RESET_PASSWORD.RESET, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
