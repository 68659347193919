// Data Types and Initial Data
export interface DataType {
  key: string;
  rowLabel: string;
  leftSph: string;
  leftCyl: string;
  leftAxis: string;
  leftAdd: string;
  leftInter: string;
  leftVa: string;
  rightSph: string;
  rightCyl: string;
  rightAxis: string;
  rightAdd: string;
  rightInter: string;
  rightVa: string;
}

interface TableRow {
  key: string;
  label: string;
  [key: string]: any; // Allow any additional properties
}

interface PDData extends TableRow {
  dist: string;
  near: string;
}

interface VisualAcuityData extends TableRow {
  R: string;
  L: string;
  OU: string;
}

interface IOPData extends TableRow {
  Right: string;
  Left: string;
  "@": string;
}

interface MatrixTableData extends TableRow {
  H: string;
  V: string;
}

interface KReadingData extends TableRow {
  MM: string;
  D: string;
  Axis: string;
}

interface AverageData extends TableRow {
  MM: string;
  D: string;
}

interface CylData extends TableRow {
  D: string;
  Axis: string;
}

export const initialData: DataType[] = [
  {
    key: "1",
    rowLabel: "Previous Rx",
    leftSph: "",
    leftCyl: "",
    leftAxis: "",
    leftAdd: "",
    leftInter: "",
    leftVa: "",
    rightSph: "",
    rightCyl: "",
    rightAxis: "",
    rightAdd: "",
    rightInter: "",
    rightVa: "",
  },
  {
    key: "2",
    rowLabel: "Subjective Rx",
    leftSph: "",
    leftCyl: "",
    leftAxis: "",
    leftAdd: "",
    leftInter: "",
    leftVa: "",
    rightSph: "",
    rightCyl: "",
    rightAxis: "",
    rightAdd: "",
    rightInter: "",
    rightVa: "",
  },
  {
    key: "3",
    rowLabel: "Given Rx",
    leftSph: "",
    leftCyl: "",
    leftAxis: "",
    leftAdd: "",
    leftInter: "",
    leftVa: "",
    rightSph: "",
    rightCyl: "",
    rightAxis: "",
    rightAdd: "",
    rightInter: "",
    rightVa: "",
  },
];

export const initialLensData: DataType[] = [
  {
    key: "1",
    rowLabel: "Current Given Rx",
    leftSph: "",
    leftCyl: "",
    leftAxis: "",
    leftAdd: "",
    leftInter: "",
    leftVa: "",
    rightSph: "",
    rightCyl: "",
    rightAxis: "",
    rightAdd: "",
    rightInter: "",
    rightVa: "",
  },
];

export const matrixTableData: MatrixTableData[] = [
  { key: "1", label: "Dist Phoria", H: "", V: "" },
  { key: "2", label: "Near Phoria", H: "", V: "" },
];

export const matrixTableData3: MatrixTableData[] = [
  { key: "1", label: "Un-aided", H: "", V: "" },
  { key: "2", label: "Aided", H: "", V: "" },
  { key: "3", label: "Pinhole", H: "", V: "" },
];

export const matrixTableData2: MatrixTableData[] = [
  { key: "1", label: "Binocular", H: "", V: "" },
  { key: "2", label: "Right", H: "", V: "" },
  { key: "3", label: "Left", H: "", V: "" },
];

export const matrixTableData4: MatrixTableData[] = [
  { key: "1", label: "IOP", H: "", V: "" },
  { key: "2", label: "Pachymetry", H: "", V: "" },
  { key: "3", label: "Adjust IOP", H: "", V: "" },
];

export const trialsData: any = [
  { key: "1", label: "Right Eye" },
  { key: "2", label: "RIght OR" },
  { key: "3", label: "Left Eye" },
  { key: "3", label: "Left OR" },
];

export const kReadingData: KReadingData[] = [
  { key: "1", label: "Right", MM: "", D: "", Axis: "" },
  { key: "2", label: "Left", MM: "", D: "", Axis: "" },
];

export const matrixTableDataExtended: MatrixTableData[] = [
  { key: "1", label: "Amplitude of Accom", H: "", V: "" },
];
