import { Input, Select, DatePicker, Radio, Checkbox, InputNumber, Upload, Switch } from "antd";
import { SelectOption, FieldConfig, SectionConfig, FormFieldConfig } from "types/form.config.type";

// Define select options
const genderOptions: SelectOption[] = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

const doctorOptions: SelectOption[] = [
  { value: "dr1", label: "Dr. John Doe" },
  { value: "dr2", label: "Dr. Jane Smith" },
];

const otherNumberTypeOptions: SelectOption[] = [
  { value: "type1", label: "Type 1" },
  { value: "type2", label: "Type 2" },
];

// Define field configurations
const patientFormConfig: Record<string, SectionConfig> = {
  Identity: {
    title: "Identity",
    wrapWithCard: true,
    fields: [
      {
        name: "title",
        label: "Title",
        component: Input,
        props: { placeholder: "Enter title" },
        colSpan: 8,
        componentType: "Input",
      },
      {
        name: "firstName",
        label: "First Name",
        component: Input,
        props: { placeholder: "Enter first name" },
        colSpan: 8,
        componentType: "Input",
      },
      {
        name: "lastName",
        label: "Last Name",
        component: Input,
        props: { placeholder: "Enter last name" },
        colSpan: 8,
        componentType: "Input",
      },
      {
        name: "preferredName",
        label: "Preferred Name",
        component: Input,
        props: { placeholder: "Enter preferred name" },
        colSpan: 8,
        componentType: "Input",
      },
      {
        name: "gender",
        label: "Gender",
        component: Select,
        options: genderOptions,
        props: { placeholder: "Select gender" },
        colSpan: 8,
        componentType: "Select",
      },
      {
        name: "dateOfBirth",
        label: "Date of Birth",
        component: DatePicker,
        props: { style: { width: "100%" }, placeholder: "Select date" },
        colSpan: 8,
        componentType: "DatePicker",
      },
      {
        name: "assistanceRequired",
        label: "Assistance Required",
        component: Radio.Group,
        children: (
          <>
            <Radio value="mobility">Mobility</Radio>
            <Radio value="hearing">Hearing</Radio>
          </>
        ),
        colSpan: 8,
        componentType: "RadioGroup",
      },
      {
        name: "deceased",
        label: "Deceased",
        component: Radio.Group,
        children: <Radio value="deceased">Deceased</Radio>,
        colSpan: 8,
        componentType: "RadioGroup",
      },
    ],
  },
  ContactDetails: {
    title: "Contact Details",
    wrapWithCard: true,
    fields: [
      {
        name: "mobilePhone",
        label: "Mobile Phone",
        component: Input,
        props: { placeholder: "Enter mobile phone" },
        colSpan: 8,
        componentType: "Input",
      },
      {
        name: "emailAddress",
        label: "Email Address",
        component: Input,
        props: { placeholder: "Enter email address" },
        colSpan: 8,
        componentType: "Input",
      },
      {
        name: "homePhone",
        label: "Home Phone",
        component: Input,
        props: { placeholder: "Enter home phone" },
        colSpan: 8,
        componentType: "Input",
      },
      {
        name: "officePhone",
        label: "Office Phone",
        component: Input,
        props: { placeholder: "Enter office phone" },
        colSpan: 8,
        componentType: "Input",
      },
      {
        name: "doctorInitials",
        label: "Doctor (GP) Initials",
        component: Select,
        options: doctorOptions,
        props: { placeholder: "Select doctor initials" },
        colSpan: 8,
        componentType: "Select",
      },
      {
        name: "ophthalmologistInitials",
        label: "Ophthalmologist Initials",
        component: Input,
        props: { placeholder: "Enter ophthalmologist initials" },
        colSpan: 8,
        componentType: "Input",
      },
    ],
  },
  ResidentialAddress: {
    title: "Residential Address",
    wrapWithCard: true,
    fields: [
      {
        name: "addressLine1",
        label: "Address Line 1",
        component: Input,
        props: { placeholder: "Enter address line 1" },
        colSpan: 12,
        componentType: "Input",
      },
      {
        name: "addressLine2",
        label: "Address Line 2",
        component: Input,
        props: { placeholder: "Enter address line 2" },
        colSpan: 12,
        componentType: "Input",
      },
      {
        name: "suburb",
        label: "Suburb",
        component: Input,
        props: { placeholder: "Enter suburb" },
        colSpan: 12,
        componentType: "Input",
      },
      {
        name: "state",
        label: "State",
        component: Input,
        props: { placeholder: "Enter state" },
        colSpan: 12,
        componentType: "Input",
      },
      {
        name: "postCode",
        label: "Post Code",
        component: Input,
        props: { placeholder: "Enter post code" },
        colSpan: 12,
        componentType: "Input",
      },
      // Uncomment below if you want to include the checkbox for postal address
      // {
      //   name: 'postalAddressDifferent',
      //   label: 'Postal address is different from Residential address',
      //   component: Checkbox,
      // },
    ],
  },
  MedicareVeteransAffairs: {
    title: "Medicare/Veteran's Affairs",
    wrapWithCard: true,
    fields: [
      {
        name: "medicareNumber",
        label: "Medicare Number",
        component: Input,
        props: { placeholder: "Enter Medicare number" },
        colSpan: 8,
        componentType: "Input",
      },
      {
        name: "individualsRef",
        label: "Individual's Ref",
        component: Input,
        props: { placeholder: "Enter individual's ref" },
        colSpan: 8,
        componentType: "Input",
      },
      {
        name: "validTo",
        label: "Valid To",
        component: Input,
        props: { placeholder: "Enter valid to" },
        colSpan: 8,
        componentType: "Input",
      },
      {
        name: "veteransAffairsNumber",
        label: "Veteran's Affairs Number",
        component: Input,
        props: { placeholder: "Enter veteran's affairs number" },
        colSpan: 8,
        componentType: "Input",
      },
      {
        name: "otherNumberType",
        label: "Other Number Type",
        component: Select,
        options: otherNumberTypeOptions,
        props: { placeholder: "Select other number type" },
        colSpan: 8,
        componentType: "Select",
      },
      {
        name: "otherNumberValue",
        label: "Other Number Value",
        component: Input,
        props: { placeholder: "Enter other number value" },
        colSpan: 8,
        componentType: "Input",
      },
    ],
  },
  HealthFund: {
    title: "Health Fund",
    wrapWithCard: true,
    fields: [
      {
        name: "healthFund",
        label: "Health Fund",
        component: Input,
        props: { placeholder: "Enter health fund" },
        colSpan: 12,
        componentType: "Input",
      },
      {
        name: "membershipNumber",
        label: "Membership Number",
        component: Input,
        props: { placeholder: "Enter membership number" },
        colSpan: 12,
        componentType: "Input",
      },
    ],
  },
  LifeStyle: {
    title: "Life Style",
    wrapWithCard: true,
    fields: [
      {
        name: "occupation",
        label: "Occupation",
        component: Input,
        props: { placeholder: "Enter occupation" },
        colSpan: 12,
        componentType: "Input",
      },
      {
        name: "hobbies",
        label: "Hobbies",
        component: Input,
        props: { placeholder: "Enter hobbies" },
        colSpan: 12,
        componentType: "Input",
      },
    ],
  },
};

export default patientFormConfig;
