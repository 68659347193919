import React from "react";
import { Row } from "antd";
import StatisticWithIconCard from "../common/StatisticCard";
import {
  UserIcon,
  CubeIcon,
  SalesIcon,
  AppointmentIcon,
  PatientIcon,
  CreateOpenIcon,
} from "../common/Icons";

const DashboardSummary: React.FC = () => {
  return (
    <Row gutter={[18, 0]} style={{ height: "100%" }}>
      <StatisticWithIconCard
        title="Total Appointment"
        value={112893}
        icon={<UserIcon />}
        backgroundColor="#dff0d8"
        trend="8.5% up from Yesterday"
      />
      <StatisticWithIconCard
        title="New Patient"
        value={112893}
        icon={<CubeIcon />}
        backgroundColor="#d9edf7"
        trend="1.5% up from last Week"
      />
      <StatisticWithIconCard
        title="Total Sales"
        value={112893}
        icon={<SalesIcon />}
        backgroundColor="#f2dede"
        trend="4.3% down from Yesterday"
      />
      <StatisticWithIconCard
        title="Appointment"
        value={112893}
        icon={<AppointmentIcon />}
        backgroundColor="#f5f5f5"
        isSmall={true}
      />
      <StatisticWithIconCard
        title="Appointment"
        value={112893}
        icon={<PatientIcon />}
        backgroundColor="#f5f5f5"
        isSmall={true}
      />
      <StatisticWithIconCard
        title="Appointment"
        value={112893}
        icon={<CreateOpenIcon />}
        backgroundColor="#f5f5f5"
        isSmall={true}
      />
    </Row>
  );
};

export default DashboardSummary;
