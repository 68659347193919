import React from "react";
import { Col, Card, Empty, Select } from "antd";
import TicketCards from "./SupportTicketCard";
import { TicketType } from "services/TicketTypeService";
import styled from "@emotion/styled";
import { SupportTicket } from "services/SupportTicketService";

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    &:focus {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }
`;

const TicketContainer = styled.div`
  max-height: calc(100vh - 160px); /* Adjust height as needed */
  overflow-y: auto;
  padding: 10px;
  border-radius: 8px;

  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #1890ff #e6f7ff; /* For Firefox */

  &::-webkit-scrollbar {
    width: 8px; /* For Chrome, Safari */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1890ff;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #e6f7ff;
    border-radius: 4px;
  }
`;

type SetSelectedTicketAction = (ticket: SupportTicket | null) => void;

interface TicketListProps {
  tickets: SupportTicket[];
  setSelectedTicket: SetSelectedTicketAction;
  selectedTicket: SupportTicket | null;
  ticketTypes: TicketType[];
}

const TicketList: React.FC<TicketListProps> = ({
  tickets,
  setSelectedTicket,
  selectedTicket,
  ticketTypes,
}) => {
  const ticketOptions = ticketTypes.map((type) => ({
    label: type.ticketType,
    value: type._id,
  }));

  return (
    <Col span={7}>
      <div style={{ marginBottom: "12px" }}>
        <StyledSelect
          style={{ width: "100%" }}
          options={ticketOptions}
          placeholder="Select ticket type"
          allowClear
        />
      </div>
      {tickets.length ? (
        <TicketContainer>
          {tickets.map((ticket) => (
            <TicketCards
              key={ticket._id}
              item={ticket}
              setSelectedTicket={setSelectedTicket}
              selectedTicket={selectedTicket}
            />
          ))}
        </TicketContainer>
      ) : (
        <Card
          style={{
            height: "52vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Empty description="No tickets available" />
        </Card>
      )}
    </Col>
  );
};

export default TicketList;
