import styled from "@emotion/styled";
import { Button } from "antd";
import { ReactComponent as FilterIcon } from "assets/filtericon.svg";
import { ReactComponent as ClockIcon } from "assets/watchicon.svg";
import { ReactComponent as PlusIcon } from "assets/plus.svg";

const ButtonWithIcon = styled(Button)<{ icon: React.ReactNode }>`
  // Additional button styles if needed
`;

export const FilterButton = () => <ButtonWithIcon icon={<FilterIcon />} />;
export const ClockButton = () => <ButtonWithIcon icon={<ClockIcon />} />;
export const PlusButton = () => <ButtonWithIcon icon={<PlusIcon />} />;
