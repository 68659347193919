import React from "react";
import { Form, Input } from "antd";

const PasswordStep: React.FC = () => (
  <>
    <Form.Item
      name="oldPassword"
      rules={[{ required: true, message: "Please input your old password!" }]}
    >
      <Input.Password placeholder="Old Password" />
    </Form.Item>
    <Form.Item
      name="password"
      rules={[{ required: true, message: "Please input your new password!" }]}
    >
      <Input.Password placeholder="New Password" />
    </Form.Item>
    <Form.Item
      name="confirmPassword"
      dependencies={["password"]}
      rules={[
        { required: true, message: "Please confirm your new password!" },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject("The two passwords do not match!");
          },
        }),
      ]}
    >
      <Input.Password placeholder="Confirm New Password" />
    </Form.Item>
  </>
);

export default PasswordStep;
