import {
  add,
  isSameDay,
  addHours,
  eachDayOfInterval,
  startOfDay,
  getDay,
  isSameWeek,
  startOfWeek,
  format,
  differenceInMinutes,
  addMinutes,
  getHours,
  getMinutes,
  getYear,
  getMonth,
  getDate,
} from "date-fns";

export function isSameTime(date1: Date, date2: Date) {
  return (
    getYear(date1) === getYear(date2) &&
    getMonth(date1) === getMonth(date2) &&
    getDate(date1) === getDate(date2) &&
    getHours(date1) === getHours(date2) &&
    getMinutes(date1) === getMinutes(date2)
  );
}

export const daysToWeekObject = (events: any[], startWeek: Date) => {
  const dayNames: any[] = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const weekObject: any = {
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  };

  if (events == null) {
    return weekObject;
  }

  for (const googleEventIndex in events) {
    const eventStartTimeDay = events[googleEventIndex].startTime;
    const eventEndTimeDay = addMinutes(events[googleEventIndex].startTime, 30);

    if (!isSameDay(eventStartTimeDay, eventEndTimeDay)) {
      const result = eachDayOfInterval({
        start: eventStartTimeDay,
        end: eventEndTimeDay,
      });
      for (const dayInterval in result) {
        const splitedEvent = { ...events[googleEventIndex] };
        splitedEvent.startTime = result[dayInterval];
        splitedEvent.endTime = result[dayInterval];
        const weekObjectKey: any = dayNames[getDay(new Date(result[dayInterval]))];
        isSameWeek(startWeek, splitedEvent.startTime) &&
          weekObject[weekObjectKey].push(splitedEvent);
      }
    } else {
      const weekObjectKey: any = dayNames[getDay(eventStartTimeDay)];
      weekObject[weekObjectKey].push(events[googleEventIndex]);
    }
  }

  return weekObject;
};

export const getDayHoursEvents = (value: any, weekObject: any) => {
  const ALL_DAY_EVENT = 0;
  const events = [];

  // Loop through half-hour intervals from 8 AM to 8 PM
  for (let i = 0; i < 25; i++) {
    const startDate = add(startOfDay(startOfWeek(value.startDate)), {
      days: 1,
    });

    // Calculate the hour starting from 8 AM
    const hour = addHours(startDate, Math.floor(i / 2) + 8); // Hours from 8 AM
    const minutes = i % 2 === 0 ? 0 : 30; // Alternate between 0 and 30 minutes
    const fullHour = addMinutes(hour, minutes);

    events.push({
      id: i,
      hourObject: fullHour,
      hour: format(fullHour, "hh:mm a"),
      Monday:
        weekObject?.monday &&
        weekObject.monday.filter((e: any) => {
          return e.allDay ? i === ALL_DAY_EVENT : isSameTime(e.startTime, fullHour);
        }),
      Tuesday:
        weekObject?.tuesday &&
        weekObject.tuesday.filter((e: any) => {
          return e.allDay
            ? i === ALL_DAY_EVENT
            : isSameTime(e.startTime, add(fullHour, { days: 1 }));
        }),
      Wednesday:
        weekObject?.wednesday &&
        weekObject.wednesday.filter((e: any) => {
          return e.allDay
            ? i === ALL_DAY_EVENT
            : isSameTime(e.startTime, add(fullHour, { days: 2 }));
        }),
      Thursday:
        weekObject?.thursday &&
        weekObject.thursday.filter((e: any) => {
          return e.allDay
            ? i === ALL_DAY_EVENT
            : isSameTime(e.startTime, add(fullHour, { days: 3 }));
        }),
      Friday:
        weekObject?.friday &&
        weekObject.friday.filter((e: any) => {
          return e.allDay
            ? i === ALL_DAY_EVENT
            : isSameTime(e.startTime, add(fullHour, { days: 4 }));
        }),
      Saturday:
        weekObject?.saturday &&
        weekObject.saturday.filter((e: any) => {
          return e.allDay
            ? i === ALL_DAY_EVENT
            : isSameTime(e.startTime, add(fullHour, { days: 5 }));
        }),
      Sunday:
        weekObject?.sunday &&
        weekObject.sunday.filter((e: any) => {
          return e.allDay
            ? i === ALL_DAY_EVENT
            : isSameTime(e.startTime, add(fullHour, { days: 6 }));
        }),
    });
  }

  return events;
};

const HOUR_TO_DECIMAL = 1.666666667;
export const MIN_BOX_SIZE = 40;

export const sizeEventBox = (event: any, hour: Date) => {
  const eventStartTime = new Date(event.startTime);
  const eventEndTime = new Date(event.endTime);
  const boxSize =
    Math.floor(differenceInMinutes(eventEndTime, eventStartTime) * HOUR_TO_DECIMAL) < MIN_BOX_SIZE
      ? MIN_BOX_SIZE
      : Math.floor(differenceInMinutes(eventEndTime, eventStartTime) * HOUR_TO_DECIMAL);
  const boxPosition =
    differenceInMinutes(hour, eventStartTime) * HOUR_TO_DECIMAL > 100
      ? 0
      : differenceInMinutes(eventStartTime, hour) * HOUR_TO_DECIMAL;

  return { boxPosition: boxPosition, boxSize: boxSize };
};
