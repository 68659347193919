/** @jsxImportSource @emotion/react */
import React from "react";
import { Typography } from "antd";
import styled from "@emotion/styled";

const { Title, Text } = Typography;

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  titleLevel?: any;
  titleStyle?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
}

const TitleStyled = styled(Title)`
  margin-bottom: 0;
`;

const SubtitleStyled = styled(Text)`
  margin-bottom: 24px;
`;

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  titleLevel = 3,
  titleStyle,
  subtitleStyle,
}) => (
  <div>
    <TitleStyled level={titleLevel} style={titleStyle}>
      {title}
    </TitleStyled>
    {subtitle && (
      <SubtitleStyled type="secondary" style={subtitleStyle}>
        {subtitle}
      </SubtitleStyled>
    )}
  </div>
);

export default PageHeader;
