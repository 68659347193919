import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider, Button, Input } from "antd";
import AppRoute from "./router";
import "./App.css";
import { themeConfig } from "config/antd.config";
import useQueryClientConfig from "hooks/useQueryClientConfig";

const App = () => {
  const queryClientConfig = useQueryClientConfig();
  const queryClient = new QueryClient(queryClientConfig);

  return (
    <ConfigProvider theme={themeConfig}>
      <QueryClientProvider client={queryClient}>
        <AppRoute />
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default App;
