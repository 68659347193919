import { useCallback } from "react";
import { useErrorContext } from "context/ErrorContext";

const useGlobalErrorHandler = () => {
  const { openErrorModal } = useErrorContext();

  return useCallback(
    (error: any) => {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            openErrorModal({
              message: "Your session has expired. Please log in again.",
              description: "You will be redirected to the login page.",
              isTokenExpired: true,
            });
            break;
          case 403:
            openErrorModal({
              message: "Access Denied",
              description: "You do not have permission to access this resource.",
              isTokenExpired: false,
            });
            break;
          case 404:
            openErrorModal({
              message: "Resource Not Found",
              description: "The requested resource could not be found.",
              isTokenExpired: false,
            });
            break;
          case 500:
            openErrorModal({
              message: "Server Error",
              description: "An error occurred on the server. Please try again later.",
              isTokenExpired: false,
            });
            break;
          default:
            openErrorModal({
              message: "An Unexpected Error Occurred",
              description: "Please try again later.",
              isTokenExpired: false,
            });
        }
      } else {
        openErrorModal({
          message: "Network Error",
          description: "Please check your internet connection and try again.",
          isTokenExpired: false,
        });
      }
    },
    [openErrorModal]
  );
};

export default useGlobalErrorHandler;
